import "./LoaderChat.css";

const LoaderChat = () => {
  return (
    <div className="LoaderChat">
      <div className="LoaderChat__loader" />
    </div>
  );
};

export default LoaderChat;
