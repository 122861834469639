import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { AnswerHeader } from "api/types/domain";
import { encuestasSelector } from "store/slices/encuestas";

import { headersRespuestas } from "../endpoints";

const CANAL_HEADER_NAME = "canal";

const useAnswerHeadersQuery = () => {
  const { idEncuestaSeleccionada: serviceId } = useSelector(encuestasSelector);
  return useQuery<AnswerHeader[], unknown>({
    enabled: serviceId !== undefined,
    queryKey: ["answerHeaders", serviceId],
    queryFn: async () => {
      const { data } = await headersRespuestas(serviceId!);
      return [
        { nombre: CANAL_HEADER_NAME, texto: "Canal", tipo: "ICON" },
        ...data.data.map(({ name, display_name, type }) => ({
          nombre: name,
          texto: display_name,
          tipo: type,
        })),
      ];
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useAnswerHeadersQuery;
