import { useSelector } from "react-redux";

import { loginSelector } from "store/slices/login";

function useIsLabeler(): boolean {
  const { cuenta } = useSelector(loginSelector);
  return !!cuenta && cuenta.endsWith("_labeler");
}

export default useIsLabeler;
