import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

const novedadesSlice = createSlice({
  name: "novedades",
  initialState: {
    modalActivo: false,
    overlaySeen: false,
  },
  reducers: {
    activaModal(state) {
      state.modalActivo = true;
    },
    desactivaModal(state) {
      state.modalActivo = false;
    },
    setOverlaySeen(state) {
      state.overlaySeen = true;
    },
  },
});

export const { activaModal, desactivaModal, setOverlaySeen } =
  novedadesSlice.actions;
export const novedadesSelector = (state: RootState) => state.novedades;

export default novedadesSlice.reducer;
