import { useQuery, useQueryClient } from "@tanstack/react-query";

import { campaignsApi } from "feedback-api";
import type { CampaignUsage } from "feedback-api/generated";

const getCampaignLimitsQuery = async ({ signal }: { signal: AbortSignal }) => {
  const { data } = await campaignsApi.getLimitsV4CampaignsLimitsGet({ signal });
  return data;
};

const useCampaignLimitsQuery = () => {
  return useQuery<CampaignUsage, unknown>({
    queryKey: ["campaignLimits"],
    queryFn: getCampaignLimitsQuery,
    refetchInterval: 300_000,
  });
};

export const useCampaignLimitsPrefetch = async () => {
  const queryClient = useQueryClient();
  await queryClient.prefetchQuery<CampaignUsage, unknown>({
    queryKey: ["campaignLimits"],
    queryFn: getCampaignLimitsQuery,
  });
};

export default useCampaignLimitsQuery;
