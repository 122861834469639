import { useQuery } from "@tanstack/react-query";
import {
  addDays,
  format,
  formatISO,
  isBefore,
  isSameDay,
  startOfDay,
} from "date-fns";
import * as _ from "lodash-es";
import { useSelector } from "react-redux";

import { dashboardsApi, MetricCount } from "feedback-api";
import { dashboardSelector } from "store/slices/dashboard";
import { loginSelector } from "store/slices/login";

const staleTimeMinutes = 30;

const useMetricsQuery = () => {
  const { filters, start, end } = useSelector(dashboardSelector);
  const startStr = format(start, "yyyy-MM-dd");
  const endStr = format(end, "yyyy-MM-dd");
  const { idCliente: clientId } = useSelector(loginSelector);

  return useQuery<MetricCount[], unknown>({
    queryKey: ["metrics", clientId, startStr, endStr, filters],
    queryFn: async ({ signal }) => {
      const {
        data: { counts: rawCounts },
      } = await dashboardsApi.getMetricsV4DashboardsMetricsGet(
        startStr,
        endStr,
        {
          params: new URLSearchParams(
            filters === "EVERYTHING"
              ? []
              : filters.map((filter) => [filter.propertyId, filter.value]),
          ),
          signal,
        },
      );
      const counts = normalizeDates(rawCounts);
      return fillInEmptyDays({ start, end, counts });
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: staleTimeMinutes * 60_000,
  });
};

const normalizeDates = (counts: MetricCount[]) => {
  return counts.map(
    ({ date, ...others }): MetricCount => ({
      date: date + "T00:00:00",
      ...others,
    }),
  );
};

const fillInEmptyDays = ({
  start,
  end,
  counts,
}: {
  start: number | Date;
  end: number | Date;
  counts: MetricCount[];
}) => {
  for (
    let iterationDate = startOfDay(start);
    isBefore(iterationDate, startOfDay(end));
    iterationDate = addDays(iterationDate, 1)
  ) {
    const dateExists = counts.some((c: MetricCount) =>
      isSameDay(c.date, iterationDate),
    );
    if (!dateExists) {
      counts.push({
        date: formatISO(iterationDate),
        total: 0,
        answered: 0,
        confirmed: 0,
        cancelled: 0,
      });
    }
  }
  return _.sortBy(counts, "date");
};

export default useMetricsQuery;
