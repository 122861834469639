import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";

import "./Button.css";

interface ButtonProps {
  children?: ReactNode;
  disabled?: boolean;
  formNoValidate?: boolean;
  size?: "large" | "medium" | "small";
  type?: "button" | "submit" | "reset";
  variant?: "default" | "outline" | "link";
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const Button = ({
  children,
  size = "large",
  type = "button",
  variant = "default",
  ...props
}: ButtonProps) => {
  const btnClass = classNames("Button", {
    "Button--default": variant === "default",
    "Button--outline": variant === "outline",
    "Button--link": variant === "link",
    "Button--large": size === "large",
    "Button--medium": size === "medium",
    "Button--small": size === "small",
  });
  return (
    <button className={btnClass} type={type} {...props}>
      {children}
    </button>
  );
};

export default Button;
