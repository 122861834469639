import Scrambler from "components/Scrambler";

import "./BarraAppCelular.css";

interface BarraAppCelularProps {
  nombrePaciente: string | undefined;
  telefono: string;
}

const BarraAppCelular = ({
  nombrePaciente,
  telefono,
}: BarraAppCelularProps) => {
  return (
    <div className="BarraAppCelular">
      <div className="BarraAppCelular__izquierda">
        <div
          className="BarraAppCelular__avatar"
          style={{
            // @ts-expect-error setting CSS variable through style prop
            "--avatar-hue":
              360 *
              (((nombrePaciente?.[0].toLowerCase() ?? "z").charCodeAt(0) - 97) /
                25),
          }}
        >
          {nombrePaciente?.[0] || ""}
        </div>
        <div className="BarraAppCelular__contenedor_nombre">
          <div className="BarraAppCelular__nombre">
            <Scrambler tipo="usuario">{nombrePaciente ?? ""}</Scrambler>
          </div>
          <div className="BarraAppCelular__estado">{telefono}</div>
        </div>
      </div>
      <div className="BarraAppCelular__contenedor_iconos"></div>
    </div>
  );
};

export default BarraAppCelular;
