// TODO: move to ../../hooks/
import { useSelector } from "react-redux";

import { loginSelector } from "store/slices/login";

const forbiddenUsers = ["sanasalud", "sanasalud_cero", "norden", "norden_cero"];

function useCanSeeUnreachables(): boolean {
  const { nombreUsuario } = useSelector(loginSelector);
  return (
    !!nombreUsuario && !forbiddenUsers.includes(nombreUsuario.toLowerCase())
  );
}

export default useCanSeeUnreachables;
