import Step, { StepProps } from "components/atoms/Step";

import "./Stepper.css";

interface StepperProps {
  steps: string[];
  currentStep: number;
  enabledSteps: number[];
  onClick: (step: number) => void;
}

const Stepper = ({
  steps,
  currentStep,
  enabledSteps,
  onClick,
}: StepperProps) => {
  const getState = (step: number, selectedStep: number): StepProps["state"] => {
    return step === selectedStep
      ? "selected"
      : step <= selectedStep
        ? "completed"
        : "future";
  };
  return (
    <div className="Stepper__container">
      {steps.map((stepLabel, index) => (
        <Step
          key={index}
          disabled={!enabledSteps.includes(index + 1)}
          index={index + 1}
          label={stepLabel}
          state={getState(index + 1, currentStep)}
          onClick={() => onClick(index + 1)}
        />
      ))}
    </div>
  );
};

export default Stepper;
