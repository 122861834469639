import { format, startOfToday } from "date-fns";
import { Controller, useForm } from "react-hook-form";

import useGetProfessionalsDataQuery from "api/hooks/useGetProfessionalsDataQuery";
import Button from "components/atoms/Button";
import InputGroup from "components/molecules/InputGroup";
import SelectGroup from "components/molecules/SelectGroup";

import { ProfessionalOption } from "../Suspensions";

const DATETIME_LOCAL_FORMAT = "yyyy-LL-dd'T'HH:mm";

interface ConfigureSuspensionStepProps {
  startDate: string;
  endDate: string;
  onSubmit: ({
    startDate,
    endDate,
    selectedProfessional,
  }: {
    startDate: string;
    endDate: string;
    selectedProfessional: ProfessionalOption;
  }) => void;
}

const ConfigureSuspensionStep = ({
  startDate,
  endDate,
  onSubmit,
}: ConfigureSuspensionStepProps) => {
  const formattedStartOfToday = format(startOfToday(), DATETIME_LOCAL_FORMAT);

  const {
    data: professionalsData,
    isError,
    isPending,
  } = useGetProfessionalsDataQuery();

  type Inputs = {
    startDate: string;
    endDate: string;
    selectedProfessional: ProfessionalOption;
  };

  const { control, getValues, handleSubmit } = useForm({
    defaultValues: {
      startDate,
      endDate,
    } as Inputs,
    shouldUnregister: true,
  });

  const onSubmitSuccess = ({
    startDate,
    endDate,
    selectedProfessional,
  }: {
    startDate: string;
    endDate: string;
    selectedProfessional: ProfessionalOption;
  }) => {
    onSubmit({ startDate, endDate, selectedProfessional });
  };

  if (isPending) return <p>Cargando profesionales</p>;
  if (isError) return <p>Error al cargar profesionales</p>;
  return (
    <div className="UploadSpreadsheetStep">
      <div className="UploadSpreadsheetStep__section">
        <h3 className="UploadSpreadsheetStep__title">Configurar suspensión</h3>
        <p className="UploadSpreadsheetStep__copy">
          Define los criterios para identificar las citas afectadas por la
          suspensión
        </p>
        <form
          className="ConfigureCampaignStep__form"
          onSubmit={handleSubmit(onSubmitSuccess)}
        >
          <div className="InputGroup__context">
            <p className="InputGroup__label">Periodo de suspensión</p>
            <p className="InputGroup__description">
              Selecciona el rango de fecha y hora para la suspensión, incluyendo
              el inicio y el término del periodo afectado.
            </p>
          </div>
          <Controller
            name="startDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <InputGroup
                label="Fecha de inicio"
                placeholder="Selecciona la fecha de inicio"
                type="datetime-local"
                min={formattedStartOfToday}
                error={error}
                {...field}
              />
            )}
            rules={{
              required:
                "Este campo es obligatorio. Por favor, selecciona una fecha y hora de inicio.",
              min: {
                value: formattedStartOfToday,
                message: "La fecha de inicio debe ser igual o posterior a hoy.",
              },
              deps: ["endDate"],
            }}
          />
          <Controller
            name="endDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <InputGroup
                label="Fecha de término"
                placeholder="Selecciona la fecha de término"
                type="datetime-local"
                min={formattedStartOfToday}
                error={error}
                {...field}
              />
            )}
            rules={{
              required:
                "Este campo es obligatorio. Por favor, selecciona una fecha y hora de término.",
              validate: (v) =>
                v >= getValues("startDate") ||
                "La fecha de término debe ser igual o posterior a la fecha de inicio.",
            }}
          />
          <Controller
            name="selectedProfessional"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <SelectGroup
                label="Selecciona al profesional"
                description="Elige al profesional que no podrá atender sus citas durante el periodo seleccionado."
                placeholder="Selecciona un profesional"
                menuPortalTarget={document.body}
                options={professionalsData?.map((professionalData) => ({
                  label: professionalData.display_data,
                  value: professionalData.search_data,
                  centerId: professionalData.center_id,
                }))}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
                value={value}
                error={error}
              />
            )}
            rules={{
              required:
                "Este campo es obligatorio. Por favor, selecciona un profesional.",
            }}
          />
          <Button type="submit">Verificar pacientes</Button>
        </form>
      </div>
    </div>
  );
};

export default ConfigureSuspensionStep;
