import "./InteractionNoteIcon.css";
import { Emoji, emojiToIconData } from "./emojis";

interface InteractionNoteIconProps {
  emoji: Emoji;
}

const InteractionNoteIcon = ({ emoji }: InteractionNoteIconProps) => {
  const { icon, altText } = emojiToIconData(emoji);
  return (
    <img className="InteractionNoteIcon__emoji" src={icon} alt={altText} />
  );
};

export default InteractionNoteIcon;
