import { useEffect } from "react";
import { useSelector } from "react-redux";

import useScrambler from "hooks/useScrambler";
import { scramblerSelector } from "store/slices/scrambler";

import "./Scrambler.css";
import { scramble } from "./scramblers";

const esURL = (posibleURL: string) => {
  try {
    new URL(posibleURL);
    return posibleURL.startsWith("http");
  } catch (_) {
    return false;
  }
};

interface ScramblerProps {
  tipo: string;
  children: string;
  propagar?: boolean;
}

const Scrambler = ({
  tipo,
  children: texto,
  propagar = false,
}: ScramblerProps) => {
  const { scrambled, terminos } = useSelector(scramblerSelector);
  const agregarAScrambler = useScrambler();

  useEffect(() => {
    if (propagar) {
      if (
        [
          "nombre",
          "name",
          "dentist_name",
          "specialist_name_1",
          "specialist_name_2",
          "specialist_name_3",
          "specialist_name_4",
        ].includes(tipo)
      ) {
        agregarAScrambler([texto, tipo]);
        agregarAScrambler([texto.split(" ")[0], tipo]);
      } else if (tipo === "usuario") {
        agregarAScrambler([texto.split(/(?=[A-Z])/).join(" "), tipo]);
        texto.split(" ").forEach((t) => agregarAScrambler([t, tipo]));
        agregarAScrambler([texto, tipo]);
      } else {
        agregarAScrambler([texto, tipo]);
      }
    }
  }, [agregarAScrambler, tipo, texto, propagar]);

  const componente = esURL(texto) ? (
    <a
      className="Scrambler__link"
      target="_blank"
      href={scrambled ? "https://google.com" : texto}
      rel="noreferrer noopener"
      onClick={(e) => e.stopPropagation()}
    >
      {scrambled
        ? new URL(texto).hostname.substring(
            new URL(texto).hostname.indexOf(".") + 1,
          )
        : new URL(texto).hostname}
    </a>
  ) : scrambled ? (
    <>{scramble(texto, tipo, terminos)}</>
  ) : (
    <>{texto ?? ""}</>
  );

  return componente;
};

export default Scrambler;
