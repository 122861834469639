import { InlineIcon } from "@iconify/react";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAnswerHeadersQuery from "api/hooks/useAnswerHeadersQuery";
import { obtenerHeaders } from "helpers/tablaRespuestas";
import { encuestasSelector } from "store/slices/encuestas";
import {
  destacaColumna,
  fijaColumna,
  respuestasSelector,
  yaNoDestaquesColumna,
} from "store/slices/respuestas";

import ModalFiltros from "../ModalFiltros";
import "./HeadTablaRespuestas.css";

const parchar = (texto: string) => {
  if (texto.includes("_PAPERInsTypeDR")) {
    return texto.replace("_PAPERInsTypeDR", "Previsión");
  }
  return texto;
};

const HeadTablaRespuestas = () => {
  const { data: headers } = useAnswerHeadersQuery();
  const { idEncuestaSeleccionada: idEncuesta } = useSelector(encuestasSelector);
  const [modalFiltroActivo, setModalFiltroActivo] = useState(false);
  const [indiceColumnaFiltrada, setIndiceColumnaFiltrada] = useState(0);
  const { columnaDestacada, ordenHeader, orden } =
    useSelector(respuestasSelector);
  const dispatch = useDispatch();

  const headersOrdenados = useMemo(() => {
    if (!idEncuesta) {
      return [];
    }
    return obtenerHeaders(headers, idEncuesta) ?? [];
  }, [headers, idEncuesta]);

  const mostrarModalFiltros = (indiceColumna: number) => {
    setIndiceColumnaFiltrada(indiceColumna);
    setModalFiltroActivo(true);
    dispatch(fijaColumna(true));
  };

  return (
    <thead className="HeadTablaRespuestas">
      <tr className="HeadTablaRespuestas__fila">
        {headersOrdenados && (
          <th
            className="HeadTablaRespuestas__header--sin-padding"
            title="Notas"
          >
            <InlineIcon
              style={{ fontSize: ".75rem" }}
              icon="mdi:note-text-outline"
            />
          </th>
        )}
        {headersOrdenados.map(({ nombre, texto }, i) => (
          <th
            key={`header-${nombre}`}
            className={classNames({
              HeadTablaRespuestas__header: true,
              "HeadTablaRespuestas__header--activo":
                modalFiltroActivo && i === indiceColumnaFiltrada,
              "HeadTablaRespuestas__header--destacado": columnaDestacada === i,
            })}
            onClick={() => mostrarModalFiltros(i)}
            onMouseEnter={() => dispatch(destacaColumna(i))}
            onMouseLeave={() => dispatch(yaNoDestaquesColumna())}
            title={texto}
          >
            <span className="HeadTablaRespuestas__texto_header">
              <span>
                {parchar(texto)}
                <span className="HeadTablaRespuestas__icono_orden">
                  {ordenHeader === nombre && (
                    <InlineIcon
                      icon={orden === "ASC" ? "mdi:arrow-up" : "mdi:arrow-down"}
                    />
                  )}
                </span>
              </span>
              <button className="HeaderTablaRespuestas__boton_filtros">
                <InlineIcon
                  icon="mdi:arrow-down-drop"
                  className="HeaderTablaRespuestas__icono_filtros"
                />
              </button>
            </span>
          </th>
        ))}
      </tr>
      <ModalFiltros
        i={indiceColumnaFiltrada}
        header={headersOrdenados[indiceColumnaFiltrada]}
        activo={modalFiltroActivo}
        containerClass="HeadTablaRespuestas__header"
        esconder={() => {
          setModalFiltroActivo(false);
          dispatch(fijaColumna(false));
        }}
      />
    </thead>
  );
};

export default HeadTablaRespuestas;
