import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

interface Reaction {
  emoji: string;
  comentario: string;
}

const reaccionesSlice = createSlice({
  name: "reacciones",
  initialState: {
    reaccionesGuardadas: [
      {
        emoji: "✅",
        comentario: "ya contactamos a paciente",
      },
      {
        emoji: "⏳",
        comentario: "paciente no contesta",
      },
    ],
  },
  reducers: {
    guardaReaccion(state, action: PayloadAction<Reaction>) {
      const { comentario, emoji } = action.payload;
      const indiceReaccionConMismoComentario =
        state.reaccionesGuardadas.findIndex((r) => r.comentario === comentario);
      if (indiceReaccionConMismoComentario < 0) {
        state.reaccionesGuardadas = [
          { comentario, emoji },
          ...state.reaccionesGuardadas,
        ];
      } else {
        state.reaccionesGuardadas[indiceReaccionConMismoComentario].emoji =
          emoji;
      }
    },
    eliminaReaccion(state, action: PayloadAction<Reaction["comentario"]>) {
      const comentario = action.payload;
      const indiceReaccionConMismoComentario =
        state.reaccionesGuardadas.findIndex((r) => r.comentario === comentario);
      state.reaccionesGuardadas = state.reaccionesGuardadas.filter(
        (_, i) => i !== indiceReaccionConMismoComentario,
      );
    },
  },
});

export const { guardaReaccion, eliminaReaccion } = reaccionesSlice.actions;
export const reaccionesSelector = (state: RootState) => state.reacciones;

export default reaccionesSlice.reducer;
