import { InlineIcon } from "@iconify/react";
import classNames from "classnames";
import { useState } from "react";

import useIsClientDebtorQuery from "api/hooks/useIsClientDebtorQuery";

import "./PaymentDueBanner.css";

const PaymentDueBanner = () => {
  const { data, isPending, isError } = useIsClientDebtorQuery();
  const [visible, setVisible] = useState(true);

  if (isError) {
    return null;
  }

  if (isPending || !visible || data.status === "NOT_EXPIRED") {
    return null;
  }

  let message = "";
  if (data.status === "ALMOST_EXPIRED") {
    message = `Su factura por el servicio del mes de ${data.documentServiceMonth} vence en ${
      data.daysLeft
    } ${data.daysLeft !== 1 ? "días" : "día"}`;
  } else if (data.status === "NON_CHILEAN_EXPIRED") {
    message = "Su cuenta tiene estados de pago vencidos";
  } else if (data.status === "EXPIRED") {
    message = `Su factura por el servicio del mes de ${data.documentServiceMonth} se encuentra vencida`;
  }

  return (
    <p
      className={classNames({
        PaymentDueBanner: true,
        PaymentDueBannerWarning: data.status === "ALMOST_EXPIRED",
        PaymentDueBannerError: data.status !== "ALMOST_EXPIRED",
      })}
    >
      Recordatorio! {message}. Para evitar una suspensión del servicio
      comuníquese con finanzas@cero.ai o al +56923700821
      <button
        className="PaymentDueBanner__close"
        onClick={() => setVisible(false)}
      >
        <InlineIcon icon="mdi:close" />
      </button>
    </p>
  );
};
export default PaymentDueBanner;
