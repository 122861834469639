import { useQuery } from "@tanstack/react-query";

import { AnswerMediaAPIResponse } from "../types/responses";
import { API_ROOT, get } from "./utils";

const useChatAnswerMediaQuery = (answerId: number) => {
  return useQuery<{ url: string; contentType: string }, unknown>({
    queryKey: ["answer_media", answerId],
    queryFn: async ({ signal }) => {
      const { data } = await get<AnswerMediaAPIResponse>(
        `${API_ROOT}/answer_media/${answerId}`,
        { signal },
      );
      return {
        url: data.data.url,
        contentType: data.data.content_type,
      };
    },
    refetchOnWindowFocus: false,
  });
};

export default useChatAnswerMediaQuery;
