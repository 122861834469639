export const POLL_DROPDOWN_CLIENTS = [
  "coaniquem",
  "dentalstudio",
  "oas",
  "santa_blanca",
  "smiledesign",
  "smilekidscenter",
];
export const POLL_TABS_CLIENTS = ["centauro"];

export enum Features {
  HospitalWaitlists = "hospital_waitlists",
  Suspensions = "suspensions",
}

export enum Permissions {
  Dashboard = "dashboard",
  HospitalWaitlists = "hospital_waitlists",
  Suspensions = "suspensions",
}
