import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import useReportIssueMutation from "api/hooks/useReportIssueMutation";
import useServicesQuery from "api/hooks/useServicesQuery";
import { InteractionId } from "api/types/domain";
import logoCero from "assets/images/logo.png";
import Loader from "components/Loader";
import { getBlobsFromInteractionDrawer } from "helpers/slack";
import useAnalytics from "hooks/useAnalytics";

import "./IssueSubmissionDialog.css";

interface IssueSubmissionDialogProps {
  interactionId?: InteractionId;
  originComponentName: string;
  visible: boolean;
  onClose: () => void;
}

const IssueSubmissionDialog = ({
  interactionId,
  originComponentName,
  visible,
  onClose,
}: IssueSubmissionDialogProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { data: services } = useServicesQuery();
  const activeService = services?.find(
    (service) => service.id === interactionId?.serviceId,
  );
  const { mutate, isPending } = useReportIssueMutation();
  const [description, setDescription] = useState("");
  const track = useAnalytics();

  useEffect(() => {
    inputRef.current?.focus();
  }, [visible]);

  const reportIssue = async () => {
    const serviceName = activeService?.name;
    mutate(
      {
        serviceName,
        description,
        blobsGetter: getBlobsFromInteractionDrawer,
      },
      { onSettled: onClose },
    );
  };

  return (
    <form
      className={classNames({
        IssueSubmissionDialog: true,
        "IssueSubmissionDialog--visible": visible,
      })}
      onSubmit={(e) => {
        e.preventDefault();
        track("Feedback", originComponentName, "reportIssue");
        reportIssue();
      }}
    >
      <button
        className="IssueSubmissionDialog__close_button"
        onClick={() => onClose()}
        disabled={isPending}
        type="button"
      >
        <Icon icon="mdi:close" />
      </button>
      <h2 className="IssueSubmissionDialog__title">
        Reportar problema a{" "}
        <img
          src={logoCero}
          alt="CERO"
          className="IssueSubmissionDialog__logo"
        />
      </h2>
      <textarea
        className="IssueSubmissionDialog__input"
        disabled={isPending}
        ref={inputRef}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Por favor descríbenos el problema que encontraste en esta interacción"
      />
      <button
        className="IssueSubmissionDialog__submit_button"
        type="submit"
        disabled={isPending}
      >
        {isPending ? (
          <Loader color="white" />
        ) : (
          <>
            <Icon icon="mdi:alert" /> Reportar problema
          </>
        )}
      </button>
    </form>
  );
};

export default IssueSubmissionDialog;
