import classNames from "classnames";

import "./InteractionDrawerCover.css";

interface InteractionDrawerCoverProps {
  visible: boolean;
}

const InteractionDrawerCover = ({ visible }: InteractionDrawerCoverProps) => {
  return (
    <div
      className={classNames({
        InteractionDrawerCover: true,
        "InteractionDrawerCover--visible": visible,
      })}
    />
  );
};

export default InteractionDrawerCover;
