import { Icon } from "@iconify/react";
import classNames from "classnames";
import Dropzone, {
  DropEvent,
  DropzoneProps,
  DropzoneRef,
} from "react-dropzone";

import { ReactComponent as AttachmentLogo } from "assets/images/attachment.svg";
import Loader from "components/Loader";

import "./FileDropzone.css";

interface FileDropzoneProps
  extends Omit<DropzoneProps, "children" | "multiple">,
    React.RefAttributes<DropzoneRef> {
  selectedFile: File | undefined;
  isLoading?: boolean;
}

const FileDropzone = ({
  disabled,
  selectedFile,
  onDropAccepted,
  isLoading,
  ...props
}: FileDropzoneProps) => {
  return (
    <Dropzone
      {...props}
      multiple={false}
      disabled={disabled || isLoading || !!selectedFile}
      onDropAccepted={onDropAccepted}
    >
      {({
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
      }) => {
        const onClear = () => {
          if (onDropAccepted) {
            onDropAccepted([], {} as unknown as DropEvent);
          }
        };

        return (
          <div
            {...getRootProps({
              className: classNames("FileDropzone__wrapper", {
                "FileDropzone__wrapper--loading": isLoading,
                "FileDropzone__wrapper--clickable": !selectedFile,
                "FileDropzone__wrapper--focused": isFocused,
                "FileDropzone__wrapper--accept": isDragAccept,
                "FileDropzone__wrapper--reject": isDragReject,
              }),
            })}
          >
            <input {...getInputProps()} />
            <AttachmentLogo />
            <div className="FileDropzone__cta">
              {selectedFile ? (
                <>
                  {selectedFile.name}
                  <button className="FileDropzone__delete" onClick={onClear}>
                    <Icon icon="uil:trash-alt" />
                    Eliminar
                  </button>
                </>
              ) : isLoading ? (
                <span>Cargando archivo...</span>
              ) : (
                <span>
                  Arrastra y suelta aquí tu archivo o{" "}
                  <strong>selecciona un archivo para cargar</strong>
                </span>
              )}
            </div>
            {/* TODO: allow setting classNames over Loader to override size and color */}
            {isLoading && (
              <div className="FileDropzone__loader">
                <Loader />
              </div>
            )}
          </div>
        );
      }}
    </Dropzone>
  );
};

export default FileDropzone;
