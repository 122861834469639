import { captureException } from "@sentry/react";
import { useIsFetching } from "@tanstack/react-query";
import { endOfWeek, format } from "date-fns";
import { es } from "date-fns/locale";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";

import useDashboardTimeSeriesQuery from "api/hooks/useMetricsTimeSeriesQuery";
import Loader from "components/Loader/Loader";
import { dashboardSelector } from "store/slices/dashboard";

import { getDataGroupingXLabel, getMetricHexColor } from "../utils";
import "./TimeSeriesChart.css";

const TimeSeriesChart = () => {
  const { timeUnit } = useSelector(dashboardSelector);
  const { data, isPending, isError, error } = useDashboardTimeSeriesQuery();
  const isFetchingMetrics = useIsFetching({ queryKey: ["metrics"] });

  if (isPending || isFetchingMetrics || !data) {
    return <Loader />;
  }

  if (isError) {
    captureException(error);
    return <p>Hubo un problema al generar el gráfico.</p>;
  }

  const maxValue = Math.max(...data.map((d) => d.total));
  const order = 10 ** Math.floor(Math.log10(maxValue));
  const maxTick = order * Math.ceil(maxValue / order);
  const tickCount = 10;
  const stepSize = Math.round(maxTick / tickCount);

  return (
    <Line
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top" as const,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: getDataGroupingXLabel(timeUnit),
            },
            grid: {
              display: false,
            },
          },
          y: {
            min: 0,
            title: {
              display: true,
              text: "Citas",
            },
            ticks: {
              stepSize,
              callback: (val) => val.toLocaleString("de-DE"),
            },
          },
        },
      }}
      data={{
        labels: data.map((d) => {
          switch (timeUnit) {
            case "WEEK":
              return `Semana ${format(d.date, "II")}: ${format(
                d.date,
                "d/M",
              )} al ${format(endOfWeek(d.date, { weekStartsOn: 1 }), "d/M")}`;
            case "MONTH":
              return format(d.date, "MMMM", { locale: es });
            default:
              return format(d.date, "eee dd/MM", { locale: es });
          }
        }),
        datasets: [
          {
            label: "Total de Citas",
            data: data.map((d) => d.total),
            borderColor: getMetricHexColor("TOTAL"),
            backgroundColor: getMetricHexColor("TOTAL"),
            pointHitRadius: 25,
            borderWidth: 4,
            tension: 0.2,
          },
          {
            label: "Con respuesta",
            data: data.map((d) => d.answered),
            borderColor: getMetricHexColor("ANSWERED"),
            backgroundColor: getMetricHexColor("ANSWERED"),
            pointHitRadius: 25,
            borderWidth: 4,
            tension: 0.2,
          },
          {
            label: "Confirmadas",
            data: data.map((d) => d.confirmed),
            borderColor: getMetricHexColor("CONFIRMED"),
            backgroundColor: getMetricHexColor("CONFIRMED"),
            pointHitRadius: 25,
            borderWidth: 4,
            tension: 0.2,
          },
          {
            label: "Anuladas",
            data: data.map((d) => d.cancelled),
            borderColor: getMetricHexColor("CANCELLED"),
            backgroundColor: getMetricHexColor("CANCELLED"),
            pointHitRadius: 25,
            borderWidth: 4,
            tension: 0.2,
          },
        ],
      }}
    />
  );
};

export default TimeSeriesChart;
