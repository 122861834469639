import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import uploadSpreadsheet from "assets/images/waitlist-tour/01-upload-spreadsheet.jpg";
import assignFields from "assets/images/waitlist-tour/02-assign-fields.jpg";
import configureCampaign from "assets/images/waitlist-tour/03-configure-campaign.jpg";
import headerOverlayBanner from "assets/images/waitlist-tour/header-overlay-banner.png";
import Badge from "components/atoms/Badge";
import Button from "components/atoms/Button";
import Paginator from "components/atoms/Paginator";
import { tieneAccesoAListasEspera } from "helpers/permisos";
import { loginSelector } from "store/slices/login";
import { novedadesSelector, setOverlaySeen } from "store/slices/novedades";

import "./WaitlistOverlay.css";

const content = [
  {
    videoFilename: "",
    staticImage: headerOverlayBanner,
    title: "Simplifica la gestión de tus campañas de lista de espera ✨",
    paragraph:
      "Con esta funcionalidad, podrás contactar a los pacientes de cualquier lista de espera para verificar su información de contacto, confirmar su necesidad de atención y recopilar datos importantes para realizar altas administrativas cuando sea necesario. ¡Todo esto con una efectividad en la contactabilidad que te sorprenderá!",
    ctaLabel: "¡Muéstrame cómo!",
  },
  {
    videoFilename: "01-upload-spreadsheet",
    staticImage: uploadSpreadsheet,
    title: "Sube la planilla con el listado de pacientes",
    paragraph:
      "Selecciona tu archivo de hoja de cálculo y cárgalo. Este paso nos permitirá procesar la información de manera eficiente para que puedas crear y gestionar tus campañas.",
    ctaLabel: "Siguiente",
  },
  {
    videoFilename: "02-assign-fields",
    staticImage: assignFields,
    title: "Verifica la correcta asignación de los campos",
    paragraph:
      "Vincula las columnas de la planilla recién cargada con los campos esenciales para administrar la campaña. Nuestro sistema identificará las etiquetas estándar de SIGTE automáticamente.",
    ctaLabel: "Siguiente",
  },
  {
    videoFilename: "03-configure-campaign",
    staticImage: configureCampaign,
    title: "Configura tu campaña según tus preferencias",
    paragraph:
      "En este último paso, puedes configurar los detalles específicos de tu campaña. Personalizar algunos mensajes y previsualizarlo antes de enviar la campaña. También puedes programar el envío.",
    ctaLabel: "Crear campaña",
  },
];

const WaitlistOverlay = () => {
  const [step, setStep] = useState(0);
  const { cuenta, features, permissions } = useSelector(loginSelector);
  const { overlaySeen } = useSelector(novedadesSelector);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  if (!tieneAccesoAListasEspera(cuenta, permissions, features) || overlaySeen) {
    return null;
  }

  const handleCtaClick = () => {
    if (step === 3) {
      dispatch(setOverlaySeen());
      navigate("/listas-espera/campanyas/nueva");
      return;
    }
    setStep(step + 1);
  };

  return (
    <div className="WaitlistOverlay__backdrop">
      <div className="WaitlistOverlay__modal">
        {step === 0 ? (
          <img
            className="WaitlistOverlay__image"
            src={content[step].staticImage}
          />
        ) : (
          <div key={step} className="WaitlistOverlay__header">
            <video
              className="WaitlistOverlay__video_container"
              autoPlay
              loop
              muted
              playsInline
            >
              <source
                src={`waitlist-tour/${content[step].videoFilename}.mp4`}
                type="video/mp4"
              />
              <source
                src={`waitlist-tour/${content[step].videoFilename}.webm`}
                type="video/webm"
              />
              <img src={content[step].staticImage} />
            </video>
          </div>
        )}
        <div className="WaitlistOverlay__body">
          <div className="WaitlistOverlay__content">
            {step === 0 ? (
              <Badge text="Nuevo" icon="uil:bolt" />
            ) : (
              <Paginator steps={3} current={step} setCurrent={setStep} />
            )}
            <div className="WaitlistOverlay__text">
              <div className="WaitlistOverlay__title">
                {content[step].title}
              </div>
              <div className="WaitlistOverlay__paragraph">
                {content[step].paragraph}
              </div>
            </div>
          </div>
          <div className="WaitlistOverlay__footer">
            <Button variant="link" onClick={() => dispatch(setOverlaySeen())}>
              Omitir
            </Button>
            <Button onClick={handleCtaClick}>{content[step].ctaLabel}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitlistOverlay;
