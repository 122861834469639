// TODO: move to ../../hooks/
import { useQuery } from "@tanstack/react-query";
import * as _ from "lodash-es";
import { useSelector } from "react-redux";

import { loginSelector } from "store/slices/login";

import { Branch } from "../types/domain";

const useBranchesQuery = () => {
  const { sucursales } = useSelector(loginSelector);
  return useQuery<Branch[], unknown>({
    queryKey: ["branches"],
    queryFn: async () => {
      const branchesNames = _.uniq(sucursales!.map((s) => s.name));
      return _.sortBy(
        branchesNames.map((name) => ({
          id: name,
          name,
        })),
        "name",
      );
    },
  });
};

export default useBranchesQuery;
