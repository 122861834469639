import { InteractionExtraData } from "api/types/domain";
import useAnalytics from "hooks/useAnalytics";

import "./InteractionDataRow.css";

interface InteractionDataRowProps {
  data: InteractionExtraData;
  originComponentName: string;
}

const InteractionDataRow = ({
  data,
  originComponentName,
}: InteractionDataRowProps) => {
  const { header, value } = data;
  const track = useAnalytics();

  return (
    <div className="InteractionDataRow">
      <h4 className="InteractionDataRow__label">{header}</h4>
      <p
        className="InteractionDataRow__value"
        title={`Copiar "${value}"`}
        onClick={() => {
          navigator.clipboard.writeText(value + "");
          track("Feedback", originComponentName, "copy", {
            property: header,
            value,
          });
        }}
      >
        {typeof value === "object" && value !== null && "tag" in value
          ? value.tag
          : value}
      </p>
    </div>
  );
};

export default InteractionDataRow;
