import { format, isToday, isYesterday } from "date-fns";
import { es } from "date-fns/locale";

import { NoteId, PatientId, ServiceId } from "api/types/domain";

import "./InteractionNote.css";
import InteractionNoteIcon from "./InteractionNoteIcon";
import { Emoji } from "./InteractionNoteIcon/emojis";

interface InteractionNoteProps {
  serviceId: ServiceId;
  patientId: PatientId;
  id: NoteId;
  timestamp: Date;
  emoji: Emoji;
  text: string;
  originComponentName: string;
}

const InteractionNote = ({
  serviceId: _serviceId,
  patientId: _patientId,
  id: _id,
  timestamp,
  emoji,
  text,
  originComponentName: _originComponentName,
}: InteractionNoteProps) => {
  return (
    <div className="InteractionNote">
      <div className="InteractionNote__icon">
        <InteractionNoteIcon emoji={emoji} />
      </div>
      <div className="InteractionNote__time">
        {format(timestamp, "HH:mm")}
        {!isToday(timestamp) && (
          <div className="InteractionNote__day">
            {isYesterday(timestamp)
              ? "ayer"
              : format(timestamp, "d MMM", { locale: es })}
          </div>
        )}
      </div>
      <div className="InteractionNote__text">{text}</div>
    </div>
  );
};

export default InteractionNote;
