import apellidos from "./apellidos";
import { nombresHombres, nombresMujeres } from "./nombres";

const hashearString = (s: string) =>
  s.length > 0 ? s.split("").reduce((sum, v) => sum + v.charCodeAt(0), 0) : 0;

const comunasMenosPobladasDeChile = [
  "Antártica",
  "Laguna Blanca",
  "Ollagüe",
  "Timaukel",
  "Tortel",
  "Río Verde",
  "O'Higgins",
  "General Lagos",
  "San Gregorio",
  "Lago Verde",
  "Juan Fernández",
  "Primavera",
  "Torres del Paine",
  "Camiña",
  "Camarones",
  "Palena",
  "Colchane",
  "Guaitecas",
  "Cabo de Hornos",
  "Futaleufú",
];

const scrambleRut = (rut: string) => {
  if (!rut) {
    return rut;
  }
  let millones = hashearString(rut).toString();
  while (millones.length < 7) {
    millones += hashearString(millones).toString()[0];
  }
  const dv = hashearString(rut) % 10;
  return `${(+millones).toLocaleString("de-DE")}-${dv}`;
};

const sucursalesFalsas = comunasMenosPobladasDeChile.map((c) => `Clínica ${c}`);

const scrambleSucursal = (sucursal: string) => {
  return sucursalesFalsas[hashearString(sucursal) % sucursalesFalsas.length];
};

const usuariosFalsos = comunasMenosPobladasDeChile.map((c) => `Salud ${c}`);

const scrambleUsuario = (usuario: string) => {
  if (!usuario) {
    return "";
  }
  const soloPrimeraParte = usuario.split(/(?=[A-Z ])/)[0];
  return usuariosFalsos[
    hashearString(soloPrimeraParte) % usuariosFalsos.length
  ];
};

const obtenerNombre = (nombre: string) => {
  const i = hashearString(nombre);
  if (nombresHombres.indexOf(nombre) >= 0) {
    return nombresHombres[i % nombresHombres.length];
  } else if (nombresMujeres.indexOf(nombre) >= 0) {
    return nombresMujeres[i % nombresMujeres.length];
  } else {
    const nombresUnisex = [
      "Ariel",
      "Alex",
      "Cameron",
      "Cris",
      "Denis",
      "Robin",
      "Santana",
      "Zoel",
    ];
    return nombresUnisex[i % nombresUnisex.length];
  }
};

const scrambleNombre = (nombre: string) => {
  const partes = nombre.split(" ");
  return partes
    .slice(0, 3)
    .map((p, i) =>
      i < 1 ? obtenerNombre(p) : apellidos[hashearString(p) % apellidos.length],
    )
    .join(" ");
};

const scrambleTelefono = () => {
  return "+56 9 25555 1234";
};

const scrambleDireccion = (texto: string) => {
  return texto.replace(/[A-Z]\S{3,} [0-9]+/g, "Chinchillas 2021");
};

export const scrambleMulti = (
  textoOriginal: string,
  terminos: [string, string][],
) => {
  return scrambleDireccion(
    terminos.reduce((texto, termino): string => {
      return texto.replace(
        new RegExp(termino[0], "gi"),
        scramble(termino[0], termino[1]),
      );
    }, textoOriginal),
  );
};

/**
 * IMPORTANT: TypeScript's type system is not able to narrow down exclusion
 * if used over the generic `string` type, e.g. `Exclude<string, "multi">`.
 *
 * Therefore it's not possible to require the `terminos` argument if the value
 * passed as `tipo` is `"multi"` and disallow it otherwise, nor it is possible
 * to have a function which accepts everything but `"multi"` so that we do not
 * accidentally call it without it.
 */
export const scramble = (
  texto: string,
  tipo: string,
  terminos: [string, string][] = [],
) => {
  switch (tipo) {
    case "usuario":
      return scrambleUsuario(texto);
    case "rut":
      return scrambleRut(texto);
    case "address":
    case "direccion":
      return scrambleDireccion(texto);
    case "nombre":
    case "name":
    case "dentist_name":
    case "specialist_name_1":
    case "specialist_name_2":
    case "specialist_name_3":
    case "specialist_name_4":
      return scrambleNombre(texto);
    case "telefono":
    case "phone":
      return scrambleTelefono();
    case "sucursal":
    case "sucursal_name":
      return scrambleSucursal(texto);
    case "*":
      return Array(texto.length).fill("*").join("");
    // TODO: Maybe we should remove `"multi"` from this switch-case and require
    // users to call scrambleMulti() directly?
    case "multi":
      return scrambleMulti(texto, terminos);
    default:
      return texto;
  }
};
