import classNames from "classnames";
import { useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";

import InteractionDrawer from "components/Feedback/InteractionDrawer";
import InteractionDrawerCover from "components/Feedback/InteractionDrawer/InteractionDrawerCover";

import "./Alerts.css";
import AlertsList from "./AlertsList";
import AlertsSidebar from "./AlertsSidebar";
import ToggleSidebarButton from "./ToggleSidebarButton";

export const AlertsTopbar = () => (
  <div className="Alerts__topbar_left">
    <h2 className="Alerts__title">Alertas</h2>
    <ToggleSidebarButton />
  </div>
);

const Alerts = () => {
  const match = useMatch("alertas/:serviceId/:patientId");
  const navigate = useNavigate();

  const patientId = match?.params.patientId
    ? Number(match.params.patientId)
    : undefined;
  const serviceId = match?.params.serviceId
    ? Number(match.params.serviceId)
    : undefined;

  useEffect(() => {
    Notification.requestPermission();
  }, []);

  return (
    <div className="Alerts">
      <InteractionDrawerCover visible={!!patientId && !!serviceId} />
      <div
        className={classNames({
          Alerts__drawer: true,
          "Alerts__drawer--hidden": !patientId || !serviceId,
        })}
      >
        {patientId && serviceId && (
          <InteractionDrawer
            interactionId={{
              serviceId,
              patientId,
              start: new Date(),
            }}
            onCloseClick={() => navigate("/alertas")}
            originComponentName="Alerts"
          />
        )}
      </div>
      <div className="Alerts__container">
        <AlertsSidebar />
        <main className="Alerts__main">
          <AlertsList
            selectedPatientId={patientId}
            selectedServiceId={serviceId}
          />
        </main>
      </div>
    </div>
  );
};

export default Alerts;
