export const obtenerTiposEncuestasVisibles = <
  T extends { id: number | string },
>(
  cuenta: string | undefined,
  tipos: T[],
) => {
  const cuentaLC = cuenta?.toLowerCase() || "";
  if (cuentaLC.endsWith("_cero") || cuentaLC.endsWith("_agendamiento")) {
    return tipos;
  }
  const tiposEncuestas = tipos?.slice() || [];
  return tiposEncuestas.filter(
    (t) =>
      ![
        233, // Sanasalud Reactivación (deshabilitado)
        374, // FALP Convenios
        457, // Alemana Seguros

        892, // Nucleo Salud Agendamiento
        900, // Made Agendamiento
        1009, // Clinica Costanera Agendamiento
        1030, // Hospital Clínico Viña del Mar Agendamiento
        1063, // Interclínica Agendamiento
        1244, // Centauro Agendamiento
        1266, // Clínica Santa María Agendamiento
      ].includes(t.id as number),
  );
};
