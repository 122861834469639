import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";

import { isVideoId, videos } from "components/Feedback/Tutoriales/helpers";

import "./VideoContainer.css";

const VideoContainer = () => {
  const { videoId } = useParams<{ videoId: string }>();
  if (!isVideoId(videoId)) {
    return null;
  }
  const { titulo, link } = videos[videoId];
  return (
    <div className="VideoContainer">
      <h2 className="VideoContainer__title">
        <Icon icon="mdi:information" />
        Estás viendo: <span style={{ fontWeight: "bold" }}>{titulo}</span>
      </h2>
      <iframe
        className="VideoContainer__iframe"
        title={titulo}
        src={link}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoContainer;
