import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Scrambler from "components/Scrambler/Scrambler";
import { formatearNombreEncuesta } from "helpers/respuestas";
import { TipoEncuesta } from "store/slices/encuestas";
import { loginSelector } from "store/slices/login";
import { respuestasSelector } from "store/slices/respuestas";

import "./DropdownEncuesta.css";
import PopupEncuestas from "./PopupEncuestas";

interface DropdownEncuestaProps {
  encuestaSeleccionada: TipoEncuesta;
  verEncuesta: (id: number | string) => Promise<void>;
}

const DropdownEncuesta = ({
  encuestaSeleccionada,
  verEncuesta,
}: DropdownEncuestaProps) => {
  const { nombreUsuario } = useSelector(loginSelector);
  const [popupActivo, setPopupActivo] = useState(false);
  const { indiceRespuestaSeleccionada, nombreEncuestaFiltrada } =
    useSelector(respuestasSelector);
  const { patientId } = useParams();

  return (
    <div
      className={classNames({
        DropdownEncuesta: true,
        "DropdownEncuesta--visible":
          !patientId || indiceRespuestaSeleccionada !== undefined,
        "DropdownEncuesta--interactivo": !patientId,
      })}
      onClick={() => setPopupActivo(!patientId)}
    >
      <Icon
        className="DropdownEncuesta__icono_empresa"
        icon={nombreEncuestaFiltrada ? "mdi:map-marker-radius" : "mdi:whatsapp"}
        style={{
          color: encuestaSeleccionada.enabled ? "#48BB78" : "#9f9eae",
        }}
      />
      <div className="DropdownEncuesta__nombre_encuesta">
        <Scrambler tipo="multi">
          {nombreEncuestaFiltrada ||
            formatearNombreEncuesta(nombreUsuario, encuestaSeleccionada.nombre)}
        </Scrambler>
      </div>
      {!patientId && (
        <Icon
          className="DropdownEncuesta__icono_menu"
          icon="mdi:chevron-down"
        />
      )}
      <PopupEncuestas
        activo={popupActivo}
        esconder={() => setPopupActivo(false)}
        verEncuesta={verEncuesta}
      />
    </div>
  );
};

export default DropdownEncuesta;
