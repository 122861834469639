import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Alert, AlertId, PatientId, ServiceId } from "../types/domain";
import { UpdateAlertAPIResponse } from "../types/responses";
import { patch, API_ROOT } from "./utils";

type AlertKey = {
  alertId: AlertId;
  serviceId: ServiceId;
  patientId: PatientId;
};

type AlertStatus = {
  solved: boolean;
};

type AlertsBeforeMutation = {
  alertsBeforeMutation: Alert[];
  patientAlertsBeforeMutation: Alert[];
};

const useChangeAlertStatusMutation = ({
  alertId,
  serviceId,
  patientId,
}: AlertKey) => {
  const url = `${API_ROOT}/alerts/${alertId}`;
  const queryClient = useQueryClient();
  return useMutation<
    UpdateAlertAPIResponse,
    unknown,
    AlertStatus,
    AlertsBeforeMutation
  >({
    mutationFn: async ({ solved }) => {
      const { data } = await patch<UpdateAlertAPIResponse>(url, {
        dismissed: solved,
      });
      return data;
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ["interaction"] });
      queryClient.invalidateQueries({ queryKey: ["alerts"] });

      const alertsBeforeMutation = queryClient.getQueryData<Alert[]>([
        "alerts",
      ]);
      if (alertsBeforeMutation) {
        const mutatedAlert = alertsBeforeMutation.find((a) => a.id === alertId);
        if (mutatedAlert) {
          mutatedAlert.solved = data.dismissed;
        }
        const alertsWithMutatedAlert = [
          ...alertsBeforeMutation.filter((a) => a.id !== alertId),
          mutatedAlert,
        ];
        queryClient.setQueryData(["alerts"], alertsWithMutatedAlert);
      }

      const patientAlertsBeforeMutation = queryClient.getQueryData<Alert[]>([
        "alerts",
        serviceId,
        patientId,
      ]);
      if (patientAlertsBeforeMutation) {
        const patientMutatedAlert = patientAlertsBeforeMutation.find(
          (a) => a.id === alertId,
        );
        if (patientMutatedAlert) {
          patientMutatedAlert.solved = data.dismissed;
        }
        const patientAlertsWithMutatedAlert = [
          ...patientAlertsBeforeMutation.filter((a) => a.id !== alertId),
          patientMutatedAlert,
        ];
        queryClient.setQueryData(
          ["alerts", serviceId, patientId],
          patientAlertsWithMutatedAlert,
        );
      }
    },
  });
};

export default useChangeAlertStatusMutation;
