import { useMutation } from "@tanstack/react-query";

import { PostSpreadsheetResponse, campaignsApi } from "feedback-api";

export class MaxRowsExceededError extends Error {
  submittedRows: number;
  maxRows: number;
  constructor(
    submittedRows: number,
    maxRows: number,
    message?: string,
    options?: ErrorOptions,
  ) {
    super(message, options);
    this.maxRows = maxRows;
    this.submittedRows = submittedRows;
    this.name = "MaxRowsExceededError";
  }
}

const useProcessSpreadsheetMutation = (maxRows: number) => {
  return useMutation<PostSpreadsheetResponse, unknown, File>({
    mutationFn: async (spreadsheet: File) => {
      const { data } =
        await campaignsApi.processSpreadsheetV4CampaignsSpreadsheetsPost(
          spreadsheet,
        );
      if (maxRows < data.records.length) {
        throw new MaxRowsExceededError(data.records.length, maxRows);
      }
      return data;
    },
  });
};

export default useProcessSpreadsheetMutation;
