import { toBlob } from "html-to-image";

const placeholder =
  "data:image/gif;base64,R0lGODlhAQABAIABAFVN9f///yH5BAEKAAEALAAAAAABAAEAAAICRAEAOw==";

export const getBlobsFromInteractionDrawer = async () => {
  return await getBlobs({
    mainContainerClass: "InteractionDrawer",
    messagesContainerClass: "Smartphone__messages_container",
    notCurrentElementsSelector: ".Smartphone__element_not_current",
  });
};

export const getBlobsFromChat = async () => {
  return await getBlobs({
    mainContainerClass: "Feedback__contenedor_central",
    messagesContainerClass: "CelularWhatsapp__contenedor_mensajes",
    notCurrentElementsSelector:
      ".CelularWhatsapp__contenedor_conversacion:not(.CelularWhatsapp__contenedor_conversacion--seleccionada)",
  });
};

const getBlobs = async ({
  mainContainerClass,
  messagesContainerClass,
  notCurrentElementsSelector,
}: {
  mainContainerClass: string;
  messagesContainerClass: string;
  notCurrentElementsSelector: string;
}) => {
  const previousDisplayStyles = Array.from(
    document.querySelectorAll<HTMLElement>(notCurrentElementsSelector),
  ).map((nodo) => nodo.style.display);
  document
    .querySelectorAll<HTMLElement>(notCurrentElementsSelector)
    .forEach((nodo) => {
      nodo.style.display = "none";
    });

  const mainContainerElement = document.getElementsByClassName(
    mainContainerClass,
  )[0] as HTMLElement;
  const blobFB = await toBlob(mainContainerElement, {
    width: mainContainerElement.scrollWidth,
    height: mainContainerElement.scrollHeight,
    imagePlaceholder: placeholder,
  });

  const messagesContainerElement = document.getElementsByClassName(
    messagesContainerClass,
  )[0] as HTMLElement;
  const prevMaxHeight = messagesContainerElement.style.maxHeight;
  messagesContainerElement.style.overflow = "visible";
  messagesContainerElement.style.maxHeight = "auto";
  const blob = await toBlob(messagesContainerElement, {
    width: messagesContainerElement.scrollWidth,
    height: messagesContainerElement.scrollHeight,
    imagePlaceholder: placeholder,
  });
  messagesContainerElement.style.overflow = "auto";
  messagesContainerElement.style.maxHeight = prevMaxHeight;
  document
    .querySelectorAll<HTMLElement>(notCurrentElementsSelector)
    .forEach((nodo, i) => {
      nodo.style.display = previousDisplayStyles[i];
    });
  return { blobFB, blob };
};
