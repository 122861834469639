import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSelector } from "react-redux";

import { postMessage } from "api/hooks/useSlackPostMessageMutation";
import { loginSelector } from "store/slices/login";

import { FilesUploadResponse } from "../types/slack";

type ReportIssueVars = {
  issueType?: string;
  serviceName?: string;
  description: string;
  blobsGetter: () => Promise<{ blobFB: Blob | null; blob: Blob | null }>;
};

const useReportIssueMutation = () => {
  const { nombreUsuario, cuenta } = useSelector(loginSelector);
  return useMutation<string, unknown, ReportIssueVars>({
    mutationKey: ["issue"],
    mutationFn: async ({
      description,
      serviceName,
      issueType = "Bot se equivoca",
      blobsGetter,
    }) => {
      const blobs = await blobsGetter();

      const result = await reportToSlackChannel({
        channelID: cuenta?.endsWith("cero")
          ? (process.env.REACT_APP_SLACK_INTERNAL_CHANNEL_ID as string)
          : (process.env.REACT_APP_SLACK_CHANNEL_ID as string),
        messageText: `Feedback: Reporte desde *${nombreUsuario}*`,
        contentTitle: `Reporte desde *${nombreUsuario}*`,
        contentFields: [
          {
            label: "Tipo",
            value: `${issueType} ${getIssueTypeEmoji(issueType)}`,
          },
          {
            label: "Cuenta",
            value: cuenta || "??",
          },
          {
            label: "Servicio",
            value: serviceName || "??",
          },
          {
            label: "URL",
            value: window.location.href,
          },
          {
            label: "Descripción",
            value: description,
          },
        ],
        ...blobs,
      });
      await axios<undefined>({
        method: "post",
        url: "https://eoyqyij8fcu0p4y.m.pipedream.net",
        data: {
          client: nombreUsuario,
          service: serviceName,
          description,
          feedback_username: cuenta,
          feedback_url: window.location.href,
        },
      });
      return result;
    },
  });
};

const reportToSlackChannel = async ({
  channelID,
  messageText,
  contentTitle,
  contentFields,
  blobFB,
  blob,
}: {
  channelID: string;
  messageText: string;
  contentTitle: string;
  contentFields: { label: string; value: string }[];
  blobFB: Blob | null;
  blob: Blob | null;
}) => {
  const response = await postMessage({
    channel: channelID,
    text: messageText,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: contentTitle,
        },
      },
      {
        type: "section",
        fields: contentFields.map((t) => ({
          type: "mrkdwn",
          text: `*${t.label}*\n${t.value}`,
        })),
      },
    ],
  });
  const fieldsTimestamp = response.message.ts;

  const formData = {
    token: process.env.REACT_APP_OAUTH2_TOKEN!,
    channels: channelID,
    file: blobFB,
    thread_ts: fieldsTimestamp,
  };
  await axios.postForm<FilesUploadResponse>(
    "https://slack.com/api/files.upload",
    formData,
  );

  formData.file = blob;
  await axios.postForm<FilesUploadResponse>(
    "https://slack.com/api/files.upload",
    formData,
  );

  return fieldsTimestamp;
};

const getIssueTypeEmoji = (issueType: string) => {
  switch (issueType) {
    case "Bot se equivoca":
      return "🤖";
    case "Paciente se molesta":
      return "😡";
    default:
      return "👀";
  }
};

export default useReportIssueMutation;
