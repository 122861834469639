import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

const ceroSlice = createSlice({
  name: "cero",
  initialState: {
    debugging: false,
  },
  reducers: {
    toggleDebugging(state) {
      state.debugging = !state.debugging;
    },
  },
});

export const { toggleDebugging } = ceroSlice.actions;
export const ceroSelector = (state: RootState) => state.cero;

export default ceroSlice.reducer;
