import { useSelector } from "react-redux";

import { toastsSelector } from "store/slices/toasts";

import Toast from "./Toast";
import "./Toaster.css";

const Toaster = () => {
  const { toasts, maxVisibleToasts } = useSelector(toastsSelector);
  const visibleToasts = toasts.slice(0, maxVisibleToasts);

  return (
    <div className="Toaster">
      {visibleToasts.map((toast) => (
        <Toast key={toast.id} toast={toast} />
      ))}
    </div>
  );
};

export default Toaster;
