// TODO: move to ../../hooks/
import { useQuery } from "@tanstack/react-query";
import { format, isSaturday, isSunday } from "date-fns";
import { useSelector } from "react-redux";

import { MetricCount } from "feedback-api";
import { dashboardSelector } from "store/slices/dashboard";

import useMetricsQuery from "./useMetricsQuery";

export type ProgressMetric = "TOTAL" | "ANSWERED" | "CONFIRMED" | "CANCELLED";

type ProgressMetricData = {
  title: string;
  count: string;
  label: string;
  fillPercentage: number;
};

const useMetricsProgressQuery = ({ metric }: { metric: ProgressMetric }) => {
  const {
    start: startDate,
    end: endDate,
    includeSaturdays,
    includeSundays,
    filters,
  } = useSelector(dashboardSelector);
  const { data: metricsData } = useMetricsQuery();
  const start = format(startDate, "yyyy-MM-dd");
  const end = format(endDate, "yyyy-MM-dd");

  return useQuery<ProgressMetricData, unknown>({
    queryKey: [
      "dashboard-progress",
      start,
      end,
      metric,
      includeSaturdays,
      includeSundays,
      filters,
    ],
    queryFn: (): ProgressMetricData => {
      if (!metricsData) {
        return {
          title: "",
          count: "",
          label: "",
          fillPercentage: 0,
        };
      }
      const filteredMetricsData = filterMetrics({
        data: metricsData,
        filters: [
          (d: MetricCount) => !isSaturday(d.date),
          (d: MetricCount) => !isSunday(d.date),
        ],
      });
      switch (metric) {
        case "TOTAL":
          return buildProgressMetricData({
            data: filteredMetricsData,
            metricKey: "total",
            label: "Citas",
          });
        case "ANSWERED":
          return buildProgressMetricData({
            data: filteredMetricsData,
            metricKey: "answered",
            label: "Con Respuesta",
          });
        case "CONFIRMED":
          return buildProgressMetricData({
            data: filteredMetricsData,
            metricKey: "confirmed",
            label: "Confirmadas",
          });
        case "CANCELLED":
          return buildProgressMetricData({
            data: filteredMetricsData,
            metricKey: "cancelled",
            label: "Anuladas",
          });
        default:
          return {
            title: "",
            count: "",
            label: "",
            fillPercentage: 0,
          };
      }
    },
    enabled: !!metricsData,
  });
};

const filterMetrics = ({
  data,
  filters,
}: {
  data: MetricCount[];
  filters: ((f: MetricCount) => boolean)[];
}): MetricCount[] => {
  let filteredMetricsData = data;
  filters.forEach((f) => {
    filteredMetricsData = filteredMetricsData.filter(f);
  });
  return filteredMetricsData;
};

const buildProgressMetricData = ({
  data,
  metricKey,
  label,
}: {
  data: MetricCount[];
  metricKey: "total" | "answered" | "confirmed" | "cancelled";
  label: string;
}): ProgressMetricData => {
  const metricCount = data.reduce(
    (a: number, v: MetricCount) => a + v[metricKey],
    0,
  );
  if (metricKey === "total") {
    return {
      title: "Total",
      count: metricCount.toLocaleString("de-DE"),
      label,
      fillPercentage: 100,
    };
  }
  const total = data.reduce((a: number, v: MetricCount) => a + v.total, 0);
  const percentage = total > 0 ? (100 * metricCount) / total : 0;
  return {
    title:
      percentage.toLocaleString("de-DE", {
        maximumFractionDigits: 0,
      }) + "%",
    count: metricCount.toLocaleString("de-DE"),
    label,
    fillPercentage: percentage,
  };
};

export default useMetricsProgressQuery;
