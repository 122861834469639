import { Icon } from "@iconify/react";
import { useIsMutating } from "@tanstack/react-query";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import InteractionNoteIcon from "../InteractionNotes/InteractionNote/InteractionNoteIcon";
import "./IssueSubmissionAck.css";

const IssueSubmissionAck = () => {
  const [visible, setVisible] = useState(false);

  const nMutations = useIsMutating({ mutationKey: ["issue"] });
  const currentMutations = useRef<number>();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (currentMutations.current === 1 && nMutations === 0) {
      setVisible(true);
      timeoutId = setTimeout(() => setVisible(false), 5_000);
    }
    currentMutations.current = nMutations;
    return () => clearTimeout(timeoutId);
  }, [nMutations]);

  return (
    <div
      className={classNames({
        IssueSubmissionAck: true,
        "IssueSubmissionAck--visible": visible,
      })}
    >
      ¡Gracias por tu reporte! <InteractionNoteIcon emoji="😊" />
      <button
        className="IssueSubmissionAck__close_button"
        onClick={() => setVisible(false)}
      >
        <Icon icon="mdi:close" />
      </button>
    </div>
  );
};

export default IssueSubmissionAck;
