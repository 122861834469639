import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

export const ESQUEMA_CLARO = "ESQUEMA_CLARO";
export const ESQUEMA_OSCURO = "ESQUEMA_OSCURO";

interface OpcionesState {
  idEncuestaGuardada?: number;
  contacto: string;
  esquema: typeof ESQUEMA_CLARO | typeof ESQUEMA_OSCURO;
}

const opcionesSlice = createSlice({
  name: "opciones",
  initialState: {
    contacto: "",
    esquema: ESQUEMA_CLARO,
  } as OpcionesState,
  reducers: {
    guardaIdEncuesta(state, action: PayloadAction<number>) {
      state.idEncuestaGuardada = action.payload;
    },
    cambiaEsquemaColor(state) {
      state.esquema =
        state.esquema === ESQUEMA_CLARO ? ESQUEMA_OSCURO : ESQUEMA_CLARO;
    },
    guardaContacto(state, action: PayloadAction<string>) {
      state.contacto = action.payload;
    },
  },
});

export const { guardaIdEncuesta, cambiaEsquemaColor, guardaContacto } =
  opcionesSlice.actions;
export const opcionesSelector = (state: RootState) => state.opciones;

export default opcionesSlice.reducer;
