import { useEffect, useState } from "react";

import useSuspensionMessagePreviews from "api/hooks/useSuspensionMessagePreviews";
import MessagePreview from "components/organisms/MessagePreview";
import { CandidateAppointment } from "feedback-api";

interface SuspensionMessagePreviewProps {
  candidateAppointments: CandidateAppointment[];
}

const SuspensionMessagePreview = ({
  candidateAppointments,
}: SuspensionMessagePreviewProps) => {
  const [selectedAppointment, setSelectedAppointment] = useState(
    candidateAppointments[0],
  );
  const [selectedMessageId, setSelectedMessageId] = useState("initial");

  const {
    data: previews,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useSuspensionMessagePreviews(selectedAppointment.appointment_data);

  useEffect(() => {
    refetch();
  }, [selectedAppointment, refetch]);

  return (
    <MessagePreview
      messageTypeOptions={previews?.map(({ id, display_name }) => ({
        value: id,
        label: display_name,
      }))}
      selectedMessageType={previews
        ?.map(({ id, display_name }) => ({ value: id, label: display_name }))
        .find(({ value }) => value === selectedMessageId)}
      patientDataOptions={candidateAppointments?.map(
        ({ id, patient_name, appointment_data }) => ({
          value: id,
          label: patient_name,
          appointment_data,
        }),
      )}
      selectedPatientData={{
        label: selectedAppointment.patient_name,
        value: selectedAppointment.id,
        appointment_data: selectedAppointment.appointment_data,
      }}
      message={previews?.find(({ id }) => id === selectedMessageId)?.message}
      isSuccess={isSuccess}
      isFetching={isFetching}
      isLoading={isLoading}
      onChange={(newMessageType, newPatientData) => {
        setSelectedMessageId(newMessageType.value);
        if (newPatientData.value !== selectedAppointment?.id) {
          setSelectedAppointment(
            candidateAppointments.find(
              ({ id }) => id === newPatientData.value,
            )!,
          );
        }
      }}
    />
  );
};

export default SuspensionMessagePreview;
