import { combineReducers } from "@reduxjs/toolkit";

import alerts from "./alerts";
import cero from "./cero";
import dashboard from "./dashboard";
import encuestas from "./encuestas";
import enviador from "./enviador";
import login from "./login";
import novedades from "./novedades";
import opciones from "./opciones";
import reacciones from "./reacciones";
import respuestas from "./respuestas";
import scrambler from "./scrambler";
import search from "./search";
import toasts from "./toasts";

const rootReducer = combineReducers({
  login,
  encuestas,
  respuestas,
  opciones,
  scrambler,
  enviador,
  cero,
  reacciones,
  novedades,
  search,
  alerts,
  dashboard,
  toasts,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
