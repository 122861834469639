import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";

import useAnalytics from "hooks/useAnalytics";
import { alertsSelector, showSettings } from "store/slices/alerts";

import "./ToggleSidebarButton.css";

const ToggleSidebarButton = () => {
  const dispatch = useDispatch();
  const { settingsHidden } = useSelector(alertsSelector);
  const track = useAnalytics();

  if (!settingsHidden) {
    return null;
  }

  return (
    <button
      className="ToggleSidebarButton"
      onClick={() => {
        track("Feedback", "Alerts", "showAlertSettings");
        dispatch(showSettings());
      }}
      title="Abrir configuración"
    >
      <Icon icon="mdi:cog" />
    </button>
  );
};

export default ToggleSidebarButton;
