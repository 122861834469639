import classNames from "classnames";
import { useSelector } from "react-redux";

import SmartphoneMessage from "components/Feedback/InteractionDrawer/Smartphone/SmartphoneMessage";
import Loader from "components/Loader";
import Select from "components/atoms/Select";
import { loginSelector } from "store/slices/login";

import "./MessagePreview.css";

type MessagePreviewProps<MessageTypeOption, PatientDataOption> = {
  messageTypeOptions?: MessageTypeOption[];
  patientDataOptions?: PatientDataOption[];
  selectedMessageType?: MessageTypeOption;
  selectedPatientData?: PatientDataOption;
  onChange: (
    newMessageType: MessageTypeOption,
    newPatientData: PatientDataOption,
  ) => void;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  message: string | undefined;
};

const MessagePreview = <MessageTypeOption, PatientDataOption>({
  messageTypeOptions,
  selectedMessageType,
  patientDataOptions,
  selectedPatientData,
  isLoading,
  isFetching,
  isSuccess,
  message,
  onChange,
}: MessagePreviewProps<MessageTypeOption, PatientDataOption>) => {
  const { nombreUsuario } = useSelector(loginSelector);
  return (
    <div className="MessagePreview">
      <div className="MessagePreview__header">
        <h3 className="MessagePreview__title">Previsualización del mensaje</h3>
        <div className="MessagePreview__controls">
          <fieldset className="MessagePreview__field">
            <label className="MessagePreview__label">Tipo de mensaje</label>
            <Select
              menuPortalTarget={document.getElementById("columns-selector")}
              value={selectedMessageType}
              onChange={(newValue) => onChange(newValue!, selectedPatientData!)}
              options={messageTypeOptions}
            />
          </fieldset>
          <fieldset className="MessagePreview__field">
            <label className="MessagePreview__label">Paciente</label>
            <Select
              menuPortalTarget={document.getElementById("columns-selector")}
              value={selectedPatientData}
              onChange={(newValue) => onChange(selectedMessageType!, newValue!)}
              options={patientDataOptions}
            />
          </fieldset>
        </div>
      </div>
      <div
        className={classNames("MessagePreview__renders", {
          "MessagePreview__renders--refetching": isFetching,
        })}
      >
        {isSuccess && (
          <>
            <span className="MessagePreview__sender">{nombreUsuario}</span>
            <SmartphoneMessage
              data={{
                message: {
                  timestamp: new Date(),
                  content: message ?? "",
                  sender: "BOT",
                  type: "TEXTO",
                  id: 0,
                },
                current: true,
                date: new Date(),
              }}
            />
          </>
        )}
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default MessagePreview;
