import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useState } from "react";

import useNotesQuery from "api/hooks/useNotesQuery";
import { InteractionId } from "api/types/domain";
import Loader from "components/Loader";
import useIsLabeler from "hooks/useIsLabeler";

import InteractionNote from "./InteractionNote";
import { Emoji } from "./InteractionNote/InteractionNoteIcon/emojis";
import "./InteractionNotes.css";
import NewNotePopup from "./NewNotePopup";

interface InteractionNotesProps {
  interactionId?: InteractionId;
  originComponentName: string;
}

const InteractionNotes = ({
  interactionId,
  originComponentName,
}: InteractionNotesProps) => {
  const [isNewNoteModalOpen, setIsNewNoteModalOpen] = useState(false);
  const {
    data: notes,
    isPending,
    isRefetching,
  } = useNotesQuery({ interactionId });
  const isLabeler = useIsLabeler();

  return (
    <div className="InteractionNotes">
      <h3 className="InteractionNotes__title">
        <Icon icon="mdi:note-text-outline" />
        Notas
      </h3>
      <div
        className={classNames("InteractionNotes__notes_container", {
          "InteractionNotes__notes_container--refetching": isRefetching,
        })}
      >
        {isPending || !interactionId ? (
          <Loader />
        ) : notes?.length === 0 ? (
          <p className="InteractionNotes__no_notes">No hay notas</p>
        ) : (
          notes?.map((note, i) => (
            <InteractionNote
              patientId={interactionId.patientId}
              serviceId={interactionId.serviceId}
              id={note.id}
              timestamp={note.timestamp}
              emoji={note.emoji as Emoji}
              text={note.text}
              key={`note-${i}`}
              originComponentName={originComponentName}
            />
          ))
        )}
      </div>
      {!isLabeler && (
        <button
          className="InteractionNotes__add_note_button"
          onClick={() => setIsNewNoteModalOpen(true)}
        >
          <Icon icon="mdi:note-plus-outline" />
          Agregar nota
        </button>
      )}
      {interactionId && (
        <NewNotePopup
          interactionId={interactionId}
          isOpen={isNewNoteModalOpen}
          close={() => setIsNewNoteModalOpen(false)}
          originComponentName={originComponentName}
        />
      )}
    </div>
  );
};

export default InteractionNotes;
