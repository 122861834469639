import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

import Button from "components/atoms/Button";

import "./CompletedSuspensionStep.css";

interface CompletedSuspensionStepProps {
  resetForm: () => void;
  selectedProfesional: string;
  appointmentsCount: number;
}

const CompletedSuspensionStep = ({
  resetForm,
  selectedProfesional,
  appointmentsCount,
}: CompletedSuspensionStepProps) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="CompletedSuspension__container">
        <div className="CompletedSuspension__header_icon_background">
          <Icon
            icon="uil:comment-verify"
            className="CompletedSuspension__header_icon"
          />
        </div>
        <div className="CompletedSuspension__data">
          <div className="CompletedSuspension__data_text">
            <p className="CompletedSuspension__data_title">
              ¡El envío de mensajes se ha iniciado con éxito!
            </p>
            <p className="CompletedSuspension__data_subtitle">
              Se está notificando a {appointmentsCount} pacientes sobre la
              suspensión de sus citas con {selectedProfesional}
            </p>
          </div>
          <div className="CompletedSuspension__data_buttons">
            <Button onClick={resetForm}>Nueva Suspensión</Button>
            <Button variant="outline" onClick={() => navigate("/")}>
              Ver respuestas
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedSuspensionStep;
