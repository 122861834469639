import { Icon } from "@iconify/react";
import { isAfter, setHours, setMinutes } from "date-fns";
import { useSelector } from "react-redux";

import { encuestasSelector } from "store/slices/encuestas";
import { respuestasSelector } from "store/slices/respuestas";

import "./BodyTablaSinRespuestas.css";

const BodyTablaSinRespuestas = () => {
  const { fechaTermino, filtros } = useSelector(respuestasSelector);
  const { tipos, idEncuestaSeleccionada } = useSelector(encuestasSelector);

  if (!idEncuestaSeleccionada) {
    return null;
  }

  const hayFiltros = filtros?.length > 0;
  const encuestaSeleccionada = tipos?.find(
    ({ id }) => id === idEncuestaSeleccionada,
  );
  const horaInicio = encuestaSeleccionada?.integrations?.[0]?.start_time?.slice(
    0,
    5,
  );
  const yaFue =
    horaInicio &&
    isAfter(
      Date.now(),
      setMinutes(setHours(fechaTermino, Number(horaInicio.slice(0, 2))), 0),
    );

  let icono = "mdi:robot-confused";
  let mensaje = <p>No hay respuestas</p>;
  if (hayFiltros) {
    mensaje = <p>Su búsqueda no tuvo resultados</p>;
  } else if (horaInicio && !yaFue) {
    const [horas, minutos] = horaInicio.split(":");
    mensaje = (
      <p>
        Las interacciones con pacientes
        <br />
        comienzan a las{" "}
        <strong>
          {horas}:{minutos}
        </strong>
      </p>
    );
    icono = "mdi:robot";
  }

  return (
    <span className="BodyTablaSinRespuestas">
      <Icon className="BodyTablaSinRespuestas__icono" icon={icono} />
      {mensaje}
    </span>
  );
};

export default BodyTablaSinRespuestas;
