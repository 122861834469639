// TODO: move to ../../hooks/
import * as _ from "lodash-es";

import { PatientId, ServiceId } from "../types/domain";
import useActiveAlertsQuery, { ActiveAlerts } from "./useActiveAlertsQuery";

const getActiveAlertsForPatient = (
  activeAlerts: ActiveAlerts,
  serviceId: ServiceId,
  patientId: PatientId,
) => {
  const alerts = [...activeAlerts.pending, ...activeAlerts.solved];
  return alerts.filter((alert) =>
    _.isEqual([alert.patientId, alert.serviceId], [patientId, serviceId]),
  );
};

const useAlertsForPatientQuery = ({
  serviceId,
  patientId,
}: {
  serviceId?: ServiceId;
  patientId?: PatientId;
}) => {
  const { data: activeAlerts } = useActiveAlertsQuery();

  if (!activeAlerts || !serviceId || !patientId) {
    return [];
  }
  return getActiveAlertsForPatient(activeAlerts, serviceId, patientId);
};

export default useAlertsForPatientQuery;
