import { InlineIcon } from "@iconify/react";

import { Categoria } from "store/slices/respuestas";

import "./TagRespuesta.css";

const maxEstrellas = 5;

const obtenerClaseTag = (
  tag: TagRespuestaProps["tag"],
  pregunta: TagRespuestaProps["pregunta"],
) => {
  if (tag) {
    return tag.clase;
  } else if (pregunta === "Opción elegida" || pregunta === "¿Por qué no?") {
    return "TagRespuesta--opcion_reagendamiento";
  } else if (!isNaN(tag)) {
    return "TagRespuesta--nota";
  }
  return "TagRespuesta TagRespuesta--vacia";
};

const obtenerTextoTag = (
  tag: TagRespuestaProps["tag"],
  pregunta: TagRespuestaProps["pregunta"],
) => {
  if (tag) {
    return tag.texto;
  } else if (pregunta === "Opción elegida" || pregunta === "¿Por qué no?") {
    return tag;
  } else if (!isNaN(tag)) {
    return (
      <>
        {Array(+tag)
          .fill(0)
          .map((_, i) => (
            <InlineIcon
              key={`estrella-activa-${i}`}
              className="TagRespuesta__icono_estrella"
              icon="mdi:star"
            />
          ))}
        {Array(Math.max(0, maxEstrellas - +tag))
          .fill(0)
          .map((_, i) => (
            <InlineIcon
              key={`estrella-inactiva-${i}`}
              className="TagRespuesta__icono_estrella TagRespuesta__icono_estrella--inactiva"
              icon="mdi:star"
            />
          ))}
      </>
    );
  }
  return tag;
};

interface TagRespuestaProps {
  tag: Categoria["niveles"][0];
  pregunta?: string | number | null;
  incluirSinRespuesta?: boolean;
  contactadoPorTelefono?: boolean | null;
}

const TagRespuesta = ({
  tag,
  pregunta,
  incluirSinRespuesta = false,
  contactadoPorTelefono = null,
}: TagRespuestaProps) => {
  if (!tag.titulo && !incluirSinRespuesta) {
    return null;
  }

  return (
    <div className={obtenerClaseTag(tag, pregunta)}>
      {contactadoPorTelefono && (
        <div className="TagRespuesta__contacto_telefonico">
          <InlineIcon icon="mdi:phone" />
        </div>
      )}
      <InlineIcon className="TagRespuesta__icono" icon={tag.icono} />
      <span>{obtenerTextoTag(tag, pregunta)}</span>
    </div>
  );
};

export default TagRespuesta;
