import { captureException } from "@sentry/react";
import * as _ from "lodash-es";
import { useEffect, useState } from "react";

import useCampaignMessagePreviews from "api/hooks/useCampaignMessagePreviews";
import MessagePreview from "components/organisms/MessagePreview";
import { PostSpreadsheetResponse } from "feedback-api";

interface MessagePreviewProps {
  spreadsheetData: PostSpreadsheetResponse | undefined;
  fieldMapping: Record<string, string[]> | undefined;
  campaignMetas: { [x: string]: string | boolean | undefined };
  selectedMessageId: string | undefined;
  setSelectedMessageId: (selectedMessageId?: string) => void;
}

const CampaignMessagePreview = ({
  spreadsheetData,
  fieldMapping,
  campaignMetas,
  selectedMessageId,
  setSelectedMessageId,
}: MessagePreviewProps) => {
  const getPatientName = (
    record: Record<string, string>,
    mapping: Record<string, string[]>,
  ) => {
    return _.startCase(
      [
        record[mapping["Nombres"][0]],
        record[mapping["Primer Apellido"][0]],
        record[mapping["Segundo Apellido"][0]],
      ]
        .join(" ")
        .toLowerCase(),
    );
  };

  const getCaseId = (
    record: Record<string, string>,
    mapping: Record<string, string[]>,
  ) => {
    return record[mapping["ID SIGTE"][0]];
  };

  const getSelectOption = (
    record: Record<string, string>,
    mapping: Record<string, string[]>,
  ) => {
    return {
      label: getPatientName(record, mapping),
      value: getCaseId(record, mapping),
    };
  };

  const records = spreadsheetData?.records as
    | Record<string, string>[]
    | undefined;
  const [record, setRecord] = useState(records?.[0]);
  const {
    data: previews,
    error,
    isSuccess,
    isError,
    isLoading,
    isFetching,
    refetch: refetchPreviews,
  } = useCampaignMessagePreviews(record, fieldMapping, campaignMetas);

  useEffect(() => {
    refetchPreviews();
  }, [record, campaignMetas, refetchPreviews]);

  if (!record || !fieldMapping) {
    return null;
  }

  if (isError) {
    captureException(error);
  }

  return (
    <MessagePreview
      messageTypeOptions={previews?.map(({ id, display_name }) => ({
        value: id,
        label: display_name,
      }))}
      selectedMessageType={previews
        ?.map(({ id, display_name }) => ({ value: id, label: display_name }))
        .find((preview) => preview.value === selectedMessageId)}
      patientDataOptions={records?.map((r) => getSelectOption(r, fieldMapping))}
      selectedPatientData={getSelectOption(record, fieldMapping)}
      message={
        previews?.find((preview) => preview.id === selectedMessageId)?.message
      }
      isSuccess={isSuccess}
      isFetching={isFetching}
      isLoading={isLoading}
      onChange={(newMessageType, newPatientData) => {
        setSelectedMessageId(newMessageType.value);
        const newRecord = records?.find(
          (r) => getCaseId(r, fieldMapping) === newPatientData.value,
        );
        if (
          getCaseId(record, fieldMapping) !==
          getCaseId(newRecord!, fieldMapping)
        ) {
          setRecord(newRecord);
        }
      }}
    />
  );
};

export default CampaignMessagePreview;
