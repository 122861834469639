import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import useSearchQuery from "api/hooks/useSearchQuery";
import { Interaction } from "api/types/domain";
import InteractionDrawer from "components/Feedback/InteractionDrawer";
import InteractionDrawerCover from "components/Feedback/InteractionDrawer/InteractionDrawerCover/InteractionDrawerCover";
import useAnalytics from "hooks/useAnalytics";
import {
  hideDrawer,
  searchSelector,
  setActiveInteraction,
  showDrawer,
} from "store/slices/search";

import InteractionsTable from "../InteractionsTable";
import "./Search.css";
import SearchInput from "./SearchInput";

export const SearchTopbar = () => {
  const { term } = useSelector(searchSelector);
  const { isPending } = useSearchQuery(term);
  return <SearchInput showLoader={isPending} defaultValue={term} />;
};

const Search = () => {
  const {
    term: searchTerm,
    drawerVisible,
    activeInteraction,
  } = useSelector(searchSelector);
  const { data } = useSearchQuery(searchTerm);
  const dispatch = useDispatch();
  const track = useAnalytics();

  const selectInteraction = (interaction: Interaction) => {
    dispatch(setActiveInteraction(interaction));
    dispatch(showDrawer());
    track("Feedback", "Search", "rowClick");
  };

  return (
    <div className="Search">
      <InteractionDrawerCover visible={drawerVisible} />
      <InteractionsTable
        data={data || []}
        onRowClick={selectInteraction}
        highlighted={activeInteraction}
      />
      {!searchTerm && (
        <p className="Search__message Search__message--instructions">
          <Icon icon="mdi:arrow-up" /> Escribe para buscar por teléfono, RUT o
          nombre de paciente
        </p>
      )}
      {searchTerm && data?.length === 0 && (
        <p className="Search__message Search__message--empty-result">
          <Icon icon="mdi:shimmer" /> No se encontraron resultados para &quot;
          {searchTerm}&quot;
        </p>
      )}
      <div
        className={classNames({
          Search__drawer: true,
          "Search__drawer--hidden": !drawerVisible,
        })}
      >
        {activeInteraction && (
          <InteractionDrawer
            interactionId={activeInteraction.id}
            onCloseClick={() => {
              dispatch(hideDrawer());
              track("Feedback", "Search", "hideDrawer");
            }}
            originComponentName="Search"
          />
        )}
      </div>
    </div>
  );
};

export default Search;
