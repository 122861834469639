import { InlineIcon } from "@iconify/react";
import classNames from "classnames";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import InteractionNoteIcon from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon";
import { Emoji } from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon/emojis";
import useAnalytics from "hooks/useAnalytics";
import useIsLabeler from "hooks/useIsLabeler";
import {
  eliminaReaccion,
  guardaReaccion,
  reaccionesSelector,
} from "store/slices/reacciones";

import SelectorEmoji from "../SelectorEmoji";
import "./FormularioNuevaNota.css";

interface FormularioNuevaNotaProps {
  agregarNota: (emoji: string, texto: string) => void;
}

const FormularioNuevaNota = ({ agregarNota }: FormularioNuevaNotaProps) => {
  const [emoji, setEmoji] = useState<Emoji>("✅");
  const [texto, setTexto] = useState("");
  const [conteo, setConteo] = useState(0);
  const [seleccionandoEmoji, setSeleccionandoEmoji] = useState(false);
  const [editando, setEditando] = useState(false);
  const { reaccionesGuardadas } = useSelector(reaccionesSelector);
  const inputRef = useRef<HTMLInputElement>(null);
  const botonEmojiRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const track = useAnalytics();
  const isLabeler = useIsLabeler();

  const agregar = (e: React.FormEvent | React.MouseEvent) => {
    e.preventDefault();
    track("Feedback", "Chat", "agregarNota", { emoji, texto });
    agregarNota(emoji, texto);
    dispatch(guardaReaccion({ emoji, comentario: texto }));
  };

  return (
    <>
      {!isLabeler && (
        <div className="FormularioNuevaNota">
          {seleccionandoEmoji && (
            <SelectorEmoji
              setEmoji={setEmoji}
              cerrar={() => {
                setSeleccionandoEmoji(false);
                track("Feedback", "Chat", "seleccionarEmojiParaNota", {
                  emoji,
                });
                inputRef.current?.focus();
              }}
              refPadre={botonEmojiRef}
            />
          )}
          <div className="FormularioNuevaNota__emoji_reaccion">
            <button
              className={classNames({
                FormularioNuevaNota__boton_emoji: true,
                "FormularioNuevaNota__boton_emoji--visible": editando,
              })}
              title="Cambiar emoji"
              onClick={() => setSeleccionandoEmoji(!seleccionandoEmoji)}
              ref={botonEmojiRef}
            >
              <InteractionNoteIcon emoji={emoji} />
            </button>
          </div>
          <form onSubmit={agregar} className="FormularioNuevaNota__formulario">
            <input
              className="FormularioNuevaNota__input_nueva_reaccion"
              value={texto}
              onChange={(e) => {
                setTexto(e.target.value);
                setConteo(e.target.value.length);
              }}
              ref={inputRef}
              placeholder="Escribe una nota..."
              onKeyUp={(e) => e.stopPropagation()}
              onFocus={() => {
                setSeleccionandoEmoji(false);
                setEditando(true);
              }}
              onBlur={() => {
                if (!texto) {
                  setEditando(false);
                }
              }}
              maxLength={100}
              type="text"
              name="textoNuevaReaccion"
              id="textoNuevaReaccion"
              autoComplete="on"
            />
            {conteo > 0 && (
              <p
                className="FormularioNuevaNota__conteo"
                style={{
                  color: conteo > 80 ? "var(--color-secundario)" : "inherit",
                }}
              >
                {conteo} / 100
              </p>
            )}
            <button
              onClick={agregar}
              className={classNames({
                FormularioNuevaNota__boton: true,
                "FormularioNuevaNota__boton--visible": editando,
              })}
              title="Agregar nota"
              type="submit"
            >
              <InlineIcon icon="mdi:note-add-outline" /> Agregar nota
            </button>
          </form>
        </div>
      )}
      {reaccionesGuardadas?.length > 0 && (
        <div
          className={classNames({
            FormularioNuevaNota__contenedor_sugerencias: true,
            "FormularioNuevaNota__contenedor_sugerencias--visible": editando,
          })}
        >
          Sugerencias:
          {reaccionesGuardadas
            .slice(0, 5)
            .map(({ emoji, comentario: texto }, i) => (
              <span
                className="FormularioNuevaNota__boton_sugerencia"
                onClick={() => {
                  setEmoji(emoji as Emoji);
                  track("Feedback", "Chat", "seleccionarSugerenciaParaNota", {
                    i,
                    emoji,
                    texto,
                  });
                  setTexto(texto);
                  inputRef.current?.focus();
                }}
                title={texto}
                key={`fila-nota-${i}`}
              >
                <span>
                  <InteractionNoteIcon emoji={emoji as Emoji} />
                </span>
                <span className="FormularioNuevaNota__boton_sugerencia_nota">
                  {texto}
                </span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    track("Feedback", "Chat", "eliminarSugerenciaParaNota", {
                      i,
                      emoji,
                      texto,
                    });
                    dispatch(eliminaReaccion(texto));
                  }}
                  className="FormularioNuevaNota__boton_eliminar_sugerencia"
                  title="Eliminar sugerencia"
                >
                  <InlineIcon icon="mdi:close" />
                </button>
              </span>
            ))}
        </div>
      )}
    </>
  );
};

export default FormularioNuevaNota;
