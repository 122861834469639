import { configureStore } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";

import rootReducer from "./slices";

// los que se guardan en el localStorage
const states = [
  "opciones",
  "login",
  "encuestas",
  "cero",
  "reacciones",
  "novedades",
  "alerts",
];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(save({ states })),
  preloadedState: load({ states }),
});

export type AppStore = typeof store;

export default store;
