import axios, { AxiosRequestConfig } from "axios";
import { parse, parseISO, startOfDay } from "date-fns";
import { es } from "date-fns/locale";

import store from "store";

import { AppointmentStatus } from "../types/domain";
import {
  ChatAPIConversation,
  SearchAPIMultiAppointment,
  SearchAPISingleAppointment,
} from "../types/responses";

export const API_ROOT = process.env.REACT_APP_LEGACY_API_ROOT;
export const API_ROOT_NEW = process.env.REACT_APP_LEGACY_API_ROOT?.replace(
  "/v3/old",
  "/v3",
);

export const get = async <T>(url: string, config?: AxiosRequestConfig) => {
  const { login } = store.getState();
  const { token } = login;
  return axios.get<T>(url, {
    ...config,
    headers: { authorization: `Bearer ${token}` },
  });
};

export const post = async <T>(url: string, params: unknown) => {
  const { login } = store.getState();
  const { token } = login;
  return axios.post<T>(url, params, {
    headers: { authorization: `Bearer ${token}` },
  });
};

export const patch = async <T>(url: string, params: unknown) => {
  const { login } = store.getState();
  const { token } = login;
  return axios.patch<T>(url, params, {
    headers: { authorization: `Bearer ${token}` },
  });
};

export const parseAPIDate = (
  appointmentDate: string,
  appointmentTime: string,
  referenceISODate: string,
): Date => {
  if (!appointmentDate) {
    return startOfDay(parseISO(referenceISODate));
  }
  if (!appointmentTime) {
    return parse(
      appointmentDate,
      "d 'de' MMMM",
      startOfDay(parseISO(referenceISODate)),
      { locale: es },
    );
  }
  return parse(
    `${appointmentDate} ${appointmentTime}`,
    appointmentTime.includes("M") ? "d 'de' MMMM h:m a" : "d 'de' MMMM H:m",
    parseISO(referenceISODate),
    { locale: es },
  );
};

export const getStatusFromChatConversation = (
  _conversation: ChatAPIConversation,
): AppointmentStatus => {
  return "OTHER";
};

export const getStatusFromSearchRow = (
  _appointment: SearchAPISingleAppointment | SearchAPIMultiAppointment,
): AppointmentStatus => {
  return "OTHER";
};
