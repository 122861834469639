import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import { encuestasSelector } from "store/slices/encuestas";
import { guardaRespuestas, respuestasSelector } from "store/slices/respuestas";

import { respuestas } from "../endpoints";
import { AnswersAPIResponse } from "../types/responses";

const useAnswersQuery = () => {
  const dispatch = useDispatch();
  const { idEncuestaSeleccionada: serviceId } = useSelector(encuestasSelector);
  const { fechaInicio: startDate, fechaTermino: endDate } =
    useSelector(respuestasSelector);
  return useQuery<AnswersAPIResponse, unknown>({
    enabled: serviceId !== undefined,
    queryKey: ["answers", serviceId, startDate, endDate],
    queryFn: async ({ signal }) => {
      const { data } = await respuestas(serviceId!, startDate, endDate, signal);
      dispatch(
        guardaRespuestas({
          jsonRespuestas: data,
          idEncuesta: serviceId!,
        }),
      );
      return data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useAnswersQuery;
