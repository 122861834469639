export const videos = {
  respuestas: {
    titulo: "Vista inicial de respuestas",
    duracion: "1:11",
    link: "https://player.vimeo.com/video/714999233?autoplay=1",
    thumbnail: "https://vumbnail.com/714999233_small.jpg",
  },
  chats: {
    titulo: "Vista detallada de chats",
    duracion: "0:53",
    link: "https://player.vimeo.com/video/715018607?autoplay=1",
    thumbnail: "https://vumbnail.com/715018607_small.jpg",
  },
  "filtros-simples": {
    titulo: "Filtros simples",
    duracion: "0:41",
    link: "https://player.vimeo.com/video/707563556?autoplay=1",
    thumbnail: "https://vumbnail.com/707563556_small.jpg",
  },
  "filtros-especificos": {
    titulo: "Filtros específicos",
    duracion: "1:12",
    link: "https://player.vimeo.com/video/710118003?autoplay=1",
    thumbnail: "https://vumbnail.com/710118003_small.jpg",
  },
  "confirmaciones-por-fecha": {
    titulo: "Confirmaciones por fecha",
    duracion: "0:49",
    link: "https://player.vimeo.com/video/707555525?autoplay=1",
    thumbnail: "https://vumbnail.com/707555525_small.jpg",
  },
  "reportar-problemas": {
    titulo: "Reportar un problema",
    duracion: "1:00",
    link: "https://player.vimeo.com/video/707572633?autoplay=1",
    thumbnail: "https://vumbnail.com/707572633_small.jpg",
  },
  comentarios: {
    titulo: "Agregar notas a un chat",
    duracion: "1:03",
    link: "https://player.vimeo.com/video/707569196?autoplay=1",
    thumbnail: "https://vumbnail.com/707569196_small.jpg",
  },
  audios: {
    titulo: "Audios",
    duracion: "0:44",
    link: "https://player.vimeo.com/video/707567810?autoplay=1",
    thumbnail: "https://vumbnail.com/707567810_small.jpg",
  },
  alertas: {
    titulo: "Alertas",
    duracion: "1:05",
    link: "https://player.vimeo.com/video/710113809?autoplay=1",
    thumbnail: "https://vumbnail.com/710113809_small.jpg",
  },
  "opciones-alertas": {
    titulo: "Opciones de alertas",
    duracion: "1:03",
    link: "https://player.vimeo.com/video/710116476?autoplay=1",
    thumbnail: "https://vumbnail.com/710116476_small.jpg",
  },
  busquedas: {
    titulo: "Búsquedas",
    duracion: "0:55",
    link: "https://player.vimeo.com/video/710120273?autoplay=1",
    thumbnail: "https://vumbnail.com/710120273_small.jpg",
  },
};

export const isVideoId = (id: unknown): id is keyof typeof videos => {
  return Object.keys(videos).includes(id as string);
};
