import classNames from "classnames";
import type { RefAttributes } from "react";
import ReactSelect, { type GroupBase } from "react-select";
import type SelectDeclaration from "react-select/dist/declarations/src/Select";
import type { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

import "./Select.css";

export type SelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  innerRef?: React.LegacyRef<SelectDeclaration<Option, IsMulti, Group>>;
} & Omit<
  StateManagerProps<Option, IsMulti, Group> &
    RefAttributes<SelectDeclaration<Option, IsMulti, Group>>,
  "classNamePrefix" | "noOptionsMessage" | "unstyled"
>;

const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  className,
  innerRef,
  ...props
}: SelectProps<Option, IsMulti, Group>) => {
  return (
    <ReactSelect
      unstyled
      noOptionsMessage={({ inputValue }) =>
        `Sin resultados para "${inputValue}"`
      }
      className={classNames("Select__container", className)}
      classNamePrefix="Select"
      {...props}
      ref={innerRef}
    />
  );
};

export default Select;
