import { Icon } from "@iconify/react";

import "./Badge.css";

interface BadgeProps {
  text: string;
  icon?: string;
}

const Badge = ({ text, icon }: BadgeProps) => {
  return (
    <div className="Badge">
      {icon && <Icon className="Badge__icon" icon={icon} />}
      <div className="Badge__text">{text}</div>
    </div>
  );
};

export default Badge;
