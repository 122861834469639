import { useQuery } from "@tanstack/react-query";

import { PatientId, ServiceId } from "../types/domain";
import {
  InteractionHistoryAPIResponse,
  InteractionHistoryRecord,
} from "../types/responses";
import { API_ROOT_NEW, get } from "./utils";

const useInteractionsHistoryQuery = ({
  serviceId,
  patientId,
}: {
  serviceId: ServiceId;
  patientId: PatientId;
}) => {
  const url = `${API_ROOT_NEW}/interactions/${serviceId}/${patientId}`;

  return useQuery<InteractionHistoryRecord[], unknown>({
    queryKey: ["interactions-history", serviceId, patientId],
    queryFn: async ({ signal }) => {
      const { data } = await get<InteractionHistoryAPIResponse>(url, {
        signal,
      });
      if (!data) {
        return [];
      }
      return data.interactions;
    },
  });
};

export default useInteractionsHistoryQuery;
