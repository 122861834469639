import { captureException } from "@sentry/react";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import useAnswerHeadersQuery from "api/hooks/useAnswerHeadersQuery";
import Loader from "components/Loader";
import { obtenerTiposEncuestasVisibles } from "helpers/encuestasSecretas";
import { obtenerPollsCalculadas } from "helpers/pollsCalculadas";
import {
  encuestasSelector,
  guardaIdEncuestaSeleccionada,
} from "store/slices/encuestas";
import { loginSelector } from "store/slices/login";
import { guardaIdEncuesta, opcionesSelector } from "store/slices/opciones";
import {
  agregaFiltro,
  limpiaFiltros,
  limpiaRespuestas,
  respuestasSelector,
} from "store/slices/respuestas";

import {
  POLL_DROPDOWN_CLIENTS,
  POLL_TABS_CLIENTS,
} from "../../../../constants";
import DropdownEncuesta from "./DropdownEncuesta";
import TabsEncuestas from "./TabsEncuestas";

const ServiceSelector = () => {
  const navigate = useNavigate();
  const { serviceId: pathServiceId } = useParams<{ serviceId?: string }>();
  const { idEncuestaSeleccionada, tipos } = useSelector(encuestasSelector);
  const { idEncuestaGuardada } = useSelector(opcionesSelector);

  const dispatch = useDispatch();

  const { isFetching } = useAnswerHeadersQuery();

  const { cuenta } = useSelector(loginSelector);
  const { respuestas } = useSelector(respuestasSelector);

  const tiposOrdenados = useMemo(() => {
    if (!tipos) {
      return [];
    }
    const encuestaSeleccionada = tipos.find(
      ({ id }) => id === idEncuestaSeleccionada,
    );
    if (!encuestaSeleccionada) {
      return obtenerTiposEncuestasVisibles(cuenta, tipos);
    }
    const encuestasCalculadas = obtenerPollsCalculadas(
      encuestaSeleccionada,
      respuestas,
    );
    if (encuestasCalculadas.length === 0) {
      return obtenerTiposEncuestasVisibles(cuenta, tipos);
    }
    const tiposEncuestas = [
      encuestaSeleccionada,
      ...encuestasCalculadas,
      ...tipos.filter(({ id }) => id !== idEncuestaSeleccionada),
    ];
    return obtenerTiposEncuestasVisibles(cuenta, tiposEncuestas);
  }, [tipos, idEncuestaSeleccionada, respuestas, cuenta]);

  const verEncuesta = useCallback(
    async (id: number | string) => {
      navigate("/respuestas");
      if (typeof id === "string") {
        const [, header, texto, idEncuesta, titulo] = id.split("|");
        dispatch(
          agregaFiltro({
            busqueda: texto,
            nombreHeader: header,
            textoHeader: header,
            idEncuesta: Number(idEncuesta),
            opciones: {
              filtroImplicito: true,
              titulo,
            },
          }),
        );
        return;
      }
      if (id === idEncuestaSeleccionada) {
        dispatch(limpiaFiltros());
        return;
      }
      try {
        dispatch(limpiaRespuestas());
        dispatch(guardaIdEncuesta(id));
        dispatch(limpiaFiltros());
        dispatch(guardaIdEncuestaSeleccionada(id));
      } catch (e) {
        captureException(e);
      }
    },
    [idEncuestaSeleccionada, dispatch, navigate],
  );

  useEffect(() => {
    if (idEncuestaSeleccionada || !tipos) {
      return;
    }
    const tiposEncuestas = obtenerTiposEncuestasVisibles(cuenta, tipos);
    const idEncuestas = tiposEncuestas.map((t) => t.id);
    if (idEncuestas.includes(Number(pathServiceId))) {
      verEncuesta(Number(pathServiceId));
    } else if (idEncuestaGuardada && idEncuestas.includes(idEncuestaGuardada)) {
      verEncuesta(idEncuestaGuardada);
    } else {
      const id = idEncuestas[0];
      if (id) {
        verEncuesta(id);
      }
    }
  }, [
    idEncuestaSeleccionada,
    idEncuestaGuardada,
    pathServiceId,
    tipos,
    cuenta,
    dispatch,
    verEncuesta,
  ]);

  const useTabs =
    POLL_TABS_CLIENTS.some((client) => cuenta?.includes(client)) ||
    (!POLL_DROPDOWN_CLIENTS.some((client) => cuenta?.includes(client)) &&
      tiposOrdenados?.length <= 5);

  if (!tipos || !idEncuestaSeleccionada) {
    return <Loader color="#6057f6" />;
  }

  const encuestaSeleccionada = tipos.find(
    (t) => t.id === idEncuestaSeleccionada,
  );

  if (!encuestaSeleccionada) {
    if (!isFetching) {
      verEncuesta(tipos[0].id);
    }
    return <Loader color="#6057f6" />;
  }

  return useTabs ? (
    <TabsEncuestas tiposOrdenados={tiposOrdenados} verEncuesta={verEncuesta} />
  ) : (
    <DropdownEncuesta
      encuestaSeleccionada={encuestaSeleccionada}
      verEncuesta={verEncuesta}
    />
  );
};

export default ServiceSelector;
