import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import InteractionNoteIcon from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon";
import { Emoji } from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon/emojis";

import "./SelectorEmoji.css";

const categoriasEmojis = [
  {
    nombre: "Reacciones",
    emojis: ["😊", "🙄", "😴", "👍", "👎"],
  },
  {
    nombre: "Símbolos",
    emojis: ["✅", "❌", "🔁", "⏳", "👀"],
  },
  {
    nombre: "Colores",
    emojis: ["🔴", "🟠", "🟡", "🟢", "🔵", "🟣", "🟤", "⚫", "⚪"],
  },
] as const;

interface SelectorEmojiProps {
  setEmoji: (emoji: Emoji) => void;
  cerrar: () => void;
  refPadre: React.MutableRefObject<HTMLButtonElement | null>;
}

const SelectorEmoji = ({ setEmoji, cerrar, refPadre }: SelectorEmojiProps) => {
  const refContenedor = useRef<HTMLDivElement>(null);
  const [pos, setPos] = useState({ left: 0, top: 0, opacity: 0 });

  const clickEnEmoji = (emoji: Emoji) => {
    setEmoji(emoji);
    cerrar();
  };

  useEffect(() => {
    const containerRect = refContenedor.current?.getBoundingClientRect();
    const parentRect = refPadre.current?.getBoundingClientRect();

    if (!containerRect || !parentRect) {
      setPos({ left: 0, top: 0, opacity: 0 });
      return;
    }
    setPos({
      left: parentRect.left - containerRect.width / 2 + parentRect.width / 2,
      top: parentRect.top - containerRect.height,
      opacity: 1,
    });
  }, [refPadre]);

  const portalContainer = document.getElementById("selector-emojis");

  if (!portalContainer) {
    return null;
  }

  return createPortal(
    <div className="SelectorEmoji" style={pos} ref={refContenedor}>
      <div className="SelectorEmoji__contenedor">
        {categoriasEmojis.map(({ nombre, emojis }) => (
          <div
            className="SelectorEmoji__contenedor_categoria"
            key={`contenedor-emojis-${nombre}`}
          >
            <h6 className="SelectorEmoji__titulo_categoria_emojis">{nombre}</h6>
            <div className="SelectorEmoji__contenedor_botones">
              {emojis.map((emoji) => (
                <button
                  key={`boton-${emoji}`}
                  onClick={() => clickEnEmoji(emoji)}
                  className="SelectorEmoji__boton_emoji"
                >
                  <InteractionNoteIcon emoji={emoji} />
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>,
    portalContainer,
  );
};

export default SelectorEmoji;
