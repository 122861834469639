import { Icon } from "@iconify/react";
import classNames from "classnames";
import { ReactNode, forwardRef, useMemo, useState } from "react";
import { SelectInstance } from "react-select";

import Select from "components/atoms/Select";
import Collapsable from "components/molecules/Collapsable";
import { PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum } from "feedback-api/generated";

import "./FieldMapper.css";

type FieldState = "unassigned" | "manual" | "auto";

interface FieldMapperProps {
  name: string;
  maxItems?: number;
  separator?: string;
  dataSample: Record<string, unknown[]>;
  value: string[];
  onChange: (columns: string[]) => void;
  type: PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum;
  isDirty: boolean;
  required: boolean;
  error: string | undefined;
}

const FieldMapper = forwardRef<
  SelectInstance<{ label: string; value: string }>,
  FieldMapperProps
>(function FieldMapper(
  {
    name,
    maxItems,
    separator = " ",
    dataSample,
    value,
    onChange,
    type,
    isDirty,
    required,
    error,
  },
  ref,
) {
  const selectedColumns = useMemo(() => {
    return value.filter((x) => x);
  }, [value]);

  const fieldState: FieldState = useMemo(() => {
    if (!selectedColumns.length) {
      return "unassigned";
    } else if (!isDirty) {
      return "auto";
    } else {
      return "manual";
    }
  }, [isDirty, selectedColumns]);

  const [isOpen, setOpen] = useState(fieldState !== "auto");

  const {
    label: stateLabel,
    icon: stateIcon,
    modifier: stateModifier,
  } = getStateData(fieldState);

  const options = Object.keys(dataSample).map((key) => ({
    label: key,
    value: key,
  }));

  return (
    <div className="FieldMapper">
      <Collapsable
        isOpen={isOpen}
        setOpen={setOpen}
        isError={!!error}
        title={
          <>
            <span className="FieldMapper__title">
              {name}
              {required && (
                <span className="FieldMapper__required_marker">*</span>
              )}
            </span>
            {(
              [
                PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum.List,
                PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum.Concat,
              ] as string[]
            ).includes(type) && (
              <span className="FieldMapper__counter">
                {getAssignmentsCountLabel(selectedColumns.length)}
              </span>
            )}
          </>
        }
        rhs={
          <div
            className={classNames("FieldMapper__state", {
              "FieldMapper__state--success": stateModifier === "success",
            })}
          >
            <Icon className="FieldMapper__state_icon" icon={stateIcon} />
            <span className="FieldMapper__state_label">{stateLabel}</span>
          </div>
        }
      >
        <div className="FieldMapper__body">
          {value.map((column, i) => (
            <div key={i} className="FieldMapper__column_section">
              <Select
                placeholder="Elige la columna que deseas asignar a este campo"
                menuPortalTarget={document.getElementById("columns-selector")}
                value={column ? { label: column, value: column } : undefined}
                isOptionSelected={(option) => value.includes(option.value)}
                onFocus={() => setOpen(true)}
                onChange={(newValue) =>
                  onChange(value.toSpliced(i, 1, newValue?.value ?? ""))
                }
                tabIndex={isOpen ? 0 : -1}
                options={options}
                innerRef={i === 0 ? ref : undefined}
                required
              />
              {i > 0 && (
                <button
                  className="FieldMapper__remove_column"
                  tabIndex={isOpen ? 0 : -1}
                  type="button"
                  onClick={() => onChange(value.toSpliced(i, 1))}
                >
                  <Icon
                    className="FieldMapper__add_column_icon"
                    icon="uil:trash-alt"
                  />
                </button>
              )}
            </div>
          ))}
          {(
            [
              PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum.List,
              PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum.Concat,
            ] as string[]
          ).includes(type) &&
            (!maxItems || value.length < maxItems) && (
              <button
                className="FieldMapper__add_column"
                type="button"
                tabIndex={isOpen ? 0 : -1}
                onClick={() => onChange([...value, ""])}
              >
                <Icon
                  className="FieldMapper__add_column_icon"
                  icon="uil:book-medical"
                />
                Agregar columna
              </button>
            )}
          {selectedColumns.length > 0 && (
            <div className="FieldMapper__preview">
              <div className="FieldMapper__preview__title">
                Vista previa del contenido de{" "}
                {selectedColumns.length === 1 ? "la columna" : "las columnas"}
              </div>
              <div
                className="FieldMapper__preview__grid"
                style={
                  type !==
                  PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum.Concat
                    ? {
                        gridTemplateColumns: `repeat(${selectedColumns.length},1fr)`,
                      }
                    : {}
                }
              >
                {type ===
                  PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum.Concat &&
                  dataSample[selectedColumns[0]]?.map((_, i) => (
                    <div key={String(i)} className="FieldMapper__preview__cell">
                      {selectedColumns
                        .map((column) => dataSample[column][i])
                        .join(separator)}
                    </div>
                  ))}
                {type !==
                  PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum.Concat &&
                  dataSample[selectedColumns[0]]?.map((_, i) =>
                    selectedColumns.map((column, j) => (
                      <div
                        key={`${i}-${j}`}
                        className="FieldMapper__preview__cell"
                      >
                        {dataSample[column][i] as ReactNode}
                      </div>
                    )),
                  )}
              </div>
            </div>
          )}
        </div>
      </Collapsable>
      {error && (
        <div className="FieldMapper__error_container">
          <Icon
            icon="uil:exclamation-triangle"
            className="FieldMapper__error_icon"
          />
          <span className="FieldMapper__error_message">{error}</span>
        </div>
      )}
    </div>
  );
});

const getStateData = (state: FieldState) => {
  switch (state) {
    case "unassigned":
      return {
        label: "Sin asignar",
        icon: "uil:layers",
        modifier: "neutral",
      };
    case "manual":
      return {
        label: "Asignado manualmente",
        icon: "uil:check-circle",
        modifier: "success",
      };
    case "auto":
      return {
        label: "Asignado automáticamente",
        icon: "uil:check-circle",
        modifier: "success",
      };
  }
};

const getAssignmentsCountLabel = (count: number) => {
  switch (count) {
    case 0:
      return "Sin columnas asignadas";
    case 1:
      return "1 columna asignada";
    default:
      return `${count} columnas asignadas`;
  }
};

export default FieldMapper;
