import { Icon } from "@iconify/react";
import classNames from "classnames";
import { FormEvent, useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";

import useAddNoteMutation from "api/hooks/useAddNoteMutation";
import { InteractionId } from "api/types/domain";
import useAnalytics from "hooks/useAnalytics";
import { guardaReaccion, reaccionesSelector } from "store/slices/reacciones";

import InteractionNoteIcon from "../InteractionNote/InteractionNoteIcon";
import { Emoji, emojiMap } from "../InteractionNote/InteractionNoteIcon/emojis";
import "./NewNotePopup.css";

const emojis = Object.keys(emojiMap).filter(
  (emoji: string) => !emojiMap[emoji as Emoji].disabled,
);

interface NewNotePopupProps {
  interactionId: InteractionId;
  isOpen: boolean;
  close: () => void;
  originComponentName: string;
}

const NewNotePopup = ({
  interactionId,
  isOpen,
  close,
  originComponentName,
}: NewNotePopupProps) => {
  const [selectedEmoji, setSelectedEmoji] = useState(emojis[0]);
  const [text, setText] = useState("");
  const textRef = useRef<HTMLInputElement>(null);
  const { mutate: addNote, isPending } = useAddNoteMutation(interactionId);
  const track = useAnalytics();
  const dispatch = useDispatch();
  const { reaccionesGuardadas } = useSelector(reaccionesSelector);

  useEffect(() => {
    textRef?.current?.focus();
  }, [isOpen]);

  const handleAddNote = (e: FormEvent) => {
    e.preventDefault();
    track("Feedback", originComponentName, "addNote", {
      selectedEmoji,
      text,
    });
    addNote({ emoji: selectedEmoji as Emoji, text });
    dispatch(guardaReaccion({ comentario: text, emoji: selectedEmoji }));
    close();
  };

  return (
    <div
      className={classNames({
        NewNotePopup: true,
        "NewNotePopup--visible": isOpen,
      })}
    >
      <OutsideClickHandler onOutsideClick={() => close()}>
        <form className="NewNotePopup__content" onSubmit={handleAddNote}>
          <button
            className="NewNotePopup__close"
            type="button"
            onClick={() => close()}
          >
            <Icon icon="mdi:close" />
          </button>
          <div className="NewNotePopup__emojis_container">
            {emojis.map((emoji: string, i) => (
              <button
                className={classNames({
                  NewNotePopup__emoji_button: true,
                  "NewNotePopup__emoji_button--selected":
                    emoji === selectedEmoji,
                })}
                type="button"
                onClick={() => {
                  setSelectedEmoji(emoji);
                  textRef?.current?.focus();
                }}
                key={`emoji-${i}`}
              >
                <InteractionNoteIcon emoji={emoji as Emoji} />
              </button>
            ))}
          </div>
          <h3 className="NewNotePopup__label">Escribe tu nota</h3>
          <input
            id="new-note-text"
            autoComplete="on"
            name="new-note-text"
            maxLength={128}
            className="NewNotePopup__input"
            ref={textRef}
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
          <button
            type="submit"
            className="InteractionNotes__add_note_button"
            disabled={isPending}
          >
            <Icon icon="mdi:note-plus-outline" />
            Agregar nota
          </button>
          <div className="NewNotePopup__quick_notes_container">
            <h3 className="NewNotePopup__label NewNotePopup__label--quick_notes_title">
              <Icon icon="mdi:lightbulb-on" /> Sugerencias
            </h3>
            {reaccionesGuardadas
              .slice(0, 5)
              .map(({ comentario: text, emoji }, i) => (
                <button
                  key={`note-suggestion-${i}`}
                  type="button"
                  className="NewNotePopup__quick_note_button"
                  onClick={() => {
                    setText(text);
                    setSelectedEmoji(emoji);
                    track("Feedback", originComponentName, "quickNoteClick", {
                      selectedEmoji: emoji,
                      text,
                    });
                  }}
                >
                  <p className="NewNotePopup__quick_note_button_emoji">
                    {emoji}
                  </p>
                  <p>{text}</p>
                </button>
              ))}
          </div>
        </form>
      </OutsideClickHandler>
    </div>
  );
};

export default NewNotePopup;
