import classNames from "classnames";

import "./Pill.css";

interface PillProps {
  variant: "green" | "red" | "blue" | "gray";
  children: React.ReactNode;
}

const Pill = ({ variant, children }: PillProps) => {
  return (
    <div className={classNames("Pill", `Pill--${variant}`)}>{children}</div>
  );
};

export default Pill;
