import { InlineIcon } from "@iconify/react";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";

import "./Rangos.css";

interface RangosProps {
  rangos: {
    etiqueta: string;
    onClick: () => void;
  }[];
  ocultar: () => void;
}

const Rangos = ({ rangos, ocultar }: RangosProps) => {
  const navigate = useNavigate();

  return (
    <OutsideClickHandler onOutsideClick={ocultar}>
      <div className="Rangos">
        {rangos.map((rango, i) => (
          <button
            className="Rangos__opcion"
            key={`rango-${i}`}
            onClick={rango.onClick}
          >
            {rango.etiqueta}
          </button>
        ))}
        <button
          className="Rangos__opcion"
          onClick={() => navigate("/exportar")}
        >
          Otro rango (Reporte)
          <InlineIcon icon="mdi:table-export" />
        </button>
        <button
          className="Rangos__opcion"
          onClick={() => navigate("/busqueda")}
        >
          Búsqueda general
          <InlineIcon icon="mdi:search" />
        </button>
      </div>
    </OutsideClickHandler>
  );
};

export default Rangos;
