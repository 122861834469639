import { InlineIcon } from "@iconify/react";
import classNames from "classnames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalAyuda from "components/ModalAyuda";
import useAnalytics from "hooks/useAnalytics";
import {
  remueveFiltro,
  combinaFiltros,
  respuestasSelector,
} from "store/slices/respuestas";

import "./Filtros.css";

const Filtros = () => {
  const { filtros } = useSelector(respuestasSelector);
  const [indiceFiltroInicioDrag, setIndiceFiltroInicioDrag] =
    useState<number>();
  const [mostrarAyuda, setMostrarAyuda] = useState(false);
  const dispatch = useDispatch();
  const filtrosVisibles = filtros.filter((f) => !f.oculto);
  const track = useAnalytics();

  return (
    <div className="Filtros">
      <div className="Filtros__titulo">
        <p className="Filtros__mensaje">
          <InlineIcon icon="mdi:filter" />{" "}
          {filtrosVisibles.length > 0
            ? "Filtros activos:"
            : "No hay filtros activos"}
        </p>
      </div>
      {filtros.map((f, i) => (
        <div
          key={`tag-filtro-${i}`}
          draggable={true}
          onDragStart={() => setIndiceFiltroInicioDrag(i)}
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={(e) => e.preventDefault()}
          onDrop={() => {
            track("Feedback", "Respuestas", "combinarFiltros", { filtro: f });
            dispatch(combinaFiltros([indiceFiltroInicioDrag!, i]));
          }}
          title="Arrastra un filtro sobre otro para combinarlos"
          className={classNames({
            Filtros__tag_filtro: true,
            "Filtros__tag_filtro--global": f.headers === "*",
            "Filtros__tag_filtro--multiple":
              f.headers !== "*" && f.nombresHeaders?.length > 1,
            "Filtros__tag_filtro--oculto": f.oculto,
          })}
        >
          {f.headers === "*"
            ? f.descripcion
            : f.busqueda.map((b, i) => {
                return (
                  <span
                    key={`tag-filtro-${i}`}
                    className="Filtros__elemento_tag_filtro"
                  >
                    <span style={{ fontWeight: "600" }}>
                      &quot;{b.startsWith("TAG") ? b.slice(3, -3) : b}&quot;
                    </span>{" "}
                    <span>en</span>{" "}
                    <span
                      style={{ fontWeight: "600", textTransform: "uppercase" }}
                    >
                      {f.nombresHeaders[i]}
                    </span>
                  </span>
                );
              })}
          <button
            className="Filtros__boton_remover_filtro"
            title="Remover este filtro"
            onClick={() => {
              track("Feedback", "Respuestas", "removerFiltros", { filtro: f });
              dispatch(remueveFiltro(i));
            }}
          >
            <InlineIcon icon="mdi:close" />
          </button>
        </div>
      ))}
      <button className="Filtros__boton_ayuda">
        <InlineIcon
          icon="mdi:help-circle"
          onClick={() => {
            track("Feedback", "Respuestas", "mostrarAyudaFiltros");
            setMostrarAyuda(!mostrarAyuda);
          }}
        />
      </button>
      {mostrarAyuda && <ModalAyuda cerrar={() => setMostrarAyuda(false)} />}
    </div>
  );
};

export default Filtros;
