import { format } from "date-fns";

import { Note } from "api/types/domain";
import InteractionNoteIcon from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon";
import { Emoji } from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon/emojis";

import "./SmartphoneNote.css";

interface SmartphoneNoteProps {
  note: Note;
}

const SmartphoneNote = ({ note }: SmartphoneNoteProps) => {
  return (
    <div className="SmartphoneNote" title="Nota (visible solo en Feedback)">
      <div className="SmartphoneNote__icon_container">
        <InteractionNoteIcon emoji={note.emoji as Emoji} />
      </div>
      <p className="SmartphoneNote__time">{format(note.timestamp, "HH:mm")}</p>
      <p className="SmartphoneNote__text">{note.text}</p>
    </div>
  );
};

export default SmartphoneNote;
