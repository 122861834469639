import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useEffect, useState } from "react";

import "./Switch.css";

interface SwitchProps {
  id?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  onFocus?: () => void;
}

const Switch = ({
  id,
  value = false,
  disabled = false,
  onChange,
  onFocus,
}: SwitchProps) => {
  const [isOn, setOn] = useState(value);

  useEffect(() => {
    setOn(value);
  }, [value]);

  const handleOnClick = () => {
    setOn(!isOn);
    onChange?.(!isOn);
  };

  return (
    <button
      id={id}
      type="button"
      role="switch"
      aria-checked={isOn}
      className={classNames("Switch", { "Switch--on": isOn })}
      onClick={handleOnClick}
      onFocus={onFocus}
      disabled={disabled}
    >
      <Icon className="Switch__start_icon" icon="uil:check" />
      <div className="Switch__thumb" />
    </button>
  );
};

export default Switch;
