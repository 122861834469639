import { useMutation } from "@tanstack/react-query";

import { ErrorCode, suspensionsApi } from "feedback-api";
import type {
  CreateSuspensionRequest,
  CreateSuspensionResponse,
  ErrorResponse,
} from "feedback-api";

const useCreateSuspensionMutation = () => {
  return useMutation<
    CreateSuspensionResponse,
    ErrorResponse,
    CreateSuspensionRequest
  >({
    mutationFn: async (payload) => {
      try {
        const { data } =
          await suspensionsApi.createSuspensionV4SuspensionsPost(payload);
        return data;
      } catch (e: unknown) {
        if (
          typeof e === "object" &&
          !!e &&
          Object.keys(e).includes("error_code")
        ) {
          throw e as ErrorResponse;
        }
        throw { error_code: ErrorCode.GenericError } as ErrorResponse;
      }
    },
  });
};

export default useCreateSuspensionMutation;
