import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { ToastData, removeToast } from "store/slices/toasts";

import "./Toast.css";

interface CloseButtonWithTimerProps {
  countdown?: number;
  onClick: () => void;
}

const CloseButtonWithTimer = ({
  countdown,
  onClick,
}: CloseButtonWithTimerProps) => {
  const [inProp, setInProp] = useState(true);

  useEffect(() => {
    if (countdown) {
      setInProp(false);
      const timeout = setTimeout(onClick, countdown);
      return () => clearTimeout(timeout);
    }
  }, [countdown, onClick]);

  return (
    <button className="CloseButton" onClick={onClick}>
      <Icon icon="mdi:close" />
      {countdown && (
        <CSSTransition
          in={inProp}
          timeout={countdown}
          classNames="CloseButton__countdown"
        >
          <svg
            style={{ transitionDuration: `${countdown}ms` }}
            className="CloseButton__countdown"
            viewBox="0 0 1 1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle className="CloseButton__circle" />
          </svg>
        </CSSTransition>
      )}
    </button>
  );
};

interface ToastProps {
  toast: ToastData;
}

const Toast = ({ toast }: ToastProps) => {
  const [inProp, setInProp] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => setInProp(true), []);

  return (
    <CSSTransition
      in={inProp}
      timeout={200}
      classNames="Toast"
      unmountOnExit
      onExited={() => dispatch(removeToast(toast.id))}
    >
      <div
        className={classNames("Toast", {
          "Toast--success": toast.type === "success",
          "Toast--error": toast.type === "error",
        })}
      >
        <div>{toast.message}</div>
        <CloseButtonWithTimer
          countdown={toast.durationMs}
          onClick={() => setInProp(false)}
        />
      </div>
    </CSSTransition>
  );
};

export default Toast;
