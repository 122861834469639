import { format } from "date-fns";
import * as _ from "lodash-es";
import { writeFile, utils } from "xlsx-js-style";

import { AnswerHeader, Respuesta } from "api/types/domain";

import { extraerTextoHeader, formatearCampoRespuestas } from "./respuestas";
import { obtenerHeadersConTagsCalculados } from "./tagsCalculados";

const extraerUltimaNota = (respuesta: Respuesta) => {
  const notas = _.cloneDeep(respuesta.reactions);
  notas.sort((r1, r2) => (r1.created_at > r2.created_at ? -1 : 1));
  if (notas[0]) {
    return `${notas[0]?.reaction_emoji} ${notas[0]?.reaction_text}`;
  }
  return "";
};

export const exportarTablaRespuestas = async (
  headers: AnswerHeader[],
  respuestas: Respuesta[],
  nombre: string,
  fechaInicio: number | Date,
  fechaTermino: number | Date,
  pollId: number,
  formato: "csv" | "xlsx" = "csv",
) => {
  const nombreArchivo = `Feedback_${nombre}_${format(
    fechaInicio,
    "dd-MM-yyyy",
  )}_${format(fechaTermino, "dd-MM-yyyy")}.${formato}`;
  const headersAIncluir = headers.filter((h) =>
    ["META", "YESNO", "RANGE", "OPEN", "ICON"].includes(h.tipo),
  );
  if (formato === "xlsx") {
    let headersXLSX;
    let filasXLSX;
    if (respuestas[0]?.phone) {
      headersXLSX = [
        "Última Nota",
        "Teléfono",
        ...headersAIncluir.map((h) => h.texto),
        "Link",
      ];
      filasXLSX = respuestas.map((r) => [
        extraerUltimaNota(r),
        formatearCampoRespuestas(r.phone, "phone"),
        ...headersAIncluir.map((h) => extraerTextoHeader(h, r)),
        `https://feedback.cero.ai/respuestas/${pollId}/${r.user_id}`,
      ]);
    } else {
      headersXLSX = [
        "Última Nota",
        ...headersAIncluir.map((h) => h.texto),
        "Link",
      ];
      filasXLSX = respuestas.map((r) => [
        extraerUltimaNota(r),
        ...headersAIncluir.map((h) => extraerTextoHeader(h, r)),
        `https://feedback.cero.ai/respuestas/${pollId}/${r.user_id}`,
      ]);
    }
    const data = [headersXLSX, ...filasXLSX];
    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(data);
    headersXLSX.forEach((_, i) => {
      const cell = worksheet[utils.encode_cell({ r: 0, c: i })];
      cell.s = { font: { bold: true } };
    });
    filasXLSX.forEach((fila, i) => {
      const cell =
        worksheet[utils.encode_cell({ r: i + 1, c: headersXLSX.length - 1 })];
      cell.l = {
        Target: fila.slice(-1)[0],
        Tooltip: "Abrir Chat en Feedback",
      };
      cell.s = { font: { color: { rgb: "2017d9" }, underline: true } };
    });
    const columnMaxLength: number[] = [];
    data.forEach((row) => {
      row.forEach((value, columnIndex) => {
        if (value === null) {
          columnMaxLength[columnIndex] = 0;
          return;
        }
        if (typeof value === "number") {
          columnMaxLength[columnIndex] = 10;
          return;
        }
        columnMaxLength[columnIndex] =
          columnMaxLength[columnIndex] >= value.length
            ? columnMaxLength[columnIndex]
            : value.length;
      });
    });
    worksheet["!cols"] = columnMaxLength.map((width) => ({ width }));
    utils.book_append_sheet(workbook, worksheet, "Exportación Feedback");
    writeFile(workbook, nombreArchivo);
  } else {
    let headersCSV;
    let respuestasCSV;
    if (respuestas[0]?.phone) {
      headersCSV = [
        "Teléfono",
        ...headersAIncluir.map((h) => h.texto),
        "Link",
      ].join(";");
      respuestasCSV = respuestas
        .map((r) =>
          [
            formatearCampoRespuestas(r.phone, "phone"),
            ...headersAIncluir.map((h) => extraerTextoHeader(h, r)),
            `https://feedback.cero.ai/respuestas/${pollId}/${r.user_id}`,
          ].join(";"),
        )
        .join("\n");
    } else {
      headersCSV = [...headersAIncluir.map((h) => h.texto), "Link"].join(";");
      respuestasCSV = respuestas
        .map((r) =>
          [
            ...headersAIncluir.map((h) => extraerTextoHeader(h, r)),
            `https://feedback.cero.ai/respuestas/${pollId}/${r.user_id}`,
          ].join(";"),
        )
        .join("\n");
    }
    const texto = `${headersCSV}\n${respuestasCSV}`;
    const elemento = document.createElement("a");
    elemento.setAttribute(
      "href",
      `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(texto)}`,
    );
    elemento.setAttribute("download", nombreArchivo);
    elemento.style.display = "none";
    document.body.appendChild(elemento);
    elemento.click();
    document.body.removeChild(elemento);
  }
};

export const obtenerHeaders = (
  headers: AnswerHeader[] | undefined,
  idEncuesta: number,
) => {
  if (!headers) {
    return null;
  }
  return (
    obtenerHeadersConTagsCalculados(headers, idEncuesta) || [
      ...headers.filter((h) => h.tipo === "YESNO"),
      ...headers.filter((h) => h.tipo === "OPEN"),
      ...headers.filter((h) => h.tipo === "RANGE"),
      ...headers.filter((h) => !["YESNO", "RANGE", "OPEN"].includes(h.tipo)),
    ]
  );
};
