import { useQuery } from "@tanstack/react-query";

import { campaignsApi, MessagePreview } from "feedback-api";

const useCampaignMessagePreviews = (
  record: Record<string, string> | undefined,
  mapping: Record<string, string[]> | undefined,
  metas?: Record<string, string | boolean | undefined> | undefined,
) => {
  return useQuery<MessagePreview[], unknown>({
    enabled: record !== undefined && mapping !== undefined,
    queryKey: ["campaignPreview"],
    queryFn: async ({ signal }) => {
      const {
        data: { previews },
      } = await campaignsApi.previewMessagesV4CampaignsPreviewsPost(
        {
          record: record!,
          mapping: Object.fromEntries(
            Object.entries(mapping!).map(([k, v]) => [k, v.filter((c) => c)]),
          ),
          metas: metas as Record<string, string | boolean> | undefined,
        },
        { signal },
      );
      return previews;
    },
  });
};

export default useCampaignMessagePreviews;
