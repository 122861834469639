import "./LoaderMensajes.css";

const LoaderMensajes = () => {
  return (
    <div className="LoaderMensajes">
      <div className="LoaderMensajes__loader" />
    </div>
  );
};

export default LoaderMensajes;
