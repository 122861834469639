import { ErrorBoundary } from "@sentry/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import useAnswersQuery from "api/hooks/useAnswersQuery";
import WaitlistOverlay from "components/organisms/WaitlistOverlay";
import { toggleDebugging } from "store/slices/cero";

import ErrorBoundaryFallback from "../ErrorBoundaryFallback";
import Novedades from "../Novedades";
import Toaster from "../Toaster";
import AlertaPilotos from "./AlertaPilotos";
import BarraLateral from "./BarraLateral";
import "./Feedback.css";
import PaymentDueBanner from "./PaymentDueBanner";
import PaymentDueModal from "./PaymentDueModal";
import Topbar from "./Topbar";

const Feedback = () => {
  useAnswersQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    const teclasMagicas = (e: KeyboardEvent) => {
      if (e.code === "Digit0") {
        dispatch(toggleDebugging());
      }
    };
    window.addEventListener("keyup", teclasMagicas);
    return () => window.removeEventListener("keyup", teclasMagicas);
  }, [dispatch]);

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Toaster />
      <div className="Feedback">
        <WaitlistOverlay />
        <Novedades />
        <BarraLateral />
        <div className="Feedback__contenedor">
          <AlertaPilotos />
          <PaymentDueModal />
          <PaymentDueBanner />
          <Topbar />
          <div className="Feedback__contenedor_central">
            <Outlet />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Feedback;
