import { format } from "date-fns";
import Linkify from "linkify-react";
import { useRef } from "react";

import imagenUnreachable from "assets/images/unreachable.png";
import TagRespuesta from "components/Feedback/Respuestas/TablaRespuestas/TagRespuesta/TagRespuesta";
import { formatearCampoRespuestas } from "helpers/respuestas";
import { getTag } from "helpers/tags";

import "./MensajeSinWhatsapp.css";

interface MensajeSinWhatsappProps {
  start: Date;
  intentos?: { phone: string; timestamp: string }[];
}

const MensajeSinWhatsapp = ({ start, intentos }: MensajeSinWhatsappProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  return (
    <div className="MensajeSinWhatsapp">
      <dialog ref={dialogRef} className="MensajeSinWhatsapp__dialogo">
        <div className="MensajeSinWhatsapp__dialogo_contenido">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TagRespuesta tag={getTag("UNREACHABLE")} />
          </div>
          <p>
            Desde ahora en Feedback estamos marcando los números de teléfono de
            pacientes que no pudimos contactar por no tener Whatsapp u otros
            motivos.
          </p>
          <form method="dialog">
            <button className="MensajeSinWhatsapp__boton_cerrar_dialogo">
              ¡Entendido!
            </button>
          </form>
        </div>
      </dialog>
      <button
        className="MensajeSinWhatsapp__boton_explicacion"
        onClick={() => dialogRef.current?.showModal()}
      >
        ¿Qué es esto?
      </button>
      <div className="MensajeSinWhatsapp__iconos">
        <img
          className="MensajeSinWhatsapp__imagen"
          src={imagenUnreachable}
          alt="No pudimos contactar a este paciente"
        />
      </div>
      <div>
        <h3 className="MensajeSinWhatsapp__titulo">
          No pudimos contactar a este paciente
        </h3>
        <Linkify options={{ target: "_blank" }}>
          <p className="MensajeSinWhatsapp__contenido">
            <span>Esto puede pasar porque:</span>
            <br />
            <span>
              1. El teléfono no tiene WhatsApp.
              <br />
              2. La aplicación de WhatsApp está desactualizada. Las versiones
              mínimas requeridas son:
              <ul>
                <li>Android: 2.21.15.15</li>
                <li>iOS: 2.21.170.4</li>
              </ul>
              3. El paciente no ha aceptado los nuevos términos y condiciones de
              WhatsApp. Debe aceptarlos en https://wa.me/tos/20210210 desde su
              dispositivo.
              <br />
              <br />
            </span>
            {intentos && intentos.length > 1 ? (
              <>
                <span>Intentamos con los siguientes números sin éxito:</span>
                <ol className="MensajeSinWhatsapp__lista_telefonos">
                  {intentos?.map((intento, i) => (
                    <li key={`MensajeSinWhatsapp__lista_telefonos-${i}`}>
                      {formatearCampoRespuestas(intento.phone, "phone")}
                    </li>
                  ))}
                </ol>
              </>
            ) : (
              // TODO aqui hay que modificar
              <></>
            )}
          </p>
        </Linkify>
      </div>
      <p className="MensajeSinWhatsapp__fecha">{format(start, "HH:mm")}</p>
    </div>
  );
};

export default MensajeSinWhatsapp;
