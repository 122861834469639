import { EncuestasState } from "store/slices/encuestas";
import { RespuestasState } from "store/slices/respuestas";

const crearPollPorFiltro = (
  encuesta: NonNullable<EncuestasState["tipos"]>[number],
  respuestas: NonNullable<RespuestasState["respuestas"]>,
  header: string,
) => {
  const encuestasFicticias = Array.from(
    new Set(respuestas.map((r) => r[header])),
  )
    .map((s) => {
      const nombreEncuesta = `${encuesta.nombre} - ${s}`;
      return {
        id: `filtro|${header}|${s}|${encuesta.id}|${nombreEncuesta}`,
        nombre: nombreEncuesta,
        propiedad: s,
        enabled: encuesta.enabled,
      };
    })
    .sort((s1, s2) => (s1.nombre < s2.nombre ? -1 : 1));
  return encuestasFicticias.length > 0 ? encuestasFicticias : [];
};

export const obtenerPollsCalculadas = (
  encuesta: NonNullable<EncuestasState["tipos"]>[number],
  respuestas: RespuestasState["respuestas"],
) => {
  if (!respuestas) {
    return [];
  }
  switch (encuesta.id) {
    case 217: // POLL_SANTA_BLANCA_RECONFIRMACION
    case 481: // POLL_SANTA_BLANCA_CONFIRMACION
    case 871: // POLL_COANIQUEM_CONFIRMACION
    case Number(process.env.REACT_APP_ID_POLL_DENTALSTUDIO):
    case Number(process.env.REACT_APP_ID_POLL_EVEREST1):
    case Number(process.env.REACT_APP_ID_POLL_EVEREST2):
    case Number(process.env.REACT_APP_ID_POLL_OAS):
    case Number(process.env.REACT_APP_ID_POLL_OAS_RECONFIRMACION):
    case Number(process.env.REACT_APP_ID_POLL_SMILE_DESIGN):
    case Number(process.env.REACT_APP_ID_POLL_SMILE_KIDS_CENTER):
      return crearPollPorFiltro(encuesta, respuestas, "sucursal_name");
    case 483: // POLL_SANTA_BLANCA_CONFIRMACION_MULTI
    case 872: // POLL_COANIQUEM_CONFIRMACION_MULTI
      return crearPollPorFiltro(encuesta, respuestas, "sucursal_name_1");
    default:
      return [];
  }
};
