import { useQuery } from "@tanstack/react-query";
import { parseISO } from "date-fns";

import { Note, InteractionId } from "../types/domain";
import { ReactionsAPIResponse } from "../types/responses";
import { API_ROOT, get } from "./utils";

const useNotesQuery = ({
  interactionId,
}: {
  interactionId?: InteractionId;
}) => {
  return useQuery<Note[], unknown>({
    queryKey: ["notes", interactionId],
    queryFn: async ({ signal }) => {
      if (!interactionId) {
        return [];
      }
      const { serviceId, patientId, start } = interactionId;
      const startParam = start.toISOString();
      const { data } = await get<ReactionsAPIResponse>(
        `${API_ROOT}/reactions/${serviceId}/${patientId}?start=${startParam}`,
        { signal },
      );
      return data.data.map((reaction) => ({
        id: reaction.id,
        timestamp: parseISO(reaction.created_at),
        text: reaction.reaction_text,
        emoji: reaction.reaction_emoji,
      }));
    },
    refetchInterval: 10_000,
  });
};

export default useNotesQuery;
