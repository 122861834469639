import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";

export type ToastData = {
  id: number;
  message: string;
  type: "success" | "error";
  durationMs?: number;
};

interface ToastsState {
  toasts: ToastData[];
  counter: number;
  maxVisibleToasts: number;
}

const toastsSlice = createSlice({
  name: "toasts",
  initialState: {
    toasts: [],
    counter: 0,
    maxVisibleToasts: 5,
  } as ToastsState,
  reducers: {
    addToast(state, action: PayloadAction<Omit<ToastData, "id">>) {
      state.toasts = [
        ...state.toasts,
        { id: state.counter++, ...action.payload },
      ];
    },
    removeToast(state, action: PayloadAction<number>) {
      state.toasts = state.toasts.filter((t) => t.id !== action.payload);
    },
    clearToasts(state) {
      state.toasts = [];
      state.counter = 0;
    },
  },
});

export const { addToast, removeToast, clearToasts } = toastsSlice.actions;
export const toastsSelector = (state: RootState) => state.toasts;

export default toastsSlice.reducer;
