import { es } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";

import { MetricsTimeSeriesTimeUnit } from "api/hooks/useMetricsTimeSeriesQuery";
import DatePicker from "components/DatePicker";
import useAnalytics from "hooks/useAnalytics";
import {
  dashboardSelector,
  setEndDate,
  setGroupByUnit,
  setIncludeSaturdays,
  setIncludeSundays,
  setIncludeWeekends,
  setStartDate,
} from "store/slices/dashboard";

import "./DashboardDataSelectors.css";
import { getBoundedDate, getDateFormat } from "./utils";

const DashboardDataSelectors = () => {
  const {
    start: startDate,
    end: endDate,
    timeUnit,
    includeWeekends,
    includeSaturdays,
    includeSundays,
  } = useSelector(dashboardSelector);
  const dispatch = useDispatch();
  const track = useAnalytics();

  return (
    <div className="DashboardDataSelectors">
      <div className="DashboardDataSelectors__field_container">
        <label className="DashboardDataSelectors__field_label">
          Agrupar por
        </label>
        <select
          onChange={(e) => {
            track("Feedback", "Dashboard", "setGroupByUnit", {
              unit: e.target.value,
            });
            dispatch(
              setGroupByUnit(e.target.value as MetricsTimeSeriesTimeUnit),
            );
          }}
          className="DashboardDataSelectors__input"
          value={timeUnit}
        >
          <option value="DAY">Día</option>
          <option value="WEEK">Semana</option>
          <option value="MONTH">Mes</option>
        </select>
      </div>
      <div className="DashboardDataSelectors__field_container">
        <label className="DashboardDataSelectors__field_label">Desde</label>
        <DatePicker
          selected={new Date(startDate)}
          onChange={(newDate) => {
            if (!newDate) {
              return;
            }
            track("Feedback", "Dashboard", "setStartDate", { newDate });
            dispatch(setStartDate(getBoundedDate(timeUnit, newDate, "START")));
          }}
          dateFormat={getDateFormat(timeUnit)}
          locale={es}
          className="DashboardDataSelectors__input"
          selectsStart
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          showWeekNumbers={timeUnit === "WEEK"}
          showWeekPicker={timeUnit === "WEEK"}
          showMonthYearPicker={timeUnit === "MONTH"}
          maxDate={new Date()}
          required
        />
      </div>
      <div className="DashboardDataSelectors__field_container">
        <label className="DashboardDataSelectors__field_label">Hasta</label>
        <DatePicker
          selected={new Date(endDate)}
          onChange={(newDate) => {
            if (!newDate) {
              return;
            }
            track("Feedback", "Dashboard", "setEndDate", { newDate });
            dispatch(setEndDate(getBoundedDate(timeUnit, newDate, "END")));
          }}
          dateFormat={getDateFormat(timeUnit)}
          locale={es}
          className="DashboardDataSelectors__input"
          selectsEnd
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          showWeekNumbers={timeUnit === "WEEK"}
          showWeekPicker={timeUnit === "WEEK"}
          showMonthYearPicker={timeUnit === "MONTH"}
          maxDate={new Date()}
          required
        />
      </div>
      {timeUnit === "DAY" && (
        <div className="DashboardDataSelectors__checkboxes_container">
          <div className="DashboardDataSelectors__checkbox_container">
            <input
              type="checkbox"
              id="include-weekends-checkbox"
              checked={includeWeekends}
              onChange={(e) => dispatch(setIncludeWeekends(e.target.checked))}
            />
            <label
              className="DashboardDataSelectors__field_label"
              htmlFor="include-weekends-checkbox"
            >
              Incluir fines de semana
            </label>
          </div>
          <div className="DashboardDataSelectors__checkbox_container DashboardDataSelectors__checkbox_container--secondary">
            <input
              type="checkbox"
              id="include-saturdays-checkbox"
              checked={includeSaturdays}
              onChange={(e) => dispatch(setIncludeSaturdays(e.target.checked))}
            />
            <label
              className="DashboardDataSelectors__field_label"
              htmlFor="include-saturdays-checkbox"
            >
              Incluir sábados
            </label>
          </div>
          <div className="DashboardDataSelectors__checkbox_container DashboardDataSelectors__checkbox_container--secondary">
            <input
              type="checkbox"
              id="include-sundays-checkbox"
              checked={includeSundays}
              onChange={(e) => dispatch(setIncludeSundays(e.target.checked))}
            />
            <label
              className="DashboardDataSelectors__field_label"
              htmlFor="include-sundays-checkbox"
            >
              Incluir domingos
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardDataSelectors;
