import "./Loader.css";

interface LoaderProps {
  color?: string;
}

const Loader = ({ color }: LoaderProps) => {
  return (
    <div
      className="Loader"
      style={{ borderLeftColor: color ?? "var(--color-principal)" }}
    />
  );
};

export default Loader;
