import Linkify from "linkify-react";

import unreachableImage from "assets/images/unreachable.png";

import "./SmartphoneUnreachableMessage.css";

const SmartphoneUnreachableMessage = () => {
  return (
    <div className="SmartphoneUnreachableMessage">
      <div className="SmartphoneUnreachableMessage__image_container">
        <img
          className="SmartphoneUnreachableMessage__image"
          src={unreachableImage}
          alt="No pudimos contactar a este paciente"
        />
      </div>
      <div>
        <h3 className="SmartphoneUnreachableMessage__title">
          No pudimos contactar a este paciente
        </h3>
        <Linkify options={{ target: "_blank" }}>
          <div className="SmartphoneUnreachableMessage__content">
            <span>Esto puede pasar porque:</span>
            <br />
            <span>
              1. El teléfono no tiene WhatsApp.
              <br />
              2. La aplicación de WhatsApp está desactualizada. Las versiones
              mínimas requeridas son:
              <ul>
                <li>Android: 2.21.15.15</li>
                <li>iOS: 2.21.170.4</li>
              </ul>
              3. El paciente no ha aceptado los nuevos términos y condiciones de
              WhatsApp. Debe aceptarlos en https://wa.me/tos/20210210 desde su
              dispositivo.
              <br />
              <br />
            </span>
          </div>
        </Linkify>
      </div>
    </div>
  );
};

export default SmartphoneUnreachableMessage;
