import { Icon } from "@iconify/react";
import classNames from "classnames";
import { forwardRef, useId, useState } from "react";
import { type FieldError } from "react-hook-form/dist/types/errors";
import { GroupBase, SelectInstance } from "react-select";

import Select from "components/atoms/Select";
import { SelectProps } from "components/atoms/Select/Select";

import "./SelectGroup.css";

type SelectGroupProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = SelectProps<Option, IsMulti, Group> & {
  onFocus?: () => void;
  label: string;
  description?: string;
  error?: FieldError;
  collapsable?: boolean;
};

const SelectGroup = forwardRef(function SelectGroup<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  {
    label,
    description,
    error,
    value,
    options,
    onFocus,
    ...selectProps
  }: SelectGroupProps<Option, IsMulti, Group>,
  ref: React.ForwardedRef<SelectInstance<Option, IsMulti, Group>>,
) {
  const htmlId = useId();
  const [innerValue] = useState(value);

  return (
    <div
      className={classNames("SelectGroup", {
        error: !!error,
      })}
    >
      <div className="SelectGroup__context">
        <label className="SelectGroup__label" htmlFor={htmlId}>
          {label}
        </label>
        {description && (
          <p className="SelectGroup__description">{description}</p>
        )}
      </div>

      <div className="SelectGroup__dynamic" tabIndex={-1}>
        <Select
          id={htmlId}
          value={innerValue}
          onFocus={onFocus}
          {...selectProps}
          ref={ref}
          options={options}
        />
        <div className="SelectGroup__error">
          <Icon
            className="SelectGroup__error_icon"
            icon="uil:exclamation-triangle"
          />
          <p className="SelectGroup__error_message">{error?.message}</p>
        </div>
      </div>
    </div>
  );
});

export default SelectGroup;
