import React from "react";

import { ChatAPIMessage } from "api/types/responses";

export const marcarNegritas = (
  texto: React.ReactHTMLElement<HTMLSpanElement>[],
): React.ReactHTMLElement<HTMLSpanElement>[] => {
  return texto.map((t) => {
    const children = t.props.children as [
      string,
      React.ReactHTMLElement<HTMLBRElement>,
    ];
    const partes = children[0].split("*");
    return {
      ...t,
      props: {
        ...t.props,
        children: [
          React.createElement(
            "span",
            [],
            partes.map((p, i) =>
              i % 2 === 1 ? (
                <strong className="MensajeWhatsapp__strong" key={Date.now()}>
                  {p}
                </strong>
              ) : (
                p
              ),
            ),
          ),
          ...children.slice(1),
        ],
      },
    };
  });
};

export const getSeenData = (message: ChatAPIMessage, visible: boolean) => {
  if (!(message.type === "bot" && visible)) {
    return;
  }
  const messages: string[] = [];
  if (message.timestamp_single_check)
    messages.push(`Enviado ${message.timestamp_single_check}`);
  if (message.timestamp_double_check)
    messages.push(`Recibido ${message.timestamp_double_check}`);
  if (message.timestamp_blue_double_check)
    messages.push(`Leído ${message.timestamp_blue_double_check}`);

  if (message.timestamp_blue_double_check)
    return {
      messages,
      suffix: "read",
      icon: "solar:check-read-linear",
    };
  else if (message.timestamp_double_check)
    return {
      messages,
      suffix: "",
      icon: "solar:check-read-linear",
    };
  else if (message.timestamp_single_check)
    return {
      messages,
      suffix: "",
      icon: "solar:unread-linear",
    };
  else
    return {
      messages,
      suffix: "",
      icon: "solar:clock-square-linear",
    };
};
