import * as _ from "lodash-es";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

import Button from "components/atoms/Button";
import { PostSpreadsheetResponse } from "feedback-api";

import "./AssignFieldsStep.css";
import FieldMapper from "./FieldMapper";

interface AssignFieldsStepProps {
  spreadsheetData: PostSpreadsheetResponse;
  previousState: Record<string, string[]> | undefined;
  onChange: (mapping: Record<string, string[]> | undefined) => void;
  onNext: () => void;
}

const AssignFieldsStep = ({
  spreadsheetData,
  previousState,
  onChange,
  onNext,
}: AssignFieldsStepProps) => {
  const { records, assign_form_schema } = spreadsheetData;
  const [initialState] = useState(() => previousState);
  const {
    control,
    formState: { isValid },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: Object.fromEntries(
      assign_form_schema.map(({ name, preassignments }) => [
        name,
        preassignments.length ? preassignments : [""],
      ]),
    ),
    shouldUnregister: true,
    mode: initialState ? "onChange" : "onSubmit",
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    if (initialState) {
      Object.entries(initialState).forEach(([name, value]) =>
        setValue(name, value, { shouldDirty: true }),
      );
    }
  }, [initialState, setValue]);

  useEffect(() => {
    if (_.isEqual(formValues, {})) {
      return;
    }
    onChange(isValid ? (formValues as Record<string, string[]>) : undefined);
  }, [isValid, formValues, onChange]);

  const rowsSample = records.slice(0, 3) as Record<string, string>[];
  const dataSample = Object.fromEntries(
    Object.keys(rowsSample[0]).map((k: string) => [
      k,
      rowsSample.map((r) => r[k]),
    ]),
  );

  return (
    <form className="AssignFieldsStep" onSubmit={handleSubmit(onNext)}>
      <div className="AssignFieldsStep__section">
        <h3 className="AssignFieldsStep__title">Asignar campos</h3>
        <p className="AssignFieldsStep__copy">
          Vincula las columnas de la planilla recién cargada con los campos
          esenciales para administrar la campaña. Nuestro sistema identificará
          las etiquetas estándar automáticamente, pero es aconsejable verificar
          para asegurar la precisión.
        </p>
      </div>
      <div className="AssignFieldsStep__section">
        {assign_form_schema.map(
          ({ name, type, separator, max_items, required }) => (
            <Controller
              key={name}
              control={control}
              name={name}
              render={({
                field: { onChange, name, value, ref },
                fieldState: { error, isDirty },
              }) => (
                <FieldMapper
                  name={name}
                  type={type}
                  maxItems={max_items}
                  separator={separator}
                  dataSample={dataSample}
                  value={value}
                  onChange={onChange}
                  isDirty={isDirty}
                  required={required}
                  error={error?.message}
                  ref={ref}
                />
              )}
              rules={{
                validate: {
                  noEmpty: (v) =>
                    !required ||
                    v.filter((x) => x).length > 0 ||
                    "Este campo es obligatorio. Por favor, asigna una columna para este campo.",
                  unique: (v) =>
                    new Set(v).size === v.length ||
                    "No está permitido repetir columnas. Por favor, selecciona columnas diferentes.",
                },
              }}
            />
          ),
        )}
      </div>
      <div className="AssignFieldsStep__actions">
        <Button type="submit" formNoValidate>
          Configurar campaña
        </Button>
      </div>
    </form>
  );
};

export default AssignFieldsStep;
