import { useQuery } from "@tanstack/react-query";
import { addHours, parseISO } from "date-fns";
import * as _ from "lodash-es";

import { Appointment, Interaction } from "../types/domain";
import {
  SearchAPIMultiAppointment,
  SearchAPIResponse,
  SearchAPISingleAppointment,
} from "../types/responses";
import { get, parseAPIDate, API_ROOT, getStatusFromSearchRow } from "./utils";

const useSearchQuery = (term: string) => {
  return useQuery<Interaction[], unknown>({
    queryKey: ["search", term],
    queryFn: async ({ signal }) => {
      if (!term) {
        return [];
      }
      const { data } = await get<SearchAPIResponse>(
        `${API_ROOT}/answers_es?query=${term}`,
        { signal },
      );
      const interactions = data.data.map((searchResult) => {
        const isMultiAppointment = "n_appointments" in searchResult;
        return isMultiAppointment
          ? searchMultiAppointmentToInteraction(searchResult)
          : searchSingleAppointmentToInteraction(searchResult);
      });
      return _.orderBy(interactions, "start", "desc");
    },
  });
};

const searchSingleAppointmentToInteraction = (
  appointment: SearchAPISingleAppointment,
): Interaction => {
  return {
    id: {
      patientId: appointment.user_id,
      serviceId: appointment.poll_id,
      start: addHours(
        parseISO(appointment.start),
        -(new Date().getTimezoneOffset() / 60),
      ),
    },
    branch: appointment.sucursal_name,
    phone: appointment.phone,
    appointments: [
      {
        datetime: parseAPIDate(
          appointment.date,
          appointment.time,
          appointment.start,
        ),
        patientName: appointment.name,
        rut: appointment.rut,
        id: appointment.id_cita,
        url: appointment.dentalink_link || appointment.medilink_link,
        status: getStatusFromSearchRow(appointment),
      },
    ],
    extraData: [],
    tags: [],
  };
};

const searchMultiAppointmentToInteraction = (
  appointment: SearchAPIMultiAppointment,
): Interaction => {
  return {
    id: {
      patientId: appointment.user_id,
      serviceId: appointment.poll_id,
      start: addHours(
        parseISO(appointment.start),
        -(new Date().getTimezoneOffset() / 60),
      ),
    },
    branch: appointment.sucursal_name_1 || appointment.sucursal_name,
    phone: appointment.phone,
    appointments: Array(Number(appointment.n_appointments))
      .fill(0)
      .map<Appointment>((_, i): Appointment => {
        const index = i + 1;
        const appointment_time =
          (appointment[
            `time_${index}` as keyof SearchAPIMultiAppointment
          ] as string) || "";
        return {
          datetime: parseAPIDate(
            appointment.date_1,
            appointment_time,
            appointment.start,
          ),
          patientName: appointment[
            `patient_name_${index}` as keyof SearchAPIMultiAppointment
          ] as string,
          rut: appointment[
            `rut_${index}` as keyof SearchAPIMultiAppointment
          ] as string,
          id: appointment[
            `id_cita_${index}` as keyof SearchAPIMultiAppointment
          ] as string,
          status: getStatusFromSearchRow(appointment),
        };
      }),
    extraData: [],
    tags: [],
  };
};

export default useSearchQuery;
