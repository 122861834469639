import { useQuery } from "@tanstack/react-query";

import { campaignsApi } from "feedback-api";
import type { GetConfigFieldsResponse } from "feedback-api";

const useCampaignConfigFieldsQuery = () => {
  return useQuery<GetConfigFieldsResponse, unknown>({
    queryKey: ["campaignConfigFields"],
    queryFn: async ({ signal }) => {
      const { data } =
        await campaignsApi.getCampaignConfigV4CampaignsConfigFieldsGet({
          signal,
        });
      return data;
    },
    refetchInterval: 300_000,
  });
};

export default useCampaignConfigFieldsQuery;
