import classNames from "classnames";

import "./Table.css";

interface TableProps {
  className?: string;
  children?: React.ReactNode;
}

const Table = ({ children, className }: TableProps) => (
  <table className={classNames("Table", className)}>{children}</table>
);

export default Table;
