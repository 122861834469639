import segmentPlugin from "@analytics/segment";
import { Analytics } from "analytics";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import { esCero } from "helpers/permisos";
import store from "store";
import { loginSelector } from "store/slices/login";

const analytics = Analytics({
  app: "Feedback",
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY!,
    }),
  ],
});
const cuenta = store.getState().login.cuenta;
if (cuenta) {
  analytics.identify(cuenta.toLowerCase());
}

function useAnalytics() {
  const { nombreUsuario: cliente, cuenta } = useSelector(loginSelector);
  return useCallback(
    (app: string, seccion: string, evento: string, parametros = {}) => {
      if (!cuenta || esCero(cuenta)) {
        return;
      }
      analytics.track([app, seccion, evento].join("-"), {
        cliente,
        cuenta: cuenta.toLowerCase(),
        url: window.location.href,
        ...parametros,
      });
    },
    [cliente, cuenta],
  );
}

export default useAnalytics;
