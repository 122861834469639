import { Icon } from "@iconify/react";
import { UIMatch, useMatches } from "react-router-dom";

import MenuUsuario from "./MenuUsuario";
import "./Topbar.css";

const Topbar = () => {
  const matches = useMatches() as UIMatch<
    unknown,
    { TopbarComponent?: () => JSX.Element }
  >[];
  const topbarComponents = matches
    .filter((match) => Boolean(match.handle?.TopbarComponent))
    .map((match) => match.handle.TopbarComponent!);
  return (
    <div className="Topbar">
      {topbarComponents.length ? (
        topbarComponents.map((TopbarComponent, i) => (
          <TopbarComponent key={i} />
        ))
      ) : (
        <div className="Topbar__generic_header">
          <Icon
            className="DropdownEncuesta__icono_empresa"
            icon="mdi:whatsapp"
          />
          <div className="DropdownEncuesta__nombre_encuesta">
            Todos los servicios
          </div>
        </div>
      )}
      <MenuUsuario />
    </div>
  );
};

export default Topbar;
