import { Icon } from "@iconify/react";
import classNames from "classnames";
import { format } from "date-fns";
import { useSelector } from "react-redux";

import { esCero } from "helpers/permisos";
import { ceroSelector } from "store/slices/cero";
import { loginSelector } from "store/slices/login";

import { SmartphoneChatMessage } from "../Smartphone";
import "./SmartphoneMessage.css";
import { getMessageContentComponent } from "./helpers";

interface SmartphoneMessageProps {
  data: SmartphoneChatMessage;
}

const SmartphoneMessage = ({ data }: SmartphoneMessageProps) => {
  const messageTime = format(data.message.timestamp, "H:mm");
  const messageContent = getMessageContentComponent(
    data.message.content,
    data.message.id,
  );

  const { debugging } = useSelector(ceroSelector);
  const { cuenta } = useSelector(loginSelector);

  return (
    <div
      className={classNames({
        SmartphoneMessage: true,
        "SmartphoneMessage--outbound": data.message.sender === "BOT",
        "SmartphoneMessage--focused": data.current,
        Smartphone__element_not_current: !data.current,
      })}
    >
      <span className="SmartphoneMessage__content">{messageContent}</span>
      <span className="SmartphoneMessage__time">{messageTime}</span>
      {esCero(cuenta) && debugging && data.message.sender === "USER" && (
        <span className="SmartphoneMessage__tag">
          <Icon icon="mdi:robot" /> {data.message.tag}
        </span>
      )}
    </div>
  );
};

export default SmartphoneMessage;
