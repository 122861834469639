import { Icon } from "@iconify/react";
import classNames from "classnames";
import { format } from "date-fns";
import { useCallback } from "react";

import useChangeAlertMutation from "api/hooks/useChangeAlertStatusMutation";
import { Alert } from "api/types/domain";
import {
  getAlertButtonIcon,
  getAlertButtonLabel,
  getAlertButtonTitle,
} from "components/Feedback/Alerts/helpers";
import useAnalytics from "hooks/useAnalytics";
import useIsLabeler from "hooks/useIsLabeler";

import "./SmartphoneAlert.css";

interface SmartphoneAlertProps {
  alert: Alert;
}

const SmartphoneAlert = ({ alert }: SmartphoneAlertProps) => {
  const { mutate, isPending } = useChangeAlertMutation({
    alertId: alert.id,
    serviceId: alert.serviceId,
    patientId: alert.patientId,
  });
  const track = useAnalytics();
  const isLabeler = useIsLabeler();

  const changeAlertSolvedStatus = useCallback(() => {
    mutate({ solved: !alert.solved });
    track(
      "Feedback",
      "Smartphone",
      alert.solved ? "markAlertAsPending" : "markAlertAsSolved",
      { alert },
    );
  }, [alert, track, mutate]);

  return (
    <div
      className={classNames({
        SmartphoneAlert: true,
        "SmartphoneAlert--solved": alert.solved,
        "SmartphoneAlert--pending": !alert.solved,
      })}
      title="Alerta (visible solo en Feedback)"
    >
      <div className="SmartphoneAlert__icon_container">
        <Icon
          className="SmartphoneAlert__icon"
          icon={alert.solved ? "mdi:bell-check" : "mdi:bell-ring"}
        />
        <div className="SmartphoneAlert__time">
          {format(alert.timestamp, "HH:mm")}
        </div>
      </div>
      <p className="SmartphoneAlert__message">{alert.typeName}</p>
      <p className="SmartphoneAlert__title">
        Alerta {alert.solved ? "resuelta" : "pendiente"}
      </p>
      {!isLabeler && (
        <button
          className="SmartphoneAlert__button"
          onClick={changeAlertSolvedStatus}
          title={getAlertButtonTitle(alert.solved)}
          disabled={isPending}
        >
          <Icon icon={getAlertButtonIcon(alert.solved)} />{" "}
          {getAlertButtonLabel(alert.solved)}
        </button>
      )}
    </div>
  );
};

export default SmartphoneAlert;
