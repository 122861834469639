import { Features, Permissions } from "../constants";

export const tieneAccesoAReportes = (cuenta: string | undefined) => {
  if (!cuenta) {
    return false;
  }
  if (!cuenta.startsWith("centauro") && !cuenta.startsWith("centraldental")) {
    return true;
  }
  return [
    "centauro_cero",
    "centauro_zona_a",
    "centauro_zona_b",
    "centauro_zona_c",
    "centauro_cat",
    "centauro_sistemas",
    "centraldental",
    "centauro",
    "centraldental_cero",
  ].includes(cuenta);
};

export const esCero = (cuenta: string | undefined) => {
  return !!cuenta && cuenta.toLowerCase().endsWith("_cero");
};

const esLEH = (cuenta: string | undefined) => {
  return !!cuenta && cuenta.toLowerCase().endsWith("_leh");
};

export const tieneAccesoADashboard = (
  cuenta: string | undefined,
  permissions: string[] = [],
) => {
  return (
    (!!cuenta && permissions.includes(Permissions.Dashboard)) || esCero(cuenta)
  );
};

export const showAlertDismissedBy = (cuenta: string | undefined) => {
  return !!cuenta && cuenta.toLowerCase().indexOf("farr") >= 0;
};

export const tieneAccesoAListasEspera = (
  cuenta: string | undefined,
  permissions: string[] = [],
  features: string[] = [],
) => {
  return (
    !!cuenta &&
    features.includes(Features.HospitalWaitlists) &&
    (permissions.includes(Permissions.HospitalWaitlists) ||
      esCero(cuenta) ||
      esLEH(cuenta))
  );
};

export const tieneAccesoASuspensiones = (
  cuenta: string | undefined,
  permissions: string[] = [],
  features: string[] = [],
) => {
  return (
    !!cuenta &&
    features.includes(Features.Suspensions) &&
    permissions.includes(Permissions.Suspensions)
  );
};
