import { InlineIcon } from "@iconify/react";
import { captureException, setUser as setSentryUser } from "@sentry/react";
import { HttpStatusCode, isAxiosError } from "axios";
import classNames from "classnames";
import { format } from "date-fns";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { login as loginAPI } from "api/endpoints";
import logo from "assets/images/logo-feedback.svg";
import Loader from "components/Loader";
import { guardaTiposEncuestas } from "store/slices/encuestas";
import { guardaDatosSesion } from "store/slices/login";

import "./Login.css";

const Login = () => {
  const [auth, setAuth] = useState({
    username: "",
    password: "",
  });
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState<string>();
  const [cargando, setCargando] = useState(false);
  const dispatch = useDispatch();

  const cambiarVariable =
    (variable: "username" | "password") =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAuth({ ...auth, [variable]: e.target.value });
    };

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setCargando(true);
      const { data } = await loginAPI(auth.username, auth.password);
      setSentryUser({ username: auth.username });
      dispatch(guardaDatosSesion({ ...data, cuenta: auth.username }));
      dispatch(guardaTiposEncuestas(data));
    } catch (e) {
      setCargando(false);
      if (
        isAxiosError(e) &&
        e.response?.status === HttpStatusCode.Unauthorized
      ) {
        setError("Usuario o contraseña incorrectos.");
      } else {
        captureException(e);
        setError(
          "Servicio no disponible en estos momentos. Por favor, intenta de nuevo en unos minutos.",
        );
      }
    }
  };

  return (
    <div
      className={classNames({
        Login: true,
        "Login--cargando": cargando,
      })}
    >
      <div className="Login__contenedor_logo">
        <img className="Login__logo" src={logo} alt="Logo Cero Feedback" />
      </div>
      <form className="Login__form" onSubmit={login}>
        <h1 className="Login__instruccion">Inicia sesión en tu cuenta</h1>
        <div className="Login__campo">
          <label htmlFor="login_usuario" className="Login__label">
            Nombre de usuario
          </label>
          <input
            className="Login__input"
            type="text"
            onChange={cambiarVariable("username")}
            id="login_usuario"
            autoFocus
            required
            autoComplete="username"
          />
        </div>
        <div className="Login__campo">
          <label htmlFor="login_password" className="Login__label">
            Contraseña
          </label>
          <div className="Login__input_wrapper">
            <input
              className="Login__input"
              type={isPasswordVisible ? "text" : "password"}
              onChange={cambiarVariable("password")}
              id="login_password"
              autoComplete="current-password"
              required
            />
            <button
              className="Login__toggle"
              type="button"
              aria-label={
                isPasswordVisible
                  ? "Ocultar contraseña."
                  : "Mostrar contraseña en texto plano. Advertencia: esto mostrará tu contraseña en la pantalla."
              }
              onClick={() => setPasswordVisible(!isPasswordVisible)}
            >
              <InlineIcon
                icon={isPasswordVisible ? "uil:eye-slash" : "uil:eye"}
              />
            </button>
          </div>
        </div>
        {error && (
          <p className="Login__error">
            <InlineIcon
              className="Login__icono_error"
              icon="uil:exclamation-triangle"
            />
            {error}
          </p>
        )}
        <button className="Login__boton" type="submit" disabled={cargando}>
          {cargando ? <Loader /> : "Ingresar"}
        </button>
      </form>
      <footer className="Login__footer">
        © Cero {format(Date.now(), "yyyy")}
      </footer>
    </div>
  );
};

export default Login;
