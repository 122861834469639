import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="NotFound">
      <div className="NotFound__container">
        <h1 className="NotFound__title">
          Error 404: No se encontró la URL solicitada
        </h1>
        <Link to="/" className="NotFound__go_to_home_button">
          <Icon icon="mdi:home" />
        </Link>
        <p>
          ¿Quizá la URL es incorrecta? Presiona este botón para ir a la página
          principal.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
