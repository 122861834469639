import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as _ from "lodash-es";

import { LoginAPIResponse } from "api/types/responses";

import { RootState } from ".";

interface LoginState {
  sucursales?: { name: string }[];
  token?: string;
  fechaToken?: number;
  nombreUsuario?: string;
  cuenta?: string;
  idCliente?: number;
  features?: string[];
  permissions?: string[];
}

type SessionPayload = LoginAPIResponse & { cuenta: string };

const loginSlice = createSlice({
  name: "login",
  initialState: {} as LoginState,
  reducers: {
    guardaDatosSesion(state, action: PayloadAction<SessionPayload>) {
      const {
        token,
        client: nombreUsuario,
        cuenta,
        features,
        permissions,
        polls,
      } = action.payload;
      state.sucursales = _.uniq(_.flatten(polls.map((p) => p.sucursales)));
      state.token = token;
      state.fechaToken = Date.now();
      state.nombreUsuario = nombreUsuario;
      state.cuenta = cuenta;
      state.idCliente = polls?.[0]?.client_id;
      state.features = features;
      state.permissions = permissions;
    },
    cierraLaSesion(state) {
      state.token = undefined;
      state.fechaToken = undefined;
      state.nombreUsuario = undefined;
      state.features = undefined;
      state.permissions = undefined;
    },
  },
});

export const { guardaDatosSesion, cierraLaSesion } = loginSlice.actions;
export const loginSelector = (state: RootState) => state.login;

export default loginSlice.reducer;
