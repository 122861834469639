import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useMemo } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";

import { obtenerTiposEncuestasVisibles } from "helpers/encuestasSecretas";
import { obtenerPollsCalculadas } from "helpers/pollsCalculadas";
import { formatearNombreEncuesta } from "helpers/respuestas";
import useAnalytics from "hooks/useAnalytics";
import { encuestasSelector } from "store/slices/encuestas";
import { loginSelector } from "store/slices/login";
import { respuestasSelector } from "store/slices/respuestas";

import "./PopupEncuestas.css";

interface PopupEncuestasProps {
  activo: boolean;
  esconder: () => void;
  verEncuesta: (id: number | string) => Promise<void>;
}

const PopupEncuestas = ({
  activo,
  esconder,
  verEncuesta,
}: PopupEncuestasProps) => {
  const { tipos, idEncuestaSeleccionada } = useSelector(encuestasSelector);
  const { cuenta, nombreUsuario } = useSelector(loginSelector);
  const { respuestas } = useSelector(respuestasSelector);
  const track = useAnalytics();

  const tiposOrdenados = useMemo(() => {
    if (!tipos) {
      return [];
    }
    const encuestaSeleccionada = tipos.find(
      ({ id }) => id === idEncuestaSeleccionada,
    );
    if (!encuestaSeleccionada) {
      return obtenerTiposEncuestasVisibles(cuenta, tipos);
    }
    const encuestasCalculadas = obtenerPollsCalculadas(
      encuestaSeleccionada,
      respuestas,
    );
    const tiposEncuestas = [
      encuestaSeleccionada,
      ...encuestasCalculadas,
      ...tipos.filter(({ id }) => id !== idEncuestaSeleccionada),
    ];
    return obtenerTiposEncuestasVisibles(cuenta, tiposEncuestas);
  }, [tipos, idEncuestaSeleccionada, respuestas, cuenta]);

  const container = document.getElementById("popup-encuestas");
  if (!container) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <div
          className={classNames({
            PopupEncuestas__lamina: true,
            "PopupEncuestas__lamina--activo": activo,
          })}
          style={{ pointerEvents: activo ? "all" : "none" }}
          onClick={(e) => {
            esconder();
            e.stopPropagation();
          }}
        ></div>,
        container,
      )}
      <div
        className="PopupEncuestas"
        style={{
          pointerEvents: activo ? "all" : "none",
          opacity: activo ? 1 : 0,
        }}
      >
        {tiposOrdenados.map(({ id, nombre, enabled }) => (
          <div
            key={`boton-${id}`}
            onClick={(e) => {
              track("Feedback", "Respuestas", "verEncuestaConSelector", {
                idEncuesta: id,
                nombreEncuesta: nombre,
              });
              verEncuesta(id);
              esconder();
              e.stopPropagation();
            }}
            className={classNames({
              PopupEncuestas__opcion: true,
              "PopupEncuestas__opcion--indentada": typeof id === "string",
            })}
          >
            <Icon
              className="PopupEncuestas__icono_empresa"
              icon={
                typeof id === "string"
                  ? "mdi:map-marker-radius"
                  : "mdi:whatsapp"
              }
              style={{ color: enabled ? "#48BB78" : "#9f9eae" }}
            />
            <div className="PopupEncuestas__nombre_encuesta">
              {formatearNombreEncuesta(nombreUsuario, nombre)}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PopupEncuestas;
