import { Icon } from "@iconify/react";
import { setUser as setSentryUser } from "@sentry/react";
import { useDispatch } from "react-redux";

import { limpiaEncuestas } from "store/slices/encuestas";
import { cierraLaSesion } from "store/slices/login";

import "./Error403.css";

interface Error403Props {
  mensaje: string;
}

const Error403 = ({ mensaje }: Error403Props) => {
  const dispatch = useDispatch();

  return (
    <div className="Error403">
      <Icon icon="mdi:chat-remove-outline" className="Error403__icono" />
      <p className="Error403__mensaje">{mensaje}</p>
      <button
        className="Error403__boton_cerrar_sesion"
        onClick={() => {
          setSentryUser(null);
          dispatch(cierraLaSesion());
          dispatch(limpiaEncuestas());
        }}
      >
        Iniciar sesión con una cuenta diferente
      </button>
    </div>
  );
};

export default Error403;
