// TODO: move to ../../hooks/
import { useQuery } from "@tanstack/react-query";
import {
  formatISO,
  isSameDay,
  isSaturday,
  isSunday,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { useSelector } from "react-redux";

import { MetricCount } from "feedback-api";
import { dashboardSelector } from "store/slices/dashboard";

import useMetricsQuery from "./useMetricsQuery";

export type MetricsTimeSeriesTimeUnit = "DAY" | "WEEK" | "MONTH";

const useMetricsTimeSeriesQuery = () => {
  const {
    start: startDate,
    end: endDate,
    timeUnit,
    includeSaturdays,
    includeSundays,
    filters,
  } = useSelector(dashboardSelector);
  const start = formatISO(startDate, { representation: "date" });
  const end = formatISO(endDate, { representation: "date" });
  const { data: metricsData } = useMetricsQuery();

  return useQuery<MetricCount[], unknown>({
    queryKey: [
      "dashboard-ts",
      filters,
      start,
      end,
      timeUnit,
      includeSaturdays,
      includeSundays,
    ],
    queryFn: async () => {
      if (!metricsData) {
        return [];
      }
      if (timeUnit === "WEEK") {
        const weeklyData: MetricCount[] = [];
        metricsData.forEach((day) => {
          const monday = startOfWeek(day.date, { weekStartsOn: 1 });
          if (!weeklyData.find((w) => isSameDay(w.date, monday))) {
            weeklyData.push({
              date: formatISO(monday),
              total: 0,
              answered: 0,
              cancelled: 0,
              confirmed: 0,
            });
          }
          const i = weeklyData.findIndex((w) => isSameDay(w.date, monday));
          weeklyData[i].total += day.total;
          weeklyData[i].answered += day.answered;
          weeklyData[i].confirmed += day.confirmed;
          weeklyData[i].cancelled += day.cancelled;
        });
        return weeklyData;
      }
      if (timeUnit === "MONTH") {
        const monthlyData: MetricCount[] = [];
        metricsData.forEach((day) => {
          const monthStart = startOfMonth(day.date);
          if (!monthlyData.find((m) => isSameDay(m.date, monthStart))) {
            monthlyData.push({
              date: formatISO(monthStart),
              total: 0,
              answered: 0,
              cancelled: 0,
              confirmed: 0,
            });
          }
          const i = monthlyData.findIndex((m) => isSameDay(m.date, monthStart));
          monthlyData[i].total += day.total;
          monthlyData[i].answered += day.answered;
          monthlyData[i].confirmed += day.confirmed;
          monthlyData[i].cancelled += day.cancelled;
        });
        return monthlyData;
      }
      let filteredMetricsData = metricsData;
      if (!includeSaturdays) {
        filteredMetricsData = filteredMetricsData.filter(
          (d) => !isSaturday(d.date),
        );
      }
      if (!includeSundays) {
        filteredMetricsData = filteredMetricsData.filter(
          (d) => !isSunday(d.date),
        );
      }
      return filteredMetricsData;
    },
    enabled: !!metricsData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export default useMetricsTimeSeriesQuery;
