import { Icon } from "@iconify/react";

import { getPillStyle } from "../utils";
import "./MultiSelectPill.css";

interface MultiSelectPillProps {
  label: string;
  onRemove: () => void;
}

const MultiSelectPill = ({ label, onRemove }: MultiSelectPillProps) => {
  return (
    <span className="MultiSelectPill" style={getPillStyle(label)}>
      {label}{" "}
      <button
        className="MultiSelectPill__button"
        onClick={onRemove}
        title={`Remover "${label}"`}
      >
        <Icon icon="mdi:close" />
      </button>
    </span>
  );
};

export default MultiSelectPill;
