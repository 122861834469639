/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApiV4SchemasCampaignsPreviewMessagesRequest
 */
export interface ApiV4SchemasCampaignsPreviewMessagesRequest {
    /**
     * A row parsed from the spreadsheet as a record. Every record is represented as a flat object, with the column headers and the column values as keys and values respectively.
     * @type {{ [key: string]: CreateCampaignRequestRecordsInnerValue; }}
     * @memberof ApiV4SchemasCampaignsPreviewMessagesRequest
     */
    'record': { [key: string]: CreateCampaignRequestRecordsInnerValue; };
    /**
     * A reverse map describing the relation between a meta field and the columns it should be obtained from.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ApiV4SchemasCampaignsPreviewMessagesRequest
     */
    'mapping': { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: CampaignConfigMetasValue; }}
     * @memberof ApiV4SchemasCampaignsPreviewMessagesRequest
     */
    'metas'?: { [key: string]: CampaignConfigMetasValue; } | null;
}
/**
 * 
 * @export
 * @interface ApiV4SchemasSuspensionsPreviewMessagesRequest
 */
export interface ApiV4SchemasSuspensionsPreviewMessagesRequest {
    /**
     * The metadata of the appointment.
     * @type {object}
     * @memberof ApiV4SchemasSuspensionsPreviewMessagesRequest
     */
    'appointment': object;
    /**
     * 
     * @type {{ [key: string]: CampaignConfigMetasValue; }}
     * @memberof ApiV4SchemasSuspensionsPreviewMessagesRequest
     */
    'custom_external_meta'?: { [key: string]: CampaignConfigMetasValue; } | null;
}
/**
 * 
 * @export
 * @interface BoolConfigField
 */
export interface BoolConfigField {
    /**
     * 
     * @type {string}
     * @memberof BoolConfigField
     */
    'meta_name': string;
    /**
     * 
     * @type {string}
     * @memberof BoolConfigField
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof BoolConfigField
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof BoolConfigField
     */
    'message_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BoolConfigField
     */
    'default_value': boolean;
    /**
     * 
     * @type {string}
     * @memberof BoolConfigField
     */
    'type': BoolConfigFieldTypeEnum;
}

export const BoolConfigFieldTypeEnum = {
    Switch: 'switch'
} as const;

export type BoolConfigFieldTypeEnum = typeof BoolConfigFieldTypeEnum[keyof typeof BoolConfigFieldTypeEnum];

/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * The internal ID of the campaign.
     * @type {number}
     * @memberof Campaign
     */
    'id': number;
    /**
     * The internal ID of the client.
     * @type {number}
     * @memberof Campaign
     */
    'client_id': number;
    /**
     * The internal ID of the poll.
     * @type {number}
     * @memberof Campaign
     */
    'poll_id': number;
    /**
     * The name of the campaign.
     * @type {string}
     * @memberof Campaign
     */
    'name': string;
    /**
     * The date the campaign was set to start on.
     * @type {string}
     * @memberof Campaign
     */
    'start_date': string;
    /**
     * The date the campaign was created by the user.
     * @type {string}
     * @memberof Campaign
     */
    'created_at': string;
    /**
     * The number of rows that turned into interactions.
     * @type {number}
     * @memberof Campaign
     */
    'loaded_rows': number;
    /**
     * The number of rows that were not turned into interactions.
     * @type {number}
     * @memberof Campaign
     */
    'skipped_rows': number;
    /**
     * The number of effective interactions.
     * @type {number}
     * @memberof Campaign
     */
    'responded_rows': number;
    /**
     * The number of assignments.
     * @type {number}
     * @memberof Campaign
     */
    'assigned_rows': number;
    /**
     * The campaign status.
     * @type {CampaignStatus}
     * @memberof Campaign
     */
    'status': CampaignStatus;
}


/**
 * 
 * @export
 * @interface CampaignConfig
 */
export interface CampaignConfig {
    /**
     * The name of the campaign.
     * @type {string}
     * @memberof CampaignConfig
     */
    'name': string;
    /**
     * The date the campaign will start as a timezone aware date. If this value is in the past, the campaign will start immediately.
     * @type {string}
     * @memberof CampaignConfig
     */
    'start': string;
    /**
     * 
     * @type {{ [key: string]: CampaignConfigMetasValue; }}
     * @memberof CampaignConfig
     */
    'metas'?: { [key: string]: CampaignConfigMetasValue; } | null;
    /**
     * The ID of the spreadsheet file uploaded during the campaign creation user flow.
     * @type {string}
     * @memberof CampaignConfig
     */
    'file_id': string;
}
/**
 * 
 * @export
 * @interface CampaignConfigMetasValue
 */
export interface CampaignConfigMetasValue {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CampaignStatus = {
    Processing: 'PROCESSING',
    Processed: 'PROCESSED',
    Failed: 'FAILED'
} as const;

export type CampaignStatus = typeof CampaignStatus[keyof typeof CampaignStatus];


/**
 * 
 * @export
 * @interface CampaignUsage
 */
export interface CampaignUsage {
    /**
     * The number of interactions initiated this month so far.
     * @type {number}
     * @memberof CampaignUsage
     */
    'monthly_interactions': number;
    /**
     * The total number of interactions initiated so far.
     * @type {number}
     * @memberof CampaignUsage
     */
    'lifetime_interactions': number;
    /**
     * The maximum number of interactions per campaign.
     * @type {number}
     * @memberof CampaignUsage
     */
    'max_campaign_size': number;
    /**
     * The maximum number of interactions per month.
     * @type {number}
     * @memberof CampaignUsage
     */
    'max_monthly_interactions': number;
    /**
     * The maximum number of lifetime interactions.
     * @type {number}
     * @memberof CampaignUsage
     */
    'max_lifetime_interactions': number;
}
/**
 * 
 * @export
 * @interface CandidateAppointment
 */
export interface CandidateAppointment {
    /**
     * The ID of the appointment.
     * @type {string}
     * @memberof CandidateAppointment
     */
    'id': string;
    /**
     * The full name of the patient.
     * @type {string}
     * @memberof CandidateAppointment
     */
    'patient_name': string;
    /**
     * The display data of the appointment.
     * @type {{ [key: string]: string; }}
     * @memberof CandidateAppointment
     */
    'appointment_display_data': { [key: string]: string; };
    /**
     * 
     * @type {object}
     * @memberof CandidateAppointment
     */
    'appointment_data': object | null;
    /**
     * Whether a suspension for this appointment was already notified.
     * @type {boolean}
     * @memberof CandidateAppointment
     */
    'notified': boolean;
    /**
     * Whether this appointment has phone number data.
     * @type {boolean}
     * @memberof CandidateAppointment
     */
    'has_phone': boolean;
}
/**
 * 
 * @export
 * @interface ConcatAssignFieldSchema
 */
export interface ConcatAssignFieldSchema {
    /**
     * The name of the field to map to. The frontend user is expected to set and/or confirm a mapping from one or more columns to this field.
     * @type {string}
     * @memberof ConcatAssignFieldSchema
     */
    'name': string;
    /**
     * The type of assignment for this field.
     * @type {string}
     * @memberof ConcatAssignFieldSchema
     */
    'type': ConcatAssignFieldSchemaTypeEnum;
    /**
     * The string to use as separator between the values of each column.
     * @type {string}
     * @memberof ConcatAssignFieldSchema
     */
    'separator'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConcatAssignFieldSchema
     */
    'max_items'?: number | null;
    /**
     * The columns to preassign to this field.
     * @type {Array<string>}
     * @memberof ConcatAssignFieldSchema
     */
    'preassignments': Array<string>;
    /**
     * Whether the field must be mapped to a column or not.
     * @type {boolean}
     * @memberof ConcatAssignFieldSchema
     */
    'required': boolean;
}

export const ConcatAssignFieldSchemaTypeEnum = {
    Concat: 'concat'
} as const;

export type ConcatAssignFieldSchemaTypeEnum = typeof ConcatAssignFieldSchemaTypeEnum[keyof typeof ConcatAssignFieldSchemaTypeEnum];

/**
 * @type ConfigField
 * @export
 */
export type ConfigField = { type: 'switch' } & BoolConfigField | { type: 'text' } & StrConfigField | { type: 'textarea' } & StrConfigField;

/**
 * 
 * @export
 * @interface CreateCampaignRequest
 */
export interface CreateCampaignRequest {
    /**
     * The rows parsed from the spreadsheet as records. Every record is represented as a flat object, with the column headers and the column values as keys and values respectively.
     * @type {Array<{ [key: string]: CreateCampaignRequestRecordsInnerValue; }>}
     * @memberof CreateCampaignRequest
     */
    'records': Array<{ [key: string]: CreateCampaignRequestRecordsInnerValue; }>;
    /**
     * A reverse map describing the relation between a meta field and the columns it should be obtained from.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof CreateCampaignRequest
     */
    'mapping': { [key: string]: Array<string>; };
    /**
     * Campaign specific fields such as the name and starting date.
     * @type {CampaignConfig}
     * @memberof CreateCampaignRequest
     */
    'config': CampaignConfig;
}
/**
 * 
 * @export
 * @interface CreateCampaignRequestRecordsInnerValue
 */
export interface CreateCampaignRequestRecordsInnerValue {
}
/**
 * 
 * @export
 * @interface CreateCampaignResponse
 */
export interface CreateCampaignResponse {
    /**
     * The ID of the newly created campaign.
     * @type {number}
     * @memberof CreateCampaignResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface CreateSuspensionRequest
 */
export interface CreateSuspensionRequest {
    /**
     * The timezone-aware instant since which appointments will be suspended (inclusive).
     * @type {string}
     * @memberof CreateSuspensionRequest
     */
    'start': string;
    /**
     * The timezone-aware instant until which appointments will be suspended (inclusive).
     * @type {string}
     * @memberof CreateSuspensionRequest
     */
    'end': string;
    /**
     * A user-defined name for the suspension.
     * @type {string}
     * @memberof CreateSuspensionRequest
     */
    'name': string;
    /**
     * The ID of the professional associated to the suspension.
     * @type {string}
     * @memberof CreateSuspensionRequest
     */
    'professional_id': string;
    /**
     * The display name of the professional associated to the suspension.
     * @type {string}
     * @memberof CreateSuspensionRequest
     */
    'professional_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSuspensionRequest
     */
    'center_id'?: string | null;
    /**
     * The IDs of the appointments to be excluded from the suspension.
     * @type {Array<string>}
     * @memberof CreateSuspensionRequest
     */
    'excluded_appointment_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateSuspensionResponse
 */
export interface CreateSuspensionResponse {
    /**
     * The ID of the newly created suspension.
     * @type {number}
     * @memberof CreateSuspensionResponse
     */
    'suspension_id': number;
}
/**
 * 
 * @export
 * @interface DebtorData
 */
export interface DebtorData {
    /**
     * 
     * @type {string}
     * @memberof DebtorData
     */
    'mostExpiredDocumentIssueDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorData
     */
    'nearestDueDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof DebtorData
     */
    'nearestDueDateIssueDate': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ErrorCode = {
    ConnectionError: 'connection_error',
    GenericError: 'generic_error'
} as const;

export type ErrorCode = typeof ErrorCode[keyof typeof ErrorCode];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {ErrorCode}
     * @memberof ErrorResponse
     */
    'error_code': ErrorCode;
}


/**
 * 
 * @export
 * @interface FiltersResponse
 */
export interface FiltersResponse {
    /**
     * The list of filters available for this client.
     * @type {Array<MetricFilter>}
     * @memberof FiltersResponse
     */
    'filters': Array<MetricFilter>;
}
/**
 * 
 * @export
 * @interface GetCampaignsResponse
 */
export interface GetCampaignsResponse {
    /**
     * The list of existing campaigns for a given client.
     * @type {Array<Campaign>}
     * @memberof GetCampaignsResponse
     */
    'campaigns': Array<Campaign>;
}
/**
 * 
 * @export
 * @interface GetConfigFieldsResponse
 */
export interface GetConfigFieldsResponse {
    /**
     * 
     * @type {Array<ConfigField>}
     * @memberof GetConfigFieldsResponse
     */
    'standard_fields': Array<ConfigField>;
    /**
     * 
     * @type {Array<ConfigField>}
     * @memberof GetConfigFieldsResponse
     */
    'advanced_fields': Array<ConfigField>;
}
/**
 * 
 * @export
 * @interface GetMaxSuspensionHoursResponse
 */
export interface GetMaxSuspensionHoursResponse {
    /**
     * The maximum duration of a suspension in hours.
     * @type {number}
     * @memberof GetMaxSuspensionHoursResponse
     */
    'max_suspension_hours': number;
}
/**
 * 
 * @export
 * @interface GetProfessionalsResponse
 */
export interface GetProfessionalsResponse {
    /**
     * List of professional\'s data
     * @type {Array<ProfessionalData>}
     * @memberof GetProfessionalsResponse
     */
    'professionals_data': Array<ProfessionalData>;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ListAssignFieldSchema
 */
export interface ListAssignFieldSchema {
    /**
     * The name of the field to map to. The frontend user is expected to set and/or confirm a mapping from one or more columns to this field.
     * @type {string}
     * @memberof ListAssignFieldSchema
     */
    'name': string;
    /**
     * The type of assignment for this field.
     * @type {string}
     * @memberof ListAssignFieldSchema
     */
    'type': ListAssignFieldSchemaTypeEnum;
    /**
     * The columns to preassign to this field.
     * @type {Array<string>}
     * @memberof ListAssignFieldSchema
     */
    'preassignments': Array<string>;
    /**
     * Whether the field must be mapped to a column or not.
     * @type {boolean}
     * @memberof ListAssignFieldSchema
     */
    'required': boolean;
}

export const ListAssignFieldSchemaTypeEnum = {
    List: 'list'
} as const;

export type ListAssignFieldSchemaTypeEnum = typeof ListAssignFieldSchemaTypeEnum[keyof typeof ListAssignFieldSchemaTypeEnum];

/**
 * 
 * @export
 * @interface MessagePreview
 */
export interface MessagePreview {
    /**
     * The ID of the message.
     * @type {string}
     * @memberof MessagePreview
     */
    'id': string;
    /**
     * The label to use as a reference for the message.
     * @type {string}
     * @memberof MessagePreview
     */
    'display_name': string;
    /**
     * The preview of the message.
     * @type {string}
     * @memberof MessagePreview
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface MetricCount
 */
export interface MetricCount {
    /**
     * The date these counts are associated to.
     * @type {string}
     * @memberof MetricCount
     */
    'date': string;
    /**
     * The number of appointments that were loaded by the integration.
     * @type {number}
     * @memberof MetricCount
     */
    'total': number;
    /**
     * The number of appointments for which the patient responded.
     * @type {number}
     * @memberof MetricCount
     */
    'answered': number;
    /**
     * The number of appointments confirmed by the patient.
     * @type {number}
     * @memberof MetricCount
     */
    'confirmed': number;
    /**
     * The number of appointments cancelled by the patient.
     * @type {number}
     * @memberof MetricCount
     */
    'cancelled': number;
}
/**
 * One of the filters available for this client.
 * @export
 * @interface MetricFilter
 */
export interface MetricFilter {
    /**
     * 
     * @type {string}
     * @memberof MetricFilter
     */
    'kind'?: MetricFilterKindEnum;
    /**
     * Internal value to be used when filtering data.
     * @type {string}
     * @memberof MetricFilter
     */
    'id': string;
    /**
     * The name to be used for display in the UI.
     * @type {string}
     * @memberof MetricFilter
     */
    'label': string;
    /**
     * The list of allowed values for this filter.
     * @type {Array<string>}
     * @memberof MetricFilter
     */
    'values': Array<string>;
}

export const MetricFilterKindEnum = {
    Freeform: 'FREEFORM'
} as const;

export type MetricFilterKindEnum = typeof MetricFilterKindEnum[keyof typeof MetricFilterKindEnum];

/**
 * 
 * @export
 * @interface MetricFreeformFilter
 */
export interface MetricFreeformFilter {
    /**
     * 
     * @type {string}
     * @memberof MetricFreeformFilter
     */
    'kind'?: MetricFreeformFilterKindEnum;
    /**
     * Internal value to be used when filtering data.
     * @type {string}
     * @memberof MetricFreeformFilter
     */
    'id': string;
    /**
     * The name to be used for display in the UI.
     * @type {string}
     * @memberof MetricFreeformFilter
     */
    'label': string;
}

export const MetricFreeformFilterKindEnum = {
    Freeform: 'FREEFORM'
} as const;

export type MetricFreeformFilterKindEnum = typeof MetricFreeformFilterKindEnum[keyof typeof MetricFreeformFilterKindEnum];

/**
 * 
 * @export
 * @interface MetricLevelsFilter
 */
export interface MetricLevelsFilter {
    /**
     * 
     * @type {string}
     * @memberof MetricLevelsFilter
     */
    'kind'?: MetricLevelsFilterKindEnum;
    /**
     * Internal value to be used when filtering data.
     * @type {string}
     * @memberof MetricLevelsFilter
     */
    'id': string;
    /**
     * The name to be used for display in the UI.
     * @type {string}
     * @memberof MetricLevelsFilter
     */
    'label': string;
    /**
     * The list of allowed values for this filter.
     * @type {Array<string>}
     * @memberof MetricLevelsFilter
     */
    'values': Array<string>;
}

export const MetricLevelsFilterKindEnum = {
    Levels: 'LEVELS'
} as const;

export type MetricLevelsFilterKindEnum = typeof MetricLevelsFilterKindEnum[keyof typeof MetricLevelsFilterKindEnum];

/**
 * 
 * @export
 * @interface MetricsResponse
 */
export interface MetricsResponse {
    /**
     * An array of objects providing the partial counts for a set of dates.
     * @type {Array<MetricCount>}
     * @memberof MetricsResponse
     */
    'counts': Array<MetricCount>;
}
/**
 * 
 * @export
 * @interface PostSpreadsheetResponse
 */
export interface PostSpreadsheetResponse {
    /**
     * The rows parsed from the spreadsheet as records. Every records is represented as a flat object, with the column headers and the column values as keys and values respectively.
     * @type {Array<{ [key: string]: CreateCampaignRequestRecordsInnerValue; }>}
     * @memberof PostSpreadsheetResponse
     */
    'records': Array<{ [key: string]: CreateCampaignRequestRecordsInnerValue; }>;
    /**
     * The collection of fields to be mapped to spreadsheet columns, in the order they should be displayed to the user.
     * @type {Array<PostSpreadsheetResponseAssignFormSchemaInner>}
     * @memberof PostSpreadsheetResponse
     */
    'assign_form_schema': Array<PostSpreadsheetResponseAssignFormSchemaInner>;
    /**
     * The ID of the file for later reference.
     * @type {string}
     * @memberof PostSpreadsheetResponse
     */
    'file_id': string;
}
/**
 * 
 * @export
 * @interface PostSpreadsheetResponseAssignFormSchemaInner
 */
export interface PostSpreadsheetResponseAssignFormSchemaInner {
    /**
     * The name of the field to map to. The frontend user is expected to set and/or confirm a mapping from one or more columns to this field.
     * @type {string}
     * @memberof PostSpreadsheetResponseAssignFormSchemaInner
     */
    'name': string;
    /**
     * The type of assignment for this field.
     * @type {string}
     * @memberof PostSpreadsheetResponseAssignFormSchemaInner
     */
    'type': PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum;
    /**
     * The columns to preassign to this field.
     * @type {Array<string>}
     * @memberof PostSpreadsheetResponseAssignFormSchemaInner
     */
    'preassignments': Array<string>;
    /**
     * Whether the field must be mapped to a column or not.
     * @type {boolean}
     * @memberof PostSpreadsheetResponseAssignFormSchemaInner
     */
    'required': boolean;
    /**
     * The string to use as separator between the values of each column.
     * @type {string}
     * @memberof PostSpreadsheetResponseAssignFormSchemaInner
     */
    'separator'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostSpreadsheetResponseAssignFormSchemaInner
     */
    'max_items'?: number;
}

export const PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum = {
    Single: 'single',
    List: 'list',
    Concat: 'concat'
} as const;

export type PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum = typeof PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum[keyof typeof PostSpreadsheetResponseAssignFormSchemaInnerTypeEnum];

/**
 * 
 * @export
 * @interface PreviewMessagesResponse
 */
export interface PreviewMessagesResponse {
    /**
     * The preview of the messages, sorted by how they should be displayed to the user.
     * @type {Array<MessagePreview>}
     * @memberof PreviewMessagesResponse
     */
    'previews': Array<MessagePreview>;
}
/**
 * 
 * @export
 * @interface ProfessionalData
 */
export interface ProfessionalData {
    /**
     * Display data for the professional.
     * @type {string}
     * @memberof ProfessionalData
     */
    'display_data': string;
    /**
     * Search data for the professional.
     * @type {string}
     * @memberof ProfessionalData
     */
    'search_data': string;
    /**
     * 
     * @type {string}
     * @memberof ProfessionalData
     */
    'center_id': string | null;
}
/**
 * 
 * @export
 * @interface SingleAssignFieldSchema
 */
export interface SingleAssignFieldSchema {
    /**
     * The name of the field to map to. The frontend user is expected to set and/or confirm a mapping from one or more columns to this field.
     * @type {string}
     * @memberof SingleAssignFieldSchema
     */
    'name': string;
    /**
     * The type of assignment for this field.
     * @type {string}
     * @memberof SingleAssignFieldSchema
     */
    'type': SingleAssignFieldSchemaTypeEnum;
    /**
     * The column to preassign to this field.
     * @type {Array<string>}
     * @memberof SingleAssignFieldSchema
     */
    'preassignments': Array<string>;
    /**
     * Whether the field must be mapped to a column or not.
     * @type {boolean}
     * @memberof SingleAssignFieldSchema
     */
    'required': boolean;
}

export const SingleAssignFieldSchemaTypeEnum = {
    Single: 'single'
} as const;

export type SingleAssignFieldSchemaTypeEnum = typeof SingleAssignFieldSchemaTypeEnum[keyof typeof SingleAssignFieldSchemaTypeEnum];

/**
 * 
 * @export
 * @interface StrConfigField
 */
export interface StrConfigField {
    /**
     * 
     * @type {string}
     * @memberof StrConfigField
     */
    'meta_name': string;
    /**
     * 
     * @type {string}
     * @memberof StrConfigField
     */
    'display_name': string;
    /**
     * 
     * @type {string}
     * @memberof StrConfigField
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof StrConfigField
     */
    'message_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StrConfigField
     */
    'default_value': string;
    /**
     * 
     * @type {string}
     * @memberof StrConfigField
     */
    'type': StrConfigFieldTypeEnum;
}

export const StrConfigFieldTypeEnum = {
    Text: 'text',
    Textarea: 'textarea'
} as const;

export type StrConfigFieldTypeEnum = typeof StrConfigFieldTypeEnum[keyof typeof StrConfigFieldTypeEnum];

/**
 * 
 * @export
 * @interface SuspensionCandidatesResponse
 */
export interface SuspensionCandidatesResponse {
    /**
     * The appointments to be suspended.
     * @type {Array<CandidateAppointment>}
     * @memberof SuspensionCandidatesResponse
     */
    'candidates': Array<CandidateAppointment>;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * CampaignsApi - axios parameter creator
 * @export
 */
export const CampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new waitlist campaign.
         * @summary Create Campaign
         * @param {CreateCampaignRequest} createCampaignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaignV4CampaignsPost: async (createCampaignRequest: CreateCampaignRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCampaignRequest' is not null or undefined
            assertParamExists('createCampaignV4CampaignsPost', 'createCampaignRequest', createCampaignRequest)
            const localVarPath = `/v4/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCampaignRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves campaign configuration fields.
         * @summary Get Campaign Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignConfigV4CampaignsConfigFieldsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/campaigns/config_fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a report file for a specific campaign.
         * @summary Get Campaign Report
         * @param {number} campaignId The ID of the campaign for which to get a report file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignReportV4CampaignsCampaignIdReportGet: async (campaignId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('getCampaignReportV4CampaignsCampaignIdReportGet', 'campaignId', campaignId)
            const localVarPath = `/v4/campaigns/{campaign_id}/report`
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all campaigns.
         * @summary Get Campaigns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignsV4CampaignsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves limits over the size of campaigns.
         * @summary Get Limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimitsV4CampaignsLimitsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/campaigns/limits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Renders message previews for a given waitlist case.
         * @summary Preview Messages
         * @param {ApiV4SchemasCampaignsPreviewMessagesRequest} apiV4SchemasCampaignsPreviewMessagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewMessagesV4CampaignsPreviewsPost: async (apiV4SchemasCampaignsPreviewMessagesRequest: ApiV4SchemasCampaignsPreviewMessagesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiV4SchemasCampaignsPreviewMessagesRequest' is not null or undefined
            assertParamExists('previewMessagesV4CampaignsPreviewsPost', 'apiV4SchemasCampaignsPreviewMessagesRequest', apiV4SchemasCampaignsPreviewMessagesRequest)
            const localVarPath = `/v4/campaigns/previews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV4SchemasCampaignsPreviewMessagesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes an XLSX spreadsheet, returning each non-header row as a record and a suggested mapping. Such mapping is waitlist specific but a different mapping could be used in the future.
         * @summary Process Spreadsheet
         * @param {File} spreadsheet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processSpreadsheetV4CampaignsSpreadsheetsPost: async (spreadsheet: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'spreadsheet' is not null or undefined
            assertParamExists('processSpreadsheetV4CampaignsSpreadsheetsPost', 'spreadsheet', spreadsheet)
            const localVarPath = `/v4/campaigns/spreadsheets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


            if (spreadsheet !== undefined) { 
                localVarFormParams.append('spreadsheet', spreadsheet as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsApi - functional programming interface
 * @export
 */
export const CampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new waitlist campaign.
         * @summary Create Campaign
         * @param {CreateCampaignRequest} createCampaignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaignV4CampaignsPost(createCampaignRequest: CreateCampaignRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCampaignResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaignV4CampaignsPost(createCampaignRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.createCampaignV4CampaignsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves campaign configuration fields.
         * @summary Get Campaign Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignConfigV4CampaignsConfigFieldsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfigFieldsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignConfigV4CampaignsConfigFieldsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.getCampaignConfigV4CampaignsConfigFieldsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a report file for a specific campaign.
         * @summary Get Campaign Report
         * @param {number} campaignId The ID of the campaign for which to get a report file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignReportV4CampaignsCampaignIdReportGet(campaignId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignReportV4CampaignsCampaignIdReportGet(campaignId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.getCampaignReportV4CampaignsCampaignIdReportGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves all campaigns.
         * @summary Get Campaigns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignsV4CampaignsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCampaignsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignsV4CampaignsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.getCampaignsV4CampaignsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves limits over the size of campaigns.
         * @summary Get Limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLimitsV4CampaignsLimitsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignUsage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLimitsV4CampaignsLimitsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.getLimitsV4CampaignsLimitsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Renders message previews for a given waitlist case.
         * @summary Preview Messages
         * @param {ApiV4SchemasCampaignsPreviewMessagesRequest} apiV4SchemasCampaignsPreviewMessagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async previewMessagesV4CampaignsPreviewsPost(apiV4SchemasCampaignsPreviewMessagesRequest: ApiV4SchemasCampaignsPreviewMessagesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.previewMessagesV4CampaignsPreviewsPost(apiV4SchemasCampaignsPreviewMessagesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.previewMessagesV4CampaignsPreviewsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Processes an XLSX spreadsheet, returning each non-header row as a record and a suggested mapping. Such mapping is waitlist specific but a different mapping could be used in the future.
         * @summary Process Spreadsheet
         * @param {File} spreadsheet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processSpreadsheetV4CampaignsSpreadsheetsPost(spreadsheet: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSpreadsheetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processSpreadsheetV4CampaignsSpreadsheetsPost(spreadsheet, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CampaignsApi.processSpreadsheetV4CampaignsSpreadsheetsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CampaignsApi - factory interface
 * @export
 */
export const CampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsApiFp(configuration)
    return {
        /**
         * Creates a new waitlist campaign.
         * @summary Create Campaign
         * @param {CreateCampaignRequest} createCampaignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaignV4CampaignsPost(createCampaignRequest: CreateCampaignRequest, options?: any): AxiosPromise<CreateCampaignResponse> {
            return localVarFp.createCampaignV4CampaignsPost(createCampaignRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves campaign configuration fields.
         * @summary Get Campaign Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignConfigV4CampaignsConfigFieldsGet(options?: any): AxiosPromise<GetConfigFieldsResponse> {
            return localVarFp.getCampaignConfigV4CampaignsConfigFieldsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a report file for a specific campaign.
         * @summary Get Campaign Report
         * @param {number} campaignId The ID of the campaign for which to get a report file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignReportV4CampaignsCampaignIdReportGet(campaignId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getCampaignReportV4CampaignsCampaignIdReportGet(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all campaigns.
         * @summary Get Campaigns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignsV4CampaignsGet(options?: any): AxiosPromise<GetCampaignsResponse> {
            return localVarFp.getCampaignsV4CampaignsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves limits over the size of campaigns.
         * @summary Get Limits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLimitsV4CampaignsLimitsGet(options?: any): AxiosPromise<CampaignUsage> {
            return localVarFp.getLimitsV4CampaignsLimitsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Renders message previews for a given waitlist case.
         * @summary Preview Messages
         * @param {ApiV4SchemasCampaignsPreviewMessagesRequest} apiV4SchemasCampaignsPreviewMessagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewMessagesV4CampaignsPreviewsPost(apiV4SchemasCampaignsPreviewMessagesRequest: ApiV4SchemasCampaignsPreviewMessagesRequest, options?: any): AxiosPromise<PreviewMessagesResponse> {
            return localVarFp.previewMessagesV4CampaignsPreviewsPost(apiV4SchemasCampaignsPreviewMessagesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes an XLSX spreadsheet, returning each non-header row as a record and a suggested mapping. Such mapping is waitlist specific but a different mapping could be used in the future.
         * @summary Process Spreadsheet
         * @param {File} spreadsheet 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processSpreadsheetV4CampaignsSpreadsheetsPost(spreadsheet: File, options?: any): AxiosPromise<PostSpreadsheetResponse> {
            return localVarFp.processSpreadsheetV4CampaignsSpreadsheetsPost(spreadsheet, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignsApi - object-oriented interface
 * @export
 * @class CampaignsApi
 * @extends {BaseAPI}
 */
export class CampaignsApi extends BaseAPI {
    /**
     * Creates a new waitlist campaign.
     * @summary Create Campaign
     * @param {CreateCampaignRequest} createCampaignRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public createCampaignV4CampaignsPost(createCampaignRequest: CreateCampaignRequest, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).createCampaignV4CampaignsPost(createCampaignRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves campaign configuration fields.
     * @summary Get Campaign Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getCampaignConfigV4CampaignsConfigFieldsGet(options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).getCampaignConfigV4CampaignsConfigFieldsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a report file for a specific campaign.
     * @summary Get Campaign Report
     * @param {number} campaignId The ID of the campaign for which to get a report file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getCampaignReportV4CampaignsCampaignIdReportGet(campaignId: number, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).getCampaignReportV4CampaignsCampaignIdReportGet(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all campaigns.
     * @summary Get Campaigns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getCampaignsV4CampaignsGet(options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).getCampaignsV4CampaignsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves limits over the size of campaigns.
     * @summary Get Limits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public getLimitsV4CampaignsLimitsGet(options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).getLimitsV4CampaignsLimitsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Renders message previews for a given waitlist case.
     * @summary Preview Messages
     * @param {ApiV4SchemasCampaignsPreviewMessagesRequest} apiV4SchemasCampaignsPreviewMessagesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public previewMessagesV4CampaignsPreviewsPost(apiV4SchemasCampaignsPreviewMessagesRequest: ApiV4SchemasCampaignsPreviewMessagesRequest, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).previewMessagesV4CampaignsPreviewsPost(apiV4SchemasCampaignsPreviewMessagesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes an XLSX spreadsheet, returning each non-header row as a record and a suggested mapping. Such mapping is waitlist specific but a different mapping could be used in the future.
     * @summary Process Spreadsheet
     * @param {File} spreadsheet 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsApi
     */
    public processSpreadsheetV4CampaignsSpreadsheetsPost(spreadsheet: File, options?: RawAxiosRequestConfig) {
        return CampaignsApiFp(this.configuration).processSpreadsheetV4CampaignsSpreadsheetsPost(spreadsheet, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardsApi - axios parameter creator
 * @export
 */
export const DashboardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves available filters for this client.
         * @summary Get Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiltersV4DashboardsFiltersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/dashboards/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves metrics for the given date interval.
         * @summary Get Metrics
         * @param {string} startDate Lower bound for the appointment date.
         * @param {string} endDate Upper bound for the appointment date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricsV4DashboardsMetricsGet: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getMetricsV4DashboardsMetricsGet', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getMetricsV4DashboardsMetricsGet', 'endDate', endDate)
            const localVarPath = `/v4/dashboards/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardsApi - functional programming interface
 * @export
 */
export const DashboardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves available filters for this client.
         * @summary Get Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFiltersV4DashboardsFiltersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiltersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFiltersV4DashboardsFiltersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardsApi.getFiltersV4DashboardsFiltersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves metrics for the given date interval.
         * @summary Get Metrics
         * @param {string} startDate Lower bound for the appointment date.
         * @param {string} endDate Upper bound for the appointment date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetricsV4DashboardsMetricsGet(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetricsV4DashboardsMetricsGet(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardsApi.getMetricsV4DashboardsMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DashboardsApi - factory interface
 * @export
 */
export const DashboardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardsApiFp(configuration)
    return {
        /**
         * Retrieves available filters for this client.
         * @summary Get Filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiltersV4DashboardsFiltersGet(options?: any): AxiosPromise<FiltersResponse> {
            return localVarFp.getFiltersV4DashboardsFiltersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves metrics for the given date interval.
         * @summary Get Metrics
         * @param {string} startDate Lower bound for the appointment date.
         * @param {string} endDate Upper bound for the appointment date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetricsV4DashboardsMetricsGet(startDate: string, endDate: string, options?: any): AxiosPromise<MetricsResponse> {
            return localVarFp.getMetricsV4DashboardsMetricsGet(startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardsApi - object-oriented interface
 * @export
 * @class DashboardsApi
 * @extends {BaseAPI}
 */
export class DashboardsApi extends BaseAPI {
    /**
     * Retrieves available filters for this client.
     * @summary Get Filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApi
     */
    public getFiltersV4DashboardsFiltersGet(options?: RawAxiosRequestConfig) {
        return DashboardsApiFp(this.configuration).getFiltersV4DashboardsFiltersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves metrics for the given date interval.
     * @summary Get Metrics
     * @param {string} startDate Lower bound for the appointment date.
     * @param {string} endDate Upper bound for the appointment date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardsApi
     */
    public getMetricsV4DashboardsMetricsGet(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return DashboardsApiFp(this.configuration).getMetricsV4DashboardsMetricsGet(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DebtorsApi - axios parameter creator
 * @export
 */
export const DebtorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve Debtor data given client name.
         * @summary Get Debtor Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtorDataV4DebtorsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/debtors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DebtorsApi - functional programming interface
 * @export
 */
export const DebtorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DebtorsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve Debtor data given client name.
         * @summary Get Debtor Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDebtorDataV4DebtorsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DebtorData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDebtorDataV4DebtorsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DebtorsApi.getDebtorDataV4DebtorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DebtorsApi - factory interface
 * @export
 */
export const DebtorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DebtorsApiFp(configuration)
    return {
        /**
         * Retrieve Debtor data given client name.
         * @summary Get Debtor Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebtorDataV4DebtorsGet(options?: any): AxiosPromise<DebtorData> {
            return localVarFp.getDebtorDataV4DebtorsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DebtorsApi - object-oriented interface
 * @export
 * @class DebtorsApi
 * @extends {BaseAPI}
 */
export class DebtorsApi extends BaseAPI {
    /**
     * Retrieve Debtor data given client name.
     * @summary Get Debtor Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebtorsApi
     */
    public getDebtorDataV4DebtorsGet(options?: RawAxiosRequestConfig) {
        return DebtorsApiFp(this.configuration).getDebtorDataV4DebtorsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SuspensionsApi - axios parameter creator
 * @export
 */
export const SuspensionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new suspension.
         * @summary Create Suspension
         * @param {CreateSuspensionRequest} createSuspensionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSuspensionV4SuspensionsPost: async (createSuspensionRequest: CreateSuspensionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSuspensionRequest' is not null or undefined
            assertParamExists('createSuspensionV4SuspensionsPost', 'createSuspensionRequest', createSuspensionRequest)
            const localVarPath = `/v4/suspensions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSuspensionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of appointment candidates that would be suspended by given filters.
         * @summary Get Candidates
         * @param {string} professionalId The ID of the professional associated to the suspension.
         * @param {string} start The timezone-aware instant since which appointments will be suspended (inclusive).
         * @param {string} end The timezone-aware instant until which appointments will be suspended (inclusive).
         * @param {string | null} [centerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesV4SuspensionsCandidatesGet: async (professionalId: string, start: string, end: string, centerId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'professionalId' is not null or undefined
            assertParamExists('getCandidatesV4SuspensionsCandidatesGet', 'professionalId', professionalId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getCandidatesV4SuspensionsCandidatesGet', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getCandidatesV4SuspensionsCandidatesGet', 'end', end)
            const localVarPath = `/v4/suspensions/candidates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (professionalId !== undefined) {
                localVarQueryParameter['professional_id'] = professionalId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (centerId !== undefined) {
                localVarQueryParameter['center_id'] = centerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the maximum number of hours a suspension can last.
         * @summary Get Max Suspension Hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxSuspensionHoursV4SuspensionsMaxSuspensionHoursGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/suspensions/max_suspension_hours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves professionals data for suspension.
         * @summary Get Professionals Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionalsDataV4SuspensionsProfessionalsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v4/suspensions/professionals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Renders message previews for a given suspension candidate.
         * @summary Preview Messages
         * @param {ApiV4SchemasSuspensionsPreviewMessagesRequest} apiV4SchemasSuspensionsPreviewMessagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewMessagesV4SuspensionsPreviewsPost: async (apiV4SchemasSuspensionsPreviewMessagesRequest: ApiV4SchemasSuspensionsPreviewMessagesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiV4SchemasSuspensionsPreviewMessagesRequest' is not null or undefined
            assertParamExists('previewMessagesV4SuspensionsPreviewsPost', 'apiV4SchemasSuspensionsPreviewMessagesRequest', apiV4SchemasSuspensionsPreviewMessagesRequest)
            const localVarPath = `/v4/suspensions/previews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV4SchemasSuspensionsPreviewMessagesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuspensionsApi - functional programming interface
 * @export
 */
export const SuspensionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuspensionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new suspension.
         * @summary Create Suspension
         * @param {CreateSuspensionRequest} createSuspensionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSuspensionV4SuspensionsPost(createSuspensionRequest: CreateSuspensionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSuspensionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSuspensionV4SuspensionsPost(createSuspensionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuspensionsApi.createSuspensionV4SuspensionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a list of appointment candidates that would be suspended by given filters.
         * @summary Get Candidates
         * @param {string} professionalId The ID of the professional associated to the suspension.
         * @param {string} start The timezone-aware instant since which appointments will be suspended (inclusive).
         * @param {string} end The timezone-aware instant until which appointments will be suspended (inclusive).
         * @param {string | null} [centerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidatesV4SuspensionsCandidatesGet(professionalId: string, start: string, end: string, centerId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuspensionCandidatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidatesV4SuspensionsCandidatesGet(professionalId, start, end, centerId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuspensionsApi.getCandidatesV4SuspensionsCandidatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the maximum number of hours a suspension can last.
         * @summary Get Max Suspension Hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaxSuspensionHoursV4SuspensionsMaxSuspensionHoursGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMaxSuspensionHoursResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaxSuspensionHoursV4SuspensionsMaxSuspensionHoursGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuspensionsApi.getMaxSuspensionHoursV4SuspensionsMaxSuspensionHoursGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves professionals data for suspension.
         * @summary Get Professionals Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfessionalsDataV4SuspensionsProfessionalsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfessionalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfessionalsDataV4SuspensionsProfessionalsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuspensionsApi.getProfessionalsDataV4SuspensionsProfessionalsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Renders message previews for a given suspension candidate.
         * @summary Preview Messages
         * @param {ApiV4SchemasSuspensionsPreviewMessagesRequest} apiV4SchemasSuspensionsPreviewMessagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async previewMessagesV4SuspensionsPreviewsPost(apiV4SchemasSuspensionsPreviewMessagesRequest: ApiV4SchemasSuspensionsPreviewMessagesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.previewMessagesV4SuspensionsPreviewsPost(apiV4SchemasSuspensionsPreviewMessagesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SuspensionsApi.previewMessagesV4SuspensionsPreviewsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SuspensionsApi - factory interface
 * @export
 */
export const SuspensionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuspensionsApiFp(configuration)
    return {
        /**
         * Creates a new suspension.
         * @summary Create Suspension
         * @param {CreateSuspensionRequest} createSuspensionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSuspensionV4SuspensionsPost(createSuspensionRequest: CreateSuspensionRequest, options?: any): AxiosPromise<CreateSuspensionResponse> {
            return localVarFp.createSuspensionV4SuspensionsPost(createSuspensionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of appointment candidates that would be suspended by given filters.
         * @summary Get Candidates
         * @param {string} professionalId The ID of the professional associated to the suspension.
         * @param {string} start The timezone-aware instant since which appointments will be suspended (inclusive).
         * @param {string} end The timezone-aware instant until which appointments will be suspended (inclusive).
         * @param {string | null} [centerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesV4SuspensionsCandidatesGet(professionalId: string, start: string, end: string, centerId?: string | null, options?: any): AxiosPromise<SuspensionCandidatesResponse> {
            return localVarFp.getCandidatesV4SuspensionsCandidatesGet(professionalId, start, end, centerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the maximum number of hours a suspension can last.
         * @summary Get Max Suspension Hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaxSuspensionHoursV4SuspensionsMaxSuspensionHoursGet(options?: any): AxiosPromise<GetMaxSuspensionHoursResponse> {
            return localVarFp.getMaxSuspensionHoursV4SuspensionsMaxSuspensionHoursGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves professionals data for suspension.
         * @summary Get Professionals Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfessionalsDataV4SuspensionsProfessionalsGet(options?: any): AxiosPromise<GetProfessionalsResponse> {
            return localVarFp.getProfessionalsDataV4SuspensionsProfessionalsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Renders message previews for a given suspension candidate.
         * @summary Preview Messages
         * @param {ApiV4SchemasSuspensionsPreviewMessagesRequest} apiV4SchemasSuspensionsPreviewMessagesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewMessagesV4SuspensionsPreviewsPost(apiV4SchemasSuspensionsPreviewMessagesRequest: ApiV4SchemasSuspensionsPreviewMessagesRequest, options?: any): AxiosPromise<PreviewMessagesResponse> {
            return localVarFp.previewMessagesV4SuspensionsPreviewsPost(apiV4SchemasSuspensionsPreviewMessagesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuspensionsApi - object-oriented interface
 * @export
 * @class SuspensionsApi
 * @extends {BaseAPI}
 */
export class SuspensionsApi extends BaseAPI {
    /**
     * Creates a new suspension.
     * @summary Create Suspension
     * @param {CreateSuspensionRequest} createSuspensionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuspensionsApi
     */
    public createSuspensionV4SuspensionsPost(createSuspensionRequest: CreateSuspensionRequest, options?: RawAxiosRequestConfig) {
        return SuspensionsApiFp(this.configuration).createSuspensionV4SuspensionsPost(createSuspensionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of appointment candidates that would be suspended by given filters.
     * @summary Get Candidates
     * @param {string} professionalId The ID of the professional associated to the suspension.
     * @param {string} start The timezone-aware instant since which appointments will be suspended (inclusive).
     * @param {string} end The timezone-aware instant until which appointments will be suspended (inclusive).
     * @param {string | null} [centerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuspensionsApi
     */
    public getCandidatesV4SuspensionsCandidatesGet(professionalId: string, start: string, end: string, centerId?: string | null, options?: RawAxiosRequestConfig) {
        return SuspensionsApiFp(this.configuration).getCandidatesV4SuspensionsCandidatesGet(professionalId, start, end, centerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the maximum number of hours a suspension can last.
     * @summary Get Max Suspension Hours
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuspensionsApi
     */
    public getMaxSuspensionHoursV4SuspensionsMaxSuspensionHoursGet(options?: RawAxiosRequestConfig) {
        return SuspensionsApiFp(this.configuration).getMaxSuspensionHoursV4SuspensionsMaxSuspensionHoursGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves professionals data for suspension.
     * @summary Get Professionals Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuspensionsApi
     */
    public getProfessionalsDataV4SuspensionsProfessionalsGet(options?: RawAxiosRequestConfig) {
        return SuspensionsApiFp(this.configuration).getProfessionalsDataV4SuspensionsProfessionalsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Renders message previews for a given suspension candidate.
     * @summary Preview Messages
     * @param {ApiV4SchemasSuspensionsPreviewMessagesRequest} apiV4SchemasSuspensionsPreviewMessagesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuspensionsApi
     */
    public previewMessagesV4SuspensionsPreviewsPost(apiV4SchemasSuspensionsPreviewMessagesRequest: ApiV4SchemasSuspensionsPreviewMessagesRequest, options?: RawAxiosRequestConfig) {
        return SuspensionsApiFp(this.configuration).previewMessagesV4SuspensionsPreviewsPost(apiV4SchemasSuspensionsPreviewMessagesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



