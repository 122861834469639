import { useQuery } from "@tanstack/react-query";

import { suspensionsApi } from "feedback-api";

const useGetProfessionalsDataQuery = () => {
  return useQuery({
    queryKey: ["professionalsData"],
    queryFn: async () => {
      const { data } =
        await suspensionsApi.getProfessionalsDataV4SuspensionsProfessionalsGet();
      return data.professionals_data;
    },
  });
};

export default useGetProfessionalsDataQuery;
