import useAnswerMediaQuery from "api/hooks/useChatAnswerMediaQuery";
import Loader from "components/Loader";

import "./AudioMessage.css";

interface AudioMessageProps {
  answerId: number;
}

const AudioMessage = ({ answerId }: AudioMessageProps) => {
  const { data, isPending } = useAnswerMediaQuery(answerId);

  if (isPending) {
    return <Loader color="var(--color-principal)" />;
  }

  return (
    <div className="AudioMessage">
      <audio className="MensajeWhatsapp__audio" src={data?.url} controls />
    </div>
  );
};

export default AudioMessage;
