import { Icon } from "@iconify/react";
import classNames from "classnames";
import { forwardRef, useEffect, useId, useState } from "react";
import { type FieldError } from "react-hook-form/dist/types/errors";

import SwitchGroup from "../SwitchGroup";
import "./InputGroup.css";

interface InputGroupProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "onFocus"
  > {
  onChange?: (value?: string | number | readonly string[]) => void;
  onFocus?: () => void;
  label: string;
  description?: string;
  error?: FieldError;
  collapsable?: boolean;
}

const InputGroup = forwardRef(function InputGroup(
  {
    label,
    description,
    error,
    value,
    collapsable = false,
    onChange,
    onFocus,
    ...inputProps
  }: InputGroupProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const htmlId = useId();
  const [isCustom, setCustom] = useState(!collapsable);
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    const ref = setTimeout(
      () => onChange?.(isCustom ? innerValue : undefined),
      isCustom ? 300 : 10,
    );
    return () => clearTimeout(ref);
  }, [isCustom, innerValue, onChange]);

  return (
    <div
      className={classNames("InputGroup", {
        error: !!error,
        "InputGroup--collapsed": !isCustom,
      })}
    >
      {collapsable ? (
        <SwitchGroup
          label={label}
          description={description}
          value={isCustom}
          onChange={setCustom}
          onFocus={onFocus}
        />
      ) : (
        <div className="InputGroup__context">
          <label className="InputGroup__label" htmlFor={htmlId}>
            {label}
          </label>
          {description && (
            <p className="InputGroup__description">{description}</p>
          )}
        </div>
      )}
      <div className="InputGroup__dynamic" tabIndex={-1}>
        <input
          id={htmlId}
          value={innerValue}
          onChange={(e) => setInnerValue(e.target.value)}
          onFocus={onFocus}
          {...inputProps}
          ref={ref}
          tabIndex={isCustom ? 0 : -1}
        />
        <div className="InputGroup__error">
          <Icon
            className="InputGroup__error_icon"
            icon="uil:exclamation-triangle"
          />
          <p className="InputGroup__error_message">{error?.message}</p>
        </div>
      </div>
    </div>
  );
});

export default InputGroup;
