import { Icon } from "@iconify/react";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { differenceInMinutes, formatDistanceToNow } from "date-fns";
import { es } from "date-fns/locale";
import { useEffect, useState } from "react";

import useAnswersQuery from "api/hooks/useAnswersQuery";
import Tooltip from "components/Tooltip";
import useAnalytics from "hooks/useAnalytics";

import "./BotonActualizar.css";

const BotonActualizar = () => {
  const queryClient = useQueryClient();
  const { isLoading, isFetching, dataUpdatedAt } = useAnswersQuery();
  const track = useAnalytics();
  const [fechaActual, setFechaActual] = useState(Date.now());

  useEffect(() => {
    const intervalFecha = setInterval(
      () => setFechaActual(() => Date.now()),
      60_000,
    );
    return () => clearInterval(intervalFecha);
  }, [dataUpdatedAt]);

  const actualizar = () => {
    track("Feedback", "Respuestas", "actualizar");
    queryClient.invalidateQueries({ queryKey: ["answers"] });
  };

  const alertar = differenceInMinutes(fechaActual, dataUpdatedAt) >= 3;
  const mensajeActualizacion = isLoading
    ? "Cargando..."
    : `Actualizar (actualizado ${formatDistanceToNow(dataUpdatedAt, {
        locale: es,
        addSuffix: true,
      })})`;

  return (
    <Tooltip text={mensajeActualizacion}>
      <button
        className={classNames({
          BotonActualizar: true,
          "BotonActualizar--alerta": alertar,
        })}
        onClick={actualizar}
        disabled={isFetching}
      >
        <Icon className="BotonActualizar__icono" icon="mdi:refresh" />
      </button>
    </Tooltip>
  );
};

export default BotonActualizar;
