import { useQuery } from "@tanstack/react-query";

import { CandidateAppointment, ErrorCode, suspensionsApi } from "feedback-api";
import { ErrorResponse } from "feedback-api";

type SuspensionCandidateParams = {
  professionalId?: string;
  centerId?: string | null;
  startDate?: string;
  endDate?: string;
};
const useGetSuspensionCandidatesQuery = ({
  professionalId,
  centerId,
  startDate,
  endDate,
}: SuspensionCandidateParams) => {
  return useQuery<
    CandidateAppointment[],
    ErrorResponse,
    CandidateAppointment[],
    (string | null | undefined)[]
  >({
    queryKey: [
      "suspensionCandidates",
      professionalId,
      centerId,
      startDate,
      endDate,
    ],
    queryFn: async ({ signal }) => {
      try {
        const { data } =
          await suspensionsApi.getCandidatesV4SuspensionsCandidatesGet(
            professionalId!,
            startDate!,
            endDate!,
            centerId!,
            { signal },
          );
        return data.candidates;
      } catch (e: unknown) {
        if (
          typeof e === "object" &&
          !!e &&
          Object.keys(e).includes("error_code")
        ) {
          throw e as ErrorResponse;
        }
        throw { error_code: ErrorCode.GenericError } as ErrorResponse;
      }
    },
    enabled: !!professionalId && !!startDate && !!endDate,
    retry: false,
  });
};

export default useGetSuspensionCandidatesQuery;
