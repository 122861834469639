const apellidos = [
  "Gonzalez",
  "Muñoz",
  "Rojas",
  "Diaz",
  "Perez",
  "Soto",
  "Contreras",
  "Silva",
  "Martinez",
  "Sepulveda",
  "Morales",
  "Rodriguez",
  "Lopez",
  "Araya",
  "Fuentes",
  "Hernandez",
  "Torres",
  "Espinoza",
  "Flores",
  "Castillo",
  "Valenzuela",
  "Ramirez",
  "Reyes",
  "Gutierrez",
  "Castro",
  "Vargas",
  "Alvarez",
  "Vasquez",
  "Tapia",
  "Fernandez",
  "Sanchez",
  "Cortes",
  "Gomez",
  "Herrera",
  "Carrasco",
  "Nuñez",
  "Miranda",
  "Jara",
  "Vergara",
  "Rivera",
  "Figueroa",
  "Garcia",
  "Bravo",
  "Riquelme",
  "Vera",
  "Vega",
  "Molina",
  "Campos",
  "Sandoval",
  "Olivares",
  "Orellana",
  "Zuñiga",
  "Ortiz",
  "Gallardo",
  "Alarcon",
  "Garrido",
  "Salazar",
  "Pizarro",
  "Aguilera",
  "Saavedra",
  "Romero",
  "Guzman",
  "Henriquez",
  "Navarro",
  "Peña",
  "Aravena",
  "Godoy",
  "Caceres",
  "Parra",
  "Leiva",
  "Escobar",
  "Yañez",
  "Valdes",
  "Salinas",
  "Vidal",
  "Jimenez",
  "Lagos",
  "Ruiz",
  "Cardenas",
  "Bustos",
  "Medina",
  "Maldonado",
  "Pino",
  "Moreno",
  "Carvajal",
  "Palma",
  "Sanhueza",
  "Poblete",
  "Navarrete",
  "Saez",
  "Toro",
  "Donoso",
  "Ortega",
  "Venegas",
  "Bustamante",
  "Alvarado",
  "Acevedo",
  "Farias",
  "Acuña",
  "Guerrero",
  "Cerda",
  "Pinto",
  "Paredes",
  "Quezada",
  "San Martín",
  "Toledo",
  "Cornejo",
  "Mora",
  "Ramos",
  "Arriagada",
  "Arancibia",
  "Velasquez",
  "Hidalgo",
  "Salas",
  "Troncoso",
  "Aguilar",
  "Ulloa",
  "Cabrera",
  "Rios",
  "Inostroza",
  "Rivas",
  "Duran",
  "Leon",
  "Arias",
  "Villarroel",
  "Cuevas",
  "Osorio",
  "Marin",
  "Calderon",
  "Lara",
  "Mendez",
  "Chavez",
  "Catalan",
  "Ponce",
  "Pacheco",
  "Villalobos",
  "Ojeda",
  "Moya",
  "Correa",
  "Barrera",
  "Cifuentes",
  "Concha",
  "Burgos",
  "Delgado",
  "Alfaro",
  "Guerra",
  "Gajardo",
  "Salgado",
  "Astudillo",
  "Uribe",
  "Barria",
  "Avila",
  "Zapata",
  "Ahumada",
  "Meza",
  "Retamal",
  "Pavez",
  "Jorquera",
  "Aguirre",
  "Becerra",
  "Neira",
  "Galvez",
  "Lobos",
  "Barraza",
  "Valdebenito",
  "Moraga",
  "Abarca",
  "Valdivia",
  "Pereira",
  "Araneda",
  "Guajardo",
  "Carcamo",
  "Canales",
  "Barrientos",
  "Villegas",
  "Urrutia",
  "Cruz",
  "Gatica",
  "Riveros",
  "Ibarra",
  "Quiroz",
  "Mella",
  "Cisternas",
  "Cid",
  "Mancilla",
  "Baeza",
  "Gaete",
  "Mardones",
  "Mansilla",
  "Albornoz",
  "Manriquez",
  "Andrade",
  "Vallejos",
  "Leal",
  "Caro",
  "Valencia",
  "Carreño",
  "Rebolledo",
  "Fuentealba",
  "Solis",
];

export default apellidos;
