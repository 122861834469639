export const nombresHombres = [
  "andres",
  "antonio",
  "luis",
  "jose",
  "juan",
  "alejandro",
  "carlos",
  "enrique",
  "eduardo",
  "manuel",
  "alberto",
  "francisco",
  "ignacio",
  "patricio",
  "jorge",
  "javier",
  "rodrigo",
  "fernando",
  "cristian",
  "miguel",
  "hernan",
  "victor",
  "esteban",
  "mauricio",
  "segundo",
  "pablo",
  "felipe",
  "sergio",
  "daniel",
  "hector",
  "sebastian",
  "ricardo",
  "pedro",
  "marcelo",
  "guillermo",
  "roberto",
  "claudio",
  "mario",
  "nicolas",
  "alexis",
  "raul",
  "jaime",
  "ivan",
  "humberto",
  "angel",
  "david",
  "alfredo",
  "hugo",
  "gabriel",
  "alfonso",
  "cesar",
  "oscar",
  "orlando",
  "arturo",
  "jesus",
  "gonzalo",
  "ernesto",
  "ramon",
  "diego",
  "osvaldo",
  "rene",
  "julio",
  "matias",
  "marco",
  "armando",
  "nelson",
  "fabian",
  "leonardo",
  "omar",
  "gustavo",
  "ruben",
  "eugenio",
  "alex",
  "elias",
  "jonathan",
  "ariel",
  "alvaro",
  "rafael",
  "emilio",
  "christian",
  "alonso",
  "adolfo",
  "bernardo",
  "german",
  "gerardo",
  "domingo",
  "rodolfo",
  "alexander",
  "rolando",
  "camilo",
  "edgardo",
  "marcos",
  "benjamin",
  "octavio",
  "samuel",
  "moises",
  "cristobal",
  "tomas",
  "vicente",
].map((n) => `${n[0].toUpperCase()}${n.substring(1)}`);

export const nombresMujeres = [
  "maria",
  "andrea",
  "alejandra",
  "rosa",
  "isabel",
  "patricia",
  "ana",
  "carolina",
  "elizabeth",
  "angelica",
  "cecilia",
  "margarita",
  "elena",
  "veronica",
  "claudia",
  "paola",
  "teresa",
  "soledad",
  "ester",
  "ines",
  "francisca",
  "daniela",
  "marcela",
  "carmen",
  "camila",
  "gloria",
  "cristina",
  "eliana",
  "luisa",
  "fernanda",
  "alicia",
  "lorena",
  "sandra",
  "jacqueline",
  "paz",
  "juana",
  "constanza",
  "pamela",
  "eugenia",
  "monica",
  "ximena",
  "beatriz",
  "nicole",
  "paulina",
  "marta",
  "gladys",
  "viviana",
  "javiera",
  "macarena",
  "victoria",
  "luz",
  "silvia",
  "belen",
  "magdalena",
  "gabriela",
  "natalia",
  "catalina",
  "edith",
  "susana",
  "adriana",
  "katherine",
  "marisol",
  "jessica",
  "sonia",
  "mercedes",
  "karina",
  "ruth",
  "fabiola",
  "paula",
  "nancy",
  "olga",
  "valentina",
  "jose",
  "ivonne",
  "karen",
  "graciela",
  "raquel",
  "irene",
  "solange",
  "evelyn",
  "loreto",
  "valeria",
  "yolanda",
  "lidia",
  "barbara",
  "sara",
  "laura",
  "jeannette",
  "julia",
  "norma",
  "carla",
  "valeska",
  "tamara",
  "roxana",
].map((n) => `${n[0].toUpperCase()}${n.substring(1)}`);
