// TODO: move to ../../hooks/
import { useQuery } from "@tanstack/react-query";

import { InteractionExtraData, InteractionId } from "../types/domain";
import useChatQuery from "./useChatQuery";

const useInteractionDataQuery = ({
  interactionId,
}: {
  interactionId?: InteractionId;
}) => {
  const { data } = useChatQuery(interactionId);

  return useQuery<InteractionExtraData[], unknown>({
    queryKey: ["interaction-data", interactionId],
    queryFn: async () => {
      if (!data) {
        return [];
      }
      const { currentInteraction } = data;
      return currentInteraction.extraData;
    },
    enabled: !!data,
  });
};

export default useInteractionDataQuery;
