import "./Dashboard.css";
import DashboardDataFilters from "./DashboardDataFilters";
import DashboardDataSelectors from "./DashboardDataSelectors";
import DownloadDashboardDataButton from "./DownloadDashboardDataButton";
import ProgressDonut from "./ProgressDonut";
import TimeSeriesChart from "./TimeSeriesChart";

export const DashboardTopbar = () => (
  <h2 className="Dashboard__title">Dashboard</h2>
);

const Dashboard = () => {
  return (
    <div className="Dashboard">
      <div className="Dashboard__container">
        <div className="Dashboard__sidebar">
          <DashboardDataSelectors />
          <DashboardDataFilters />
          <DownloadDashboardDataButton />
        </div>
        <div className="Dashboard__top_charts">
          <ProgressDonut metric="TOTAL" />
          <ProgressDonut metric="ANSWERED" />
          <ProgressDonut metric="CONFIRMED" />
          <ProgressDonut metric="CANCELLED" />
        </div>
        <div className="Dashboard__chart_container">
          <TimeSeriesChart />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
