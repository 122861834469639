import { useSelector } from "react-redux";

import { respuestasSelector } from "store/slices/respuestas";

function useWhatsappLink(phone: string | undefined) {
  const { abrirAppWhatsapp } = useSelector(respuestasSelector);

  if (!phone) {
    return "";
  }

  return abrirAppWhatsapp
    ? `https://wa.me/${phone}`
    : `https://web.whatsapp.com/send/?phone=${phone}`;
}

export default useWhatsappLink;
