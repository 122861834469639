import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";

import { MetricsTimeSeriesTimeUnit } from "api/hooks/useMetricsTimeSeriesQuery";

export const getDateFormat = (unit: MetricsTimeSeriesTimeUnit): string => {
  switch (unit) {
    case "MONTH":
      return "MM / yyyy";
    case "WEEK":
      return "'S 'I / R";
    case "DAY":
      return "dd / MM / yyyy";
  }
};

export const getBoundedDate = (
  unit: MetricsTimeSeriesTimeUnit,
  date: number | Date,
  bound: "START" | "END",
): number => {
  switch (unit) {
    case "MONTH": {
      if (bound === "START") {
        return +startOfMonth(date);
      }
      return +endOfMonth(date);
    }
    case "WEEK": {
      if (bound === "START") {
        return +startOfWeek(date, { weekStartsOn: 1 });
      }
      return +endOfWeek(date, { weekStartsOn: 1 });
    }
    case "DAY":
      return +date;
  }
};
