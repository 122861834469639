import { Link } from "react-router-dom";

import { useCampaignLimitsPrefetch } from "api/hooks/useCampaignLimitsQuery";
import useCampaignsQuery from "api/hooks/useCampaignsQuery";
import SmartphoneMessage from "components/Feedback/InteractionDrawer/Smartphone/SmartphoneMessage";
import Loader from "components/Loader";
import useAnalytics from "hooks/useAnalytics";

import CampaignLimits from "./CampaignLimits";
import CampaignList from "./CampaignList";
import "./Campaigns.css";

const Campaigns = () => {
  const track = useAnalytics();

  useCampaignLimitsPrefetch();

  const { data: campaigns, isPending, isError } = useCampaignsQuery();

  const createCampaignButton = (section: string) => (
    <Link
      className="Campaigns__create-button"
      to="campanyas/nueva"
      onClick={() => track("Campaigns", section, "verNuevaCampanya")}
    >
      Nueva Campaña
    </Link>
  );

  return (
    <div className="Campaigns">
      {isPending ? (
        <Loader />
      ) : isError ? (
        <>Algo salió mal. Intente de nuevo más tarde</>
      ) : campaigns.length === 0 ? (
        <div className="Campaigns__no-campaigns__card">
          <div className="Campaigns__no-campaigns__figure">
            <SmartphoneMessage
              data={{
                message: {
                  timestamp: new Date(2024, 0, 1, 9, 0),
                  content:
                    "Hola, le escribo del Hospital de Cero. Necesitamos saber si la paciente Valentina Antonia Reyes González que figura en nuestra lista de espera, todavía necesita el procedimiento/examen de Gastroenterología. ¿Me confirma si aún requiere la cita?",
                  sender: "USER",
                  type: "TEXTO",
                  id: 0,
                },
                current: true,
                date: new Date(2024, 0, 1, 9, 0),
              }}
            />
            <SmartphoneMessage
              data={{
                message: {
                  timestamp: new Date(2024, 0, 1, 13, 37),
                  content: "Sí, muchas gracias. 😊",
                  sender: "BOT",
                  type: "TEXTO",
                  id: 1,
                },
                current: true,
                date: new Date(2024, 0, 1, 13, 37),
              }}
            />
          </div>
          <div className="Campaigns__no-campaigns__content">
            <div className="Campaigns__no-campaigns__body">
              <h3 className="Campaigns__no-campaigns__title">
                ¡Crea tu primera campaña!
              </h3>
              <p className="Campaigns__no-campaigns__paragraph">
                Una vez que hayas creado campañas, podrás visualizarlas y
                gestionarlas directamente desde esta sección.
              </p>
            </div>
            {createCampaignButton("cta")}
          </div>
        </div>
      ) : (
        <>
          <div className="Campaigns__header">
            <h2 className="Campaigns__title">Campañas</h2>
            {createCampaignButton("header")}
          </div>
          <div className="Campaigns__body">
            <CampaignList data={campaigns} />
            <CampaignLimits />
          </div>
        </>
      )}
    </div>
  );
};

export default Campaigns;
