import { Icon } from "@iconify/react";
import classNames from "classnames";
import * as _ from "lodash-es";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAnalytics from "hooks/useAnalytics";
import { buscaEsto, respuestasSelector } from "store/slices/respuestas";

import "./BuscadorRespuestas.css";

interface BuscadorRespuestasProps {
  cargando: boolean;
}

const BuscadorRespuestas = ({ cargando }: BuscadorRespuestasProps) => {
  const { busqueda } = useSelector(respuestasSelector);
  const dispatch = useDispatch();
  const track = useAnalytics();

  const trackBusqueda = useRef(
    _.debounce(
      (termino) =>
        track("Feedback", "Respuestas", "buscarEnTabla", { termino }),
      2_000,
    ),
  ).current;

  return (
    <div className="BuscadorRespuestas">
      <div
        className="BuscadorRespuestas__contenedor_icono"
        onClick={() => {
          track("Feedback", "Respuestas", "limpiarBusquedaTabla");
          dispatch(buscaEsto(""));
        }}
        style={{
          pointerEvents: busqueda ? "all" : "none",
          opacity: cargando ? 0.5 : 0.75,
        }}
        title="Limpiar filtro"
      >
        <Icon
          icon={busqueda ? "mdi:close" : "mdi:filter-variant"}
          className="BuscadorRespuestas__icono"
        />
      </div>
      <input
        type="text"
        className={classNames({
          BuscadorRespuestas__input: true,
          "BuscadorRespuestas__input--activo": busqueda,
        })}
        value={busqueda}
        onChange={(e) => {
          trackBusqueda(e.target.value);
          dispatch(buscaEsto(e.target.value));
        }}
        spellCheck="false"
        disabled={cargando}
        placeholder="Filtrar tabla"
      />
    </div>
  );
};

export default BuscadorRespuestas;
