import { formatRelative, isPast } from "date-fns";
import { es } from "date-fns/locale";
import Modal from "react-modal";

import Button from "components/atoms/Button";

import "./ConfirmModal.css";

interface ConfirmModalProps {
  isOpen: boolean;
  campaignStart: string;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  disabled: boolean;
}

const ConfirmModal = ({
  isOpen,
  campaignStart,
  onConfirm,
  onCancel,
  onClose,
  disabled = false,
}: ConfirmModalProps) => {
  const now = new Date();
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Confirmar carga"
      onRequestClose={onClose}
      className="ConfirmModal__modal"
      overlayClassName="ConfirmModal__overlay"
    >
      <div className="ConfirmModal__content">
        <h2 className="ConfirmModal__title">¿Estás seguro?</h2>
        <p className="ConfirmModal__copy">
          Una vez que confirmes, se enviarán las consultas a los pacientes{" "}
          {isPast(campaignStart)
            ? "de inmediato"
            : formatRelative(campaignStart, now, { locale: es })}
          .
        </p>
        <div className="ConfirmModal__actions">
          <Button size="medium" onClick={onConfirm} disabled={disabled}>
            Confirmar
          </Button>
          <Button
            size="medium"
            variant="outline"
            onClick={onCancel}
            disabled={disabled}
          >
            Volver
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
