import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import useAnswerMediaQuery from "./useChatAnswerMediaQuery";

interface VCardData {
  phone?: string;
  name?: string;
}

const useVCardQuery = (answerId: number, enabled: boolean = true) => {
  const { data } = useAnswerMediaQuery(answerId);
  return useQuery<AxiosResponse<string>, unknown, VCardData>({
    queryKey: ["vcard", answerId],
    queryFn: ({ signal }) => axios.get<string>(data!.url, { signal }),
    enabled: !!data?.url && enabled,
    refetchOnWindowFocus: false,
    select: (data) => processVCard(data.data),
  });
};

const processVCard = (data: string): VCardData => {
  const parts = data
    .split("\r\n")
    .reduce((obj: Record<string, string>, line) => {
      const [prop, value] = line.split(":");
      return { ...obj, [prop]: value };
    }, {});
  const VCard: VCardData = {};
  Object.keys(parts).forEach((prop) => {
    if (prop.startsWith("TEL")) {
      VCard.phone = parts[prop];
    } else if (prop === "FN") {
      VCard.name = parts[prop];
    }
  });
  return VCard;
};

export default useVCardQuery;
