import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { alertsSelector, toggleNotifications } from "store/slices/alerts";

import FilterCheckbox from "../AlertsFilters/FilterCheckbox";
import "./AlertsOptions.css";

const AlertsOptions = () => {
  const { notificationsEnabled } = useSelector(alertsSelector);
  const dispatch = useDispatch();

  const notificationsTogglable =
    "Notification" in window && Notification.permission !== "denied";

  useEffect(() => {
    if (notificationsEnabled) {
      if (!notificationsTogglable) {
        // Either the browser does not support notifications
        // OR notifications have been disabled externally (through the browser or OS).
        // TODO: add instructions in case the user wants to enable them again?
        dispatch(toggleNotifications());
        return;
      }
      Notification.requestPermission().then((result) => {
        if (result !== "granted") {
          dispatch(toggleNotifications());
          return;
        }
      });
    }
  }, [dispatch, notificationsTogglable, notificationsEnabled]);

  return (
    <div className="AlertsOptions">
      <FilterCheckbox
        checked={notificationsEnabled}
        onClick={() => dispatch(toggleNotifications())}
        label="Recibir notificaciones"
        disabled={!notificationsTogglable}
      />
    </div>
  );
};

export default AlertsOptions;
