import { captureException } from "@sentry/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Emoji } from "components/Feedback/InteractionDrawer/InteractionNotes/InteractionNote/InteractionNoteIcon/emojis";

import { InteractionId } from "../types/domain";
import { CreateReactionAPIResponse } from "../types/responses";
import { API_ROOT, post } from "./utils";

type Note = {
  text: string;
  emoji: Emoji;
};

const useAddNoteMutation = (interactionId: InteractionId) => {
  const queryClient = useQueryClient();
  const url = `${API_ROOT}/reactions/${interactionId.serviceId}/${interactionId.patientId}`;
  return useMutation<CreateReactionAPIResponse, unknown, Note>({
    mutationKey: [
      "notes",
      interactionId.serviceId,
      interactionId.patientId,
      interactionId.start,
    ],
    mutationFn: async ({ text, emoji }) => {
      const { data } = await post<CreateReactionAPIResponse>(url, {
        start: interactionId.start.toISOString(),
        text,
        emoji,
      });
      return data;
    },
    onError: (err, _newNote, _context) => {
      captureException(err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notes", interactionId] });
    },
  });
};

export default useAddNoteMutation;
