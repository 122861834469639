import { Icon } from "@iconify/react";
import classNames from "classnames";

import "./Step.css";

export interface StepProps {
  disabled: boolean;
  index: number;
  label: string;
  state: "selected" | "future" | "completed";
  onClick: () => void;
}

const Step = ({ disabled, index, label, state, onClick }: StepProps) => {
  return (
    <div
      className={classNames("Step__container", {
        "Step__container--disabled": disabled,
      })}
      onClick={disabled ? undefined : onClick}
    >
      <div
        className={classNames("Step__index", {
          "Step__index--disabled": disabled,
          "Step__index--completed": !disabled && state !== "selected",
          "Step__index--selected": !disabled && state === "selected",
        })}
      >
        {state === "completed" ? <Icon icon="uil:check" /> : index}
      </div>
      <div
        className={classNames("Step__label", {
          "Step__label--disabled": disabled,
          "Step__label--completed": !disabled && state !== "selected",
          "Step__label--selected": !disabled && state === "selected",
        })}
      >
        {label}
      </div>
    </div>
  );
};

export default Step;
