import { InlineIcon } from "@iconify/react";

import "./ErrorBoundaryFallback.css";

const ErrorBoundaryFallback = () => {
  return (
    <div className="ErrorBoundaryFallback">
      <div className="ErrorBoundaryFallback__contenedor">
        <h1>Lo sentimos, algo salió mal 😌</h1>
        <p>Presiona este botón para recargar la página</p>
        <button
          className="ErrorBoundaryFallback__boton_recargar"
          onClick={() => window.location.reload()}
        >
          <InlineIcon icon="mdi:refresh" />
        </button>
        <p>
          Si el problema persiste, contáctate con CERO para que te brindemos
          asistencia
        </p>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;
