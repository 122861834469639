import ReactModal from "react-modal";

import "./Modal.css";

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onRequestClose?: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => void;
}

const Modal = ({ children, onRequestClose, isOpen = true }: ModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="Modal__modal"
      overlayClassName="Modal__overlay"
    >
      <div className="Modal__content">{children}</div>
    </ReactModal>
  );
};

export default Modal;
