import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

interface ScramblerState {
  scrambled: boolean;
  terminos: [string, string][];
}

const slice = createSlice({
  name: "scrambler",
  initialState: {
    scrambled: false,
    terminos: [],
  } as ScramblerState,
  reducers: {
    escondeDatosSensibles(state, action: PayloadAction<boolean>) {
      state.scrambled = action.payload;
    },
    agregaTerminos(state, action: PayloadAction<[string, string][]>) {
      const terminos = action.payload;
      if (Array.isArray(terminos[0])) {
        terminos.forEach((t) => {
          if (!state.terminos.some((st) => st[0] === t[0])) {
            state.terminos.push(t);
          }
        });
      }
    },
    remueveTerminos(state, action: PayloadAction<[string, string][]>) {
      const terminos = action.payload;
      if (Array.isArray(terminos[0])) {
        terminos.forEach((t) => {
          state.terminos = state.terminos.filter((st) => st[0] !== t[0]);
        });
      }
    },
  },
});

export const { escondeDatosSensibles, agregaTerminos, remueveTerminos } =
  slice.actions;
export const scramblerSelector = (state: RootState) => state.scrambler;

export default slice.reducer;
