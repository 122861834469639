import { useMutation } from "@tanstack/react-query";

import { campaignsApi } from "feedback-api";
import type {
  CreateCampaignRequest,
  CreateCampaignResponse,
} from "feedback-api/generated";

const useCreateCampaignMutation = () => {
  return useMutation<CreateCampaignResponse, unknown, CreateCampaignRequest>({
    mutationFn: async ({ records, mapping, config }) => {
      const { data } = await campaignsApi.createCampaignV4CampaignsPost({
        records,
        mapping: Object.fromEntries(
          Object.entries(mapping).map(([k, v]) => [k, v.filter((c) => c)]),
        ),
        config,
      });
      return data;
    },
  });
};

export default useCreateCampaignMutation;
