import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

const enviadorSlice = createSlice({
  name: "enviador",
  initialState: {
    activo: false,
  },
  reducers: {
    activaEnviador(state) {
      state.activo = true;
    },
    desactivaEnviador(state) {
      state.activo = false;
    },
  },
});

export const { activaEnviador, desactivaEnviador } = enviadorSlice.actions;
export const enviadorSelector = (state: RootState) => state.enviador;

export default enviadorSlice.reducer;
