import classNames from "classnames";

import "./Paginator.css";

interface PaginatorProps {
  steps: number;
  current: number;
  setCurrent?: (current: number) => void;
}

const Paginator = ({ steps, current, setCurrent }: PaginatorProps) => {
  return (
    <div className="Paginator">
      {Array.from({ length: steps }, (_, index) => (
        <button
          key={index}
          type="button"
          className={classNames("Paginator__marker", {
            "Paginator__marker--active": index + 1 === current,
          })}
          onClick={() => setCurrent?.(index + 1)}
        />
      ))}
    </div>
  );
};

export default Paginator;
