import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";

import { ESQUEMA_OSCURO, opcionesSelector } from "store/slices/opciones";

import "./ModalAyuda.css";

interface ModalAyudaProps {
  cerrar: () => void;
}

const ModalAyuda = ({ cerrar }: ModalAyudaProps) => {
  const { esquema } = useSelector(opcionesSelector);
  const filename =
    esquema === ESQUEMA_OSCURO ? "demo_filtros_dark" : "demo_filtros";

  return (
    <div className="ModalAyuda">
      <video
        className="ModalAyuda__gif"
        key={esquema}
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={`videos/${filename}.webm`} type="video/webm" />
        <source src={`videos/${filename}.mp4`} type="video/mp4" />
      </video>
      <div className="ModalAyuda__bajada">
        <h2 className="ModalAyuda__titulo">Cómo usar los filtros</h2>
        <ul className="ModalAyuda__explicación">
          <li>Filtra por columna haciendo click en su encabezado.</li>
          <li>Puedes combinar dos filtros arrastrando uno sobre el otro.</li>
        </ul>
      </div>
      <button
        type="button"
        className="ModalAyuda__boton_cerrar"
        title="Cerrar ayuda"
        onClick={cerrar}
      >
        <Icon icon="mdi:close" />
      </button>
    </div>
  );
};

export default ModalAyuda;
