import { Icon } from "@iconify/react";
import { useMemo } from "react";

import useCampaignLimitsQuery from "api/hooks/useCampaignLimitsQuery";
import Button from "components/atoms/Button";

import FileDropzone from "./FileDropzone";
import "./UploadSpreadsheetStep.css";

interface UploadSpreadsheetStepProps {
  isLoading: boolean;
  handleAccepted: (files: File[]) => Promise<void>;
  selectedFile?: File;
  error?: string;
  onNext: () => void;
}

const UploadSpreadSheetStep = ({
  isLoading,
  handleAccepted,
  selectedFile,
  error,
  onNext,
}: UploadSpreadsheetStepProps) => {
  const { data: limits, isPending, isError } = useCampaignLimitsQuery();

  const maxRows = useMemo(() => {
    if (isPending) {
      return NaN;
    }
    if (isError) {
      return Infinity;
    }
    return Math.min(
      limits.max_campaign_size,
      limits.max_monthly_interactions - limits.monthly_interactions,
      limits.max_lifetime_interactions - limits.lifetime_interactions,
    );
  }, [isPending, isError, limits]);

  return (
    <div className="UploadSpreadsheetStep">
      <div className="UploadSpreadsheetStep__section">
        <h3 className="UploadSpreadsheetStep__title">Subir planilla</h3>
        <p className="UploadSpreadsheetStep__copy">
          Selecciona tu archivo de hoja de cálculo y cárgalo aquí. Este paso nos
          permitirá procesar la información de manera eficiente para que puedas
          crear y gestionar tus campañas. Asegúrate de que tu archivo{" "}
          {Number.isFinite(maxRows) && (
            <>
              <strong>no exceda los {maxRows} pacientes</strong> y{" "}
            </>
          )}
          sea uno de los siguientes formatos:{" "}
          <span className="UploadSpreadsheetStep__extension-tag">xlsx</span>
        </p>
      </div>
      <div className="UploadSpreadsheetStep__row">
        <Icon
          className="UploadSpreadsheetStep__icon UploadSpreadsheetStep__icon--help"
          icon="uil:question-circle"
        />
        <p className="UploadSpreadsheetStep__copy">
          <strong>¿Necesitas ayuda?</strong>{" "}
          <a href="/plantilla_cargas.xlsx">Descarga nuestra plantilla</a> de
          hoja de cálculo para asegurarte de que tu archivo esté formateado
          correctamente.
        </p>
      </div>
      <div className="UploadSpreadsheetStep__section">
        {selectedFile && (
          <div className="UploadSpreadsheetStep__callout-success">
            <Icon
              className="UploadSpreadsheetStep__icon"
              icon="uil:check-circle"
            />
            ¡Listo! El archivo se ha cargado correctamente. Puedes continuar al
            siguiente paso.
          </div>
        )}
        {error && (
          <div className="UploadSpreadsheetStep__callout-danger">
            <Icon
              className="UploadSpreadsheetStep__icon"
              icon="uil:exclamation-triangle"
            />
            {error}
          </div>
        )}
        <FileDropzone
          selectedFile={selectedFile}
          accept={{
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlsx"],
          }}
          onDropAccepted={handleAccepted}
          isLoading={isLoading}
        />
      </div>
      <Button onClick={onNext} disabled={!selectedFile || isLoading}>
        Asignar campos
      </Button>
    </div>
  );
};

export default UploadSpreadSheetStep;
