import { captureException } from "@sentry/react";
import classNames from "classnames";
import { CSSProperties } from "react";

import useCampaignLimitsQuery from "api/hooks/useCampaignLimitsQuery";
import Loader from "components/Loader";
import Skeleton from "components/Skeleton";
import { addToast } from "store/slices/toasts";

import "./CampaignLimits.css";

const CampaignLimits = () => {
  const { data, error, isPending, isError } = useCampaignLimitsQuery();

  if (isPending) {
    return (
      <div className="CampaignLimits">
        <Skeleton />
        <Loader />
      </div>
    );
  }
  if (isError) {
    captureException(error);
    addToast({
      message: "No se pudieron cargar los límites.",
      type: "error",
      durationMs: 10_000,
    });
    return null;
  }
  return (
    <div className="CampaignLimits">
      Iniciadas
      <CampaignMetric
        current={data.monthly_interactions}
        max={data.max_monthly_interactions}
        label="este mes"
      />
      <CampaignMetric
        current={data.lifetime_interactions}
        max={data.max_lifetime_interactions}
        label="total"
      />
    </div>
  );
};

interface CampaignMetricProps {
  current: number;
  max: number;
  label: string;
}

const CampaignMetric = ({ current, max, label }: CampaignMetricProps) => {
  return (
    <div
      className={classNames("CampaignMetric", {
        "CampaignMetric--danger": current / max >= 0.95,
        "CampaignMetric--warn": current / max >= 0.8,
      })}
    >
      <div
        className="CampaignMetric__gauge"
        style={
          {
            "--percentage": `${(100 * current) / max}`,
          } as CSSProperties
        }
      >
        <span className="CampaignMetric__percentage">
          {((100 * current) / max).toFixed(0)}
        </span>
        %
      </div>
      <span>
        <span className="CampaignMetric__current">{current}</span>
        <span className="CampaignMetric__max">/{max}</span>
      </span>
      <span className="CampaignMetric__label">{label}</span>
    </div>
  );
};

export default CampaignLimits;
