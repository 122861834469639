import classNames from "classnames";

import "./ModalExportacionAvanzada.css";

interface ModalExportacionAvanzadaProps {
  email: string;
  visible: boolean;
  ocultar: () => void;
}

const ModalExportacionAvanzada = ({
  email,
  visible,
  ocultar,
}: ModalExportacionAvanzadaProps) => {
  return (
    <div
      className={classNames({
        ModalExportacionAvanzada: true,
        "ModalExportacionAvanzada--visible": visible,
      })}
    >
      <div className="ModalExportacionAvanzada__contenido">
        <h2>¡Éxito!</h2>
        <p>
          Enviaremos tu reporte a<br />
          <strong>{email}</strong>
          <br />
          dentro de los próximos minutos.
        </p>
        <button className="ModalExportacionAvanzada__boton" onClick={ocultar}>
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default ModalExportacionAvanzada;
