import { useQuery } from "@tanstack/react-query";

import { campaignsApi } from "feedback-api";
import type { Campaign } from "feedback-api/generated";

const useCampaignsQuery = () => {
  return useQuery<Campaign[], unknown>({
    queryKey: ["campaigns"],
    queryFn: async ({ signal }) => {
      const {
        data: { campaigns },
      } = await campaignsApi.getCampaignsV4CampaignsGet({ signal });
      return campaigns;
    },
    refetchInterval: 300_000,
  });
};

export default useCampaignsQuery;
