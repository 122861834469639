import { InlineIcon } from "@iconify/react";
import { captureException } from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import * as _ from "lodash-es";
import { parse as parseCSV } from "papaparse";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { consultarMapping, crearEncuestas } from "api/endpoints";
import { InputHeader, Log } from "api/types/responses";
import { desactivaEnviador, enviadorSelector } from "store/slices/enviador";
import { ESQUEMA_OSCURO, opcionesSelector } from "store/slices/opciones";
import { guardaRangoFechas } from "store/slices/respuestas";
import { normalizar } from "utils/normalize";

import "./EnviadorRespuestas.css";

const obtenerTipoInput = (header: InputHeader) => {
  switch (header.type) {
    case "text":
      return "text";
    case "datetime":
      if (header.display_name.toLowerCase().startsWith("hora")) {
        return "time";
      }
      return "date";
    default:
      return header.type;
  }
};

const formatearCampo = (header: InputHeader, valor: string) => {
  if (header.display_name === "FONO") {
    return valor.replace(/[^0-9]/g, "");
  } else if (obtenerTipoInput(header) === "date") {
    return format(parseISO(valor), "yyyy-MM-dd");
  }
  return valor;
};

const ENVIADOR_ESTADO_PENDIENTE = "ENVIADOR_ESTADO_PENDIENTE" as const;
const ENVIADOR_ESTADO_ENVIADO = "ENVIADOR_ESTADO_ENVIADO" as const;

type Fila = {
  datos: string[];
  logs?: Log[];
  estado: typeof ENVIADOR_ESTADO_PENDIENTE | typeof ENVIADOR_ESTADO_ENVIADO;
};

interface EnviadorRespuestasProps {
  idEncuesta: number;
}

const EnviadorRespuestas = ({ idEncuesta }: EnviadorRespuestasProps) => {
  const { activo } = useSelector(enviadorSelector);
  const { esquema } = useSelector(opcionesSelector);
  const dispatch = useDispatch();
  const [filas, setFilas] = useState<Fila[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isPending, data } = useQuery({
    queryKey: [`inputHeaders-${idEncuesta}`],
    queryFn: consultarMapping(idEncuesta),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
  // TODO: move this to api/hooks
  const {
    mutate,
    isPending: enviando,
    isError,
    error,
  } = useMutation({
    mutationFn: crearEncuestas,
    onSuccess: (data) => {
      const logsRespuesta = data.data.data.logs;
      const conteoPacientesAContactar = filas.length;
      const conteoPacientesNoContactados = logsRespuesta.filter((r) =>
        r.logs.some((l) => l.type === "ERROR"),
      ).length;
      setFilas(
        filas.map((f, i) => {
          const logs = logsRespuesta.find((l) => l.row_index === i)?.logs;
          if (logs) {
            if (logs.some((l) => l.type === "ERROR")) {
              return { ...f, logs, estado: ENVIADOR_ESTADO_PENDIENTE };
            } else {
              return { ...f, logs, estado: ENVIADOR_ESTADO_ENVIADO };
            }
          }
          return _.omit({ ...f, estado: ENVIADOR_ESTADO_ENVIADO }, ["logs"]);
        }),
      );
      if (conteoPacientesAContactar > conteoPacientesNoContactados) {
        dispatch(guardaRangoFechas([Date.now(), Date.now()]));
      }
    },
    onError: (error) => {
      captureException(error);
    },
  });

  useEffect(() => setFilas([]), [idEncuesta]);

  const container = document.getElementById("modal-enviador-respuestas");

  if (!activo || !container) {
    return null;
  }

  const enviarEncuestas = (e: React.FormEvent) => {
    e.preventDefault();
    const ahora = format(Date.now(), "yyyy-MM-dd HH:mm:ss");
    const datos = filas.map((f) =>
      headersSinConsultaConfirmacion.reduce(
        (obj, h, i) => ({
          ...obj,
          [h.display_name]: formatearCampo(h, f.datos[i]),
        }),
        { "Consulta confirmación": ahora },
      ),
    );
    mutate({ idEncuesta, datos });
  };

  const headersSinConsultaConfirmacion = [
    {
      name: "FONO",
      type: "text",
      display_name: "FONO",
      required: true,
    } as const,
    ...(data?.data.data.filter((h) => h.type !== "true") ?? []),
  ];

  const procesarArchivo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !fileInputRef.current) {
      return;
    }
    parseCSV<Record<string, string>>(file, {
      header: true,
      transformHeader: (h) => {
        if (normalizar(h) === normalizar("telefono")) {
          return "fono";
        }
        return normalizar(h);
      },
      complete: ({ data }) => {
        setFilas((filas) => [
          ...filas,
          ...data
            .map((fila) => {
              const datos = headersSinConsultaConfirmacion.map(
                (h) => fila[normalizar(h.display_name)] || "",
              );
              return _.some(datos)
                ? { datos, estado: ENVIADOR_ESTADO_PENDIENTE }
                : null;
            })
            .filter((x): x is Exclude<typeof x, null> => x !== null),
        ]);
      },
    });
    fileInputRef.current.value = "";
  };

  const abrirDialogoArchivo = () => fileInputRef.current?.click();
  const agregarFilaVacía = () =>
    setFilas([
      ...filas,
      {
        datos: Array(headersSinConsultaConfirmacion.length).fill(""),
        estado: ENVIADOR_ESTADO_PENDIENTE,
      },
    ]);
  const filasPendientes = filas.filter(
    (f) => f.estado === ENVIADOR_ESTADO_PENDIENTE,
  );
  const textoBotonContactar = filasPendientes.length
    ? `Contactar ${filasPendientes.length} paciente${
        filasPendientes.length > 1 ? "s" : ""
      }`
    : `No hay pacientes por contactar`;

  return createPortal(
    <div
      className={classNames({
        EnviadorRespuestas: true,
        EnviadorRespuestas__oscuro: esquema === ESQUEMA_OSCURO,
      })}
      onClick={() => dispatch(desactivaEnviador())}
    >
      {isError && `Error: ${error}`}
      {isPending ? (
        <p>Cargando...</p>
      ) : (
        <div
          className="EnviadorRespuestas__contenedor"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="EnviadorRespuestas__boton_cerrar"
            onClick={() => dispatch(desactivaEnviador())}
            title="Cerrar"
          >
            <InlineIcon icon="mdi:close" />
          </button>
          <div className="EnviadorRespuestas__superior">
            <h1 className="EnviadorRespuestas__titulo">Contactar pacientes</h1>
            <div className="EnviadorRespuestas__superior_acciones">
              <button
                className="EnviadorRespuestas__boton_accion"
                onClick={agregarFilaVacía}
              >
                <InlineIcon icon="mdi:table-row-add-after" /> Agregar paciente
              </button>
              <button
                className="EnviadorRespuestas__boton_accion"
                onClick={abrirDialogoArchivo}
              >
                <InlineIcon icon="mdi:table-import" /> Cargar desde archivo
              </button>
              <button
                className="EnviadorRespuestas__boton_accion"
                onClick={() => setFilas([])}
              >
                <InlineIcon icon="mdi:table-off" /> Limpiar tabla
              </button>
              <input
                ref={fileInputRef}
                style={{ display: "none" }}
                type="file"
                accept=".csv"
                onChange={procesarArchivo}
              />
            </div>
          </div>
          <form
            onSubmit={enviarEncuestas}
            className="EnviadorRespuestas__formulario"
          >
            <div className="EnviadorRespuestas__contenedor_tabla">
              <table className="EnviadorRespuestas__tabla">
                <thead>
                  <tr>
                    <th></th>
                    {headersSinConsultaConfirmacion.map(({ display_name }) => (
                      <th key={`header-enviador-${display_name}`}>
                        {display_name}
                      </th>
                    ))}
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filas.length > 0 ? (
                    filas.map((fila, i) => (
                      <tr
                        key={`fila-enviador-${i}`}
                        className={classNames({
                          "EnviadorRespuestas__fila--enviada":
                            fila.estado === ENVIADOR_ESTADO_ENVIADO,
                        })}
                      >
                        <td>
                          <span className="EnviadorRespuestas__indice_fila">
                            {fila.logs && (
                              <>
                                {fila.logs.some((l) => l.type === "ERROR")
                                  ? "🔴"
                                  : "🟢"}
                                <div className="EnviadorRespuestas__contenedor_logs">
                                  {fila.logs.map((log, i) => (
                                    <div key={`log-${i}`}>
                                      {log.type === "ERROR" ? "🔴" : "🟣"}{" "}
                                      {log.log}
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                            {i + 1}
                          </span>
                        </td>
                        {fila.datos.map((v, j) => (
                          <td key={`campo-enviador-${i}-${j}`}>
                            <input
                              className={classNames({
                                EnviadorRespuestas__input: true,
                                "EnviadorRespuestas__input--enviado":
                                  fila.estado === ENVIADOR_ESTADO_ENVIADO,
                              })}
                              type={obtenerTipoInput(
                                headersSinConsultaConfirmacion[j],
                              )}
                              onChange={(e) => {
                                setFilas([
                                  ...filas.slice(0, i),
                                  {
                                    ...filas[i],
                                    datos: filas[i].datos.map((v, k) =>
                                      k === j ? e.target.value : v,
                                    ),
                                    estado: ENVIADOR_ESTADO_PENDIENTE,
                                    logs: undefined,
                                  },
                                  ...filas.slice(i + 1),
                                ]);
                              }}
                              required={
                                headersSinConsultaConfirmacion[j].required
                              }
                              value={v}
                            />
                          </td>
                        ))}
                        <td>
                          <button
                            className="Enviador__boton_accion_fila"
                            type="button"
                            onClick={() =>
                              setFilas((filas) =>
                                filas.filter((_, k) => k !== i),
                              )
                            }
                          >
                            Borrar fila
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="EnviadorRespuestas__mensaje_sin_datos"
                        colSpan={(data?.data.data.length ?? 0) + 2}
                      >
                        Puedes agregar pacientes desde un{" "}
                        <span
                          className="EnviadorRespuestas__link"
                          onClick={abrirDialogoArchivo}
                        >
                          archivo CSV
                        </span>{" "}
                        o{" "}
                        <span
                          className="EnviadorRespuestas__link"
                          onClick={agregarFilaVacía}
                        >
                          manualmente
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="EnviadorRespuestas__contenedor_acciones">
              <button
                className="EnviadorRespuestas__boton_enviar"
                disabled={!filasPendientes.length}
                type="submit"
              >
                <InlineIcon icon="mdi:send" />{" "}
                {enviando ? "Contactando..." : textoBotonContactar}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>,
    container,
  );
};

export default EnviadorRespuestas;
