import { Icon } from "@iconify/react";
import classNames from "classnames";
import * as _ from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MetricFilter, MetricFilterKindEnum } from "feedback-api";
import {
  addFilter,
  dashboardSelector,
  removeFilter,
} from "store/slices/dashboard";

import "./MultiSelect.css";
import MultiSelectList from "./MultiSelectList";
import MultiSelectPill from "./MultiSelectPill";

interface MultiSelectProps {
  property: MetricFilter;
}

const MultiSelect = ({ property }: MultiSelectProps) => {
  const [selectorModalVisible, setSelectorModalVisible] = useState(false);
  const { filters } = useSelector(dashboardSelector);
  const [localTextFilter, setLocalTextFilter] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectorModalVisible) {
      inputRef.current?.focus();
    }
  }, [selectorModalVisible]);

  if (property.kind === MetricFilterKindEnum.Freeform) {
    return null;
  }

  const propertyFilters =
    filters === "EVERYTHING"
      ? []
      : filters.filter((f) => f.propertyId === property.id);

  const filteredValues = property.values.filter(
    (v) =>
      !propertyFilters.some((f) => f.value === v) &&
      v
        .toLocaleLowerCase("de-DE")
        .includes(localTextFilter.toLocaleLowerCase("de-ES")),
  );

  return (
    <div className="MultiSelect">
      <div className="MultiSelect__field">
        <div className="MultiSelect__label">
          <Icon icon="mdi:filter" />
          {property.label}
        </div>
        <div
          className="MultiSelect__input_container"
          onClick={() => {
            setSelectorModalVisible(!selectorModalVisible);
          }}
        >
          {propertyFilters.map(({ propertyId, value }) => (
            <MultiSelectPill
              key={`MultiSelectPill-${value}`}
              label={value}
              onRemove={() => dispatch(removeFilter({ propertyId, value }))}
            />
          ))}
          <input
            className={classNames({
              MultiSelect__input: true,
              "MultiSelect__input--hidden":
                !selectorModalVisible && propertyFilters.length > 0,
            })}
            placeholder={
              _.isEmpty(propertyFilters) ? `Buscar ${property.label}` : ""
            }
            type="text"
            ref={inputRef}
            value={localTextFilter}
            onChange={(e) => setLocalTextFilter(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                setLocalTextFilter("");
                if (_.isEmpty(filteredValues)) {
                  return;
                }
                dispatch(
                  addFilter({
                    propertyId: property.id,
                    value: filteredValues[0],
                  }),
                );
              }
            }}
          />
        </div>
      </div>
      <MultiSelectList
        propertyId={property.id}
        values={filteredValues}
        onOutsideClick={() => setSelectorModalVisible(false)}
        onAddFilter={({ value }: { value: string }) => {
          dispatch(addFilter({ propertyId: property.id, value }));
          setLocalTextFilter("");
          inputRef.current?.focus();
        }}
        onRemoveFilter={({ value }: { value: string }) => {
          dispatch(removeFilter({ propertyId: property.id, value }));
        }}
        visible={selectorModalVisible}
      />
    </div>
  );
};

export default MultiSelect;
