import { Icon } from "@iconify/react";
import classNames from "classnames";
import { useEffect, useState } from "react";

import "./Collapsable.css";

interface CollapsableProps {
  children: React.ReactNode;
  title: React.ReactNode;
  isOpen?: boolean;
  setOpen?: (isOpen: boolean) => void;
  isError?: boolean;
  rhs?: React.ReactNode;
  variant?: "default" | "flat";
}

const Collapsable = ({
  children,
  title,
  rhs,
  isOpen = false,
  isError = false,
  setOpen,
  variant = "default",
}: CollapsableProps) => {
  const [isOpenInternal, setIsOpenInternal] = useState(isOpen);

  useEffect(() => {
    setIsOpenInternal(isOpen);
  }, [isOpen]);

  const handleOnClick = () => {
    setIsOpenInternal(!isOpenInternal);
    setOpen?.(!isOpenInternal);
  };

  return (
    <div
      className={classNames("Collapsable", {
        "Collapsable--open": isOpenInternal,
        "Collapsable--error": isError,
        "Collapsable--flat": variant === "flat",
      })}
    >
      <button
        className="Collapsable__header"
        type="button"
        onClick={handleOnClick}
      >
        <div className="Collapsable__header_lhs">
          <div className="Collapsable__opener">
            <Icon
              className={classNames("Collapsable__opener_icon", {
                "Collapsable__opener_icon--open": isOpenInternal,
              })}
              icon="uil:angle-down"
            />
          </div>
          <div className="Collapsable__title">{title}</div>
        </div>
        {rhs && <div>{rhs}</div>}
      </button>
      <div className="Collapsable__toggle">
        <div className="Collapsable__content">{children}</div>
      </div>
    </div>
  );
};

export default Collapsable;
