import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { dashboardsApi, MetricFilter } from "feedback-api";
import { loginSelector } from "store/slices/login";

const useMetricsFiltersQuery = () => {
  const { idCliente } = useSelector(loginSelector);

  return useQuery<MetricFilter[], unknown>({
    queryKey: ["metrics-filters", idCliente],
    queryFn: async ({ signal }) => {
      const { data } = await dashboardsApi.getFiltersV4DashboardsFiltersGet({
        signal,
      });
      return data.filters;
    },
  });
};

export default useMetricsFiltersQuery;
