import configuration from "./configuration";
import {
  CampaignsApiFactory,
  DashboardsApiFactory,
  DebtorsApiFactory,
  SuspensionsApiFactory,
} from "./generated";

export type {
  CampaignConfig,
  GetConfigFieldsResponse,
  MessagePreview,
  MetricCount,
  MetricFilter,
  PostSpreadsheetResponse,
  Campaign,
  CandidateAppointment,
  CreateSuspensionRequest,
  CreateSuspensionResponse,
  ErrorResponse,
} from "./generated";

export { CampaignStatus, ErrorCode, MetricFilterKindEnum } from "./generated";

export const campaignsApi = CampaignsApiFactory(configuration);
export const dashboardsApi = DashboardsApiFactory(configuration);
export const debtorsApi = DebtorsApiFactory(configuration);
export const suspensionsApi = SuspensionsApiFactory(configuration);
