import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ChatPostMessageResponse } from "../types/slack";

type SlackPostMessageVars = {
  channel: string;
  ts?: string;
} & ({ text: string } | { blocks: Block[] });

type Block = SectionBlock;

type SectionBlock = {
  type: "section";
  block_id?: string;
  accessories?: unknown;
} & ({ text: TextObject } | { fields: TextObject[] });

type TextObject = {
  type: "plain_text" | "mrkdwn";
  text: string;
  emoji?: boolean;
  verbatim?: boolean;
};

const useSlackPostMessageMutation = () => {
  return useMutation<ChatPostMessageResponse, unknown, SlackPostMessageVars>({
    mutationFn: async (vars) => {
      return await postMessage(vars);
    },
  });
};

export const postMessage = async ({ ts, ...vars }: SlackPostMessageVars) => {
  const formData = {
    token: process.env.REACT_APP_OAUTH2_TOKEN!,
    ...vars,
    blocks: "blocks" in vars ? JSON.stringify(vars.blocks) : undefined,
    unfurl_links: false,
    thread_ts: ts,
  };
  const { data } = await axios.postForm<ChatPostMessageResponse>(
    "https://slack.com/api/chat.postMessage",
    formData,
  );
  return data;
};

export default useSlackPostMessageMutation;
