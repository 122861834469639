import { Icon } from "@iconify/react";
import { format, parseISO } from "date-fns";
import { Fragment } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";

import useInteractionsHistoryQuery from "api/hooks/useInteractionsHistoryQuery";
import { InteractionHistoryRecord } from "api/types/responses";
import Scrambler from "components/Scrambler";
import { formatearCampoRespuestas } from "helpers/respuestas";

import "./HistorialContactosModal.css";

interface HistorialContactosModalProps {
  close: () => void;
}

// modal-historial-contactos
const HistorialContactosModal = ({ close }: HistorialContactosModalProps) => {
  const params = useParams();
  const { data, isSuccess } = useInteractionsHistoryQuery({
    serviceId: Number(params.serviceId),
    patientId: Number(params.patientId),
  });

  if (!isSuccess) {
    // TODO: handle errors separately
    return null;
  }

  return createPortal(
    <div
      className="HistorialContactosModal__backdrop"
      onClick={(_e) => close()}
    >
      <div
        className="HistorialContactosModal__paper"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="HistorialContactosModal__close"
          onClick={(_e) => close()}
        >
          <Icon icon="mdi:close" />
        </button>
        <div className="HistorialContactosModal__content">
          <div className="HistorialContactosModal__title">
            Historial de Contactos
          </div>
          <div className="HistorialContactosModal__grid">
            <div className="HistorialContactosModal__grid__header">
              <div>Fecha</div>
              <div>Canal</div>
              <div>Teléfono</div>
              <div>Estado</div>
            </div>
            <div className="HistorialContactosModal__grid__body">
              {data.map((record: InteractionHistoryRecord) => {
                const date = parseISO(`${record.timestamp}Z`);

                return (
                  <Fragment key={record.timestamp}>
                    <div>{format(date, "yyyy-MM-dd HH:mm")}</div>
                    <div>
                      <Icon
                        className="HistorialContactosModal__channel_icon"
                        icon={`mdi:${record.channel}`}
                      />
                    </div>
                    <div>
                      <Scrambler tipo="telefono" propagar={false}>
                        {formatearCampoRespuestas(record.phone, "phone")}
                      </Scrambler>
                    </div>
                    <div>
                      {formatearCampoRespuestas(
                        record.status,
                        "contact-status",
                      )}
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector("#modal-historial-contactos") as HTMLElement,
  );
};

export default HistorialContactosModal;
