import ReactJson from "@microlink/react-json-view";
import { useState, useEffect, useCallback, useMemo } from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { chat as chatAPI } from "api/endpoints";
import { ChatAPIConversation, ChatAPIResponse } from "api/types/responses";
import Error403 from "components/Feedback/Error403";
import { esCero } from "helpers/permisos";
import useAnalytics from "hooks/useAnalytics";
import useIsLabeler from "hooks/useIsLabeler";
import { ceroSelector } from "store/slices/cero";
import { loginSelector } from "store/slices/login";
import { respuestasSelector } from "store/slices/respuestas";

import AccionesChat from "./AccionesChat";
import CelularWhatsapp from "./CelularWhatsapp";
import "./Chat.css";
import DatosChat from "./DatosChat";
import NotasChat from "./NotasChat";
import RespuestasChat from "./RespuestasChat";

const msHabilitacionReporteSlack = 0;

const Chat = () => {
  const [conversaciones, setConversaciones] = useState<ChatAPIConversation[]>();
  const [phoneHistories, setPhoneHistories] = useState<
    {
      phone: string;
      timestamp: string;
    }[][]
  >();
  const [telefono, setTelefono] = useState<string>("");
  const [indiceConversacion, setIndiceConversacion] = useState(0);
  const [cargando, setCargando] = useState(false);
  const [jsonChat, setJsonChat] = useState<ChatAPIResponse>();
  const [error403, setError403] = useState(false);
  const [accionesHabilitadas, setAccionesHabilitadas] = useState(false);
  const { serviceId, patientId } = useParams();
  const { cuenta } = useSelector(loginSelector);
  const { debugging } = useSelector(ceroSelector);
  const { indiceRespuestaSeleccionada } = useSelector(respuestasSelector);
  const track = useAnalytics();
  const isLabeler = useIsLabeler();

  const actualizarMensajes = useCallback(() => {
    if (!serviceId || !patientId) {
      return;
    }
    setConversaciones(undefined);
    setCargando(true);
    chatAPI(serviceId, patientId)
      .then(({ data }) => {
        const {
          data: { conversations, phone_histories, user },
        } = data;
        setTelefono(user ? user.phone : "");
        setConversaciones(conversations);
        setPhoneHistories(phone_histories);
        setIndiceConversacion(conversations.length - 1);
        setJsonChat(data);
      })
      .catch(() => setError403(true))
      .finally(() => setCargando(false));
  }, [serviceId, patientId]);

  useEffect(() => {
    actualizarMensajes();
  }, [actualizarMensajes]);

  useEffect(() => {
    setAccionesHabilitadas(false);
    const to = setTimeout(
      () => setAccionesHabilitadas(true),
      msHabilitacionReporteSlack,
    );
    return () => clearTimeout(to);
  }, [indiceRespuestaSeleccionada]);

  useEffect(() => track("Feedback", "Chat", "index"), [track]);

  const link = useMemo(() => {
    if (!conversaciones?.[indiceConversacion]) {
      return;
    }
    const contexto = conversaciones[indiceConversacion].context;
    const tipos = ["Dentalink", "Medilink"];
    for (const tipo of tipos) {
      const link = contexto?.find((meta) => meta.title === tipo);
      if (link) {
        return {
          tipo,
          url: link.value,
        };
      }
    }
  }, [conversaciones, indiceConversacion]);

  if (error403) {
    return <Error403 mensaje="No puedes ver este chat." />;
  }

  return (
    <div className="Chat">
      {esCero(cuenta) && debugging && jsonChat?.data && (
        <>
          <Draggable>
            <div
              className="Chat__contenedor_json Chat__contenedor_json--primero"
              onClick={(e) => e.stopPropagation()}
            >
              <h1 className="Chat__titulo_contenedor_json">context</h1>
              <div className="Chat__contenedor_scroll_json">
                <ReactJson
                  src={jsonChat.data._context}
                  theme="monokai"
                  sortKeys={true}
                  collapsed
                />
              </div>
            </div>
          </Draggable>
          <Draggable>
            <div
              className="Chat__contenedor_json Chat__contenedor_json--segundo"
              onClick={(e) => e.stopPropagation()}
            >
              <h1 className="Chat__titulo_contenedor_json">
                appointment_metas
              </h1>
              <div className="Chat__contenedor_scroll_json">
                <ReactJson
                  src={jsonChat.data._appointment_metas}
                  theme="monokai"
                  collapsed
                  sortKeys={false}
                />
              </div>
            </div>
          </Draggable>
          <Draggable>
            <div
              className="Chat__contenedor_json Chat__contenedor_json--tercero"
              onClick={(e) => e.stopPropagation()}
            >
              <h1 className="Chat__titulo_contenedor_json">appointment_data</h1>
              <div className="Chat__contenedor_scroll_json">
                <ReactJson
                  src={jsonChat.data._appointment_data}
                  theme="monokai"
                  collapsed
                  sortKeys={true}
                />
              </div>
            </div>
          </Draggable>
          <Draggable>
            <div
              className="Chat__contenedor_json Chat__contenedor_json--cuarto"
              onClick={(e) => e.stopPropagation()}
            >
              <h1 className="Chat__titulo_contenedor_json">actions</h1>
              <div className="Chat__contenedor_scroll_json">
                <ReactJson
                  src={jsonChat.data._actions}
                  theme="monokai"
                  collapsed
                  sortKeys={false}
                />
              </div>
            </div>
          </Draggable>
          <Draggable>
            <div
              className="Chat__contenedor_json Chat__contenedor_json--quinto"
              onClick={(e) => e.stopPropagation()}
            >
              <h1 className="Chat__titulo_contenedor_json">appointment_logs</h1>
              <div className="Chat__contenedor_scroll_json">
                <ReactJson
                  src={jsonChat.data._appointment_logs}
                  theme="monokai"
                  collapsed
                  sortKeys={false}
                />
              </div>
            </div>
          </Draggable>
        </>
      )}
      <DatosChat
        datos={conversaciones?.[indiceConversacion].context}
        telefono={telefono}
        cargando={cargando}
        intentos={phoneHistories?.[indiceConversacion] ?? []}
      />
      <CelularWhatsapp
        conversaciones={conversaciones}
        indiceConversacion={indiceConversacion}
        seleccionarConversacion={setIndiceConversacion}
        nombrePaciente={
          conversaciones?.[indiceConversacion].context.find(
            (c) => c.target === "name" || c.target === "patient_name_1",
          )?.value
        }
        telefono={telefono ? `+${telefono}` : ""}
        intentos={
          indiceConversacion ? phoneHistories?.[indiceConversacion] : undefined
        }
      />
      <RespuestasChat
        datos={conversaciones?.[indiceConversacion].context}
        tags={conversaciones?.[indiceConversacion].tags}
      />
      <div>
        <NotasChat start={conversaciones?.[indiceConversacion].start} />
        {accionesHabilitadas && !isLabeler && (
          <AccionesChat telefono={telefono} link={link} />
        )}
      </div>
    </div>
  );
};

export default Chat;
