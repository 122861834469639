import { Icon } from "@iconify/react";
import { format } from "date-fns";
import ReactDatePicker, {
  DatePickerProps as ReactDatePickerProps,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./react-datepicker-overrides.css";

const DatePicker = (props: ReactDatePickerProps) => {
  const locale =
    typeof props.locale === "string"
      ? // @ts-expect-error Using logic specific to react-datepicker
        window.__localeData__[props.locale]
      : props.locale;
  return (
    <ReactDatePicker
      {...props}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        decreaseYear,
        increaseYear,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        prevYearButtonDisabled,
        nextYearButtonDisabled,
      }) =>
        props.showMonthYearPicker ? (
          <>
            <button
              type="button"
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              onClick={decreaseYear}
              aria-label="Año anterior"
              disabled={prevYearButtonDisabled}
            >
              <Icon icon="mdi:chevron-left" />
            </button>
            <div className="react-datepicker__current-month">
              {format(date, "yyyy", { locale })}
            </div>
            <button
              type="button"
              className="react-datepicker__navigation react-datepicker__navigation--next"
              onClick={increaseYear}
              aria-label="Año siguiente"
              disabled={nextYearButtonDisabled}
            >
              <Icon icon="mdi:chevron-right" />
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              onClick={decreaseMonth}
              aria-label="Mes anterior"
              disabled={prevMonthButtonDisabled}
            >
              <Icon icon="mdi:chevron-left" />
            </button>
            <div className="react-datepicker__current-month">
              {format(date, "LLLL yyyy", { locale })}
            </div>
            <button
              type="button"
              className="react-datepicker__navigation react-datepicker__navigation--next"
              onClick={increaseMonth}
              aria-label="Mes siguiente"
              disabled={nextMonthButtonDisabled}
            >
              <Icon icon="mdi:chevron-right" />
            </button>
          </>
        )
      }
    />
  );
};

export default DatePicker;
